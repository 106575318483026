import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {debounceTime, filter} from 'rxjs/operators';
import {LookupService} from "../../srvs/lookup";
import {UsageFilter} from "../../usage/usage.filter";
import {DialogService} from "../../services/dialog.service";
import {Db2SmsInFilter} from "./db2SmsIn.filter";
import {SmsMtFilter} from "../../so/smsMtFilter";
import {Db2SmsOutFilter} from "./db2SmsOut.filter";

@Component({
  selector: 'micro-db2sms-log-mt',
  templateUrl: './db2sms-log-mt.component.html'
})
export class Db2SmsLogMtComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:Db2SmsInFilter = new Db2SmsInFilter();
  usageFilter:UsageFilter = new UsageFilter();
  timer:any;
  reloading:boolean = false;

  @Input() companyId:any;
  @Input() userId:any;
  @Input() d2sConfigId:any;
  @Input() sqlServerId:any;
  @Input() accountTag:any;
  @Input() toMsisdn:any;
  @Input() status:any;
  @Input() smsMtState:any;
  @Input() embedded:false;

  settings:any = {
    pageIndex: 0,
    pageSize: 25,
    autoRefresh: false
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private lookupService:LookupService,
              public dialogService:DialogService) {
  }

  ngOnInit() {
    this.loadSettings();
    this.reload();

    this.timer = setInterval(()=> {
      if (this.settings.autoRefresh && !this.embedded) {
        this.reload();
      }
    }, 30000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  public reload() {
    if (this.reloading) {
      return;
    }

    this.reloading = true;
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || this.settings.pageSize))
      ;

    if (!this.embedded) {
      if (!this.usageFilter.fromInfinite) {
        params = params.set('from', '' + this.usageFilter.from);
      }

      if (!this.usageFilter.untilInfinite) {
        params = params.set('until', '' + this.usageFilter.until);
      }
    }

    this.http.get(`${this.env.e.url}/db2sms/mt`, {params:params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  addDateRangeListner() {
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  loadSettings() {
    let sessionSettingsJson = sessionStorage.getItem("db2sms_log_mt");

    if (sessionSettingsJson) {
      let sessionSettings = JSON.parse(sessionSettingsJson);
      this.settings = sessionSettings.settings;
      this.paginator.pageIndex = this.settings.pageIndex;
      this.paginator.pageSize = this.settings.pageSize;

      if (!this.embedded) {
        this.filter = new Db2SmsInFilter(sessionSettings.filter);
      } else {
        this.filter = new Db2SmsInFilter();
        this.filter.companyId = this.companyId;
        this.filter.userId = this.userId;
        this.filter.d2sConfigId = this.d2sConfigId;
        this.filter.sqlServerId = this.sqlServerId;
        this.filter.accountTag = this.accountTag;
        this.filter.toMsisdn = this.toMsisdn;
        this.filter.status = this.status;
        this.filter.smsMtState = this.smsMtState;
      }
      this.addFilterListener();

      if (!this.embedded) {
        this.usageFilter = new UsageFilter(sessionSettings.dateRange);
        this.addDateRangeListner();
      }

    } else {
      this.usageFilter = new UsageFilter();
      this.addDateRangeListner();
      this.filter = new Db2SmsInFilter();
      if (this.embedded) {
        this.filter.companyId = this.companyId;
        this.filter.userId = this.userId;
        this.filter.d2sConfigId = this.d2sConfigId;
        this.filter.sqlServerId = this.sqlServerId;
        this.filter.accountTag = this.accountTag;
        this.filter.toMsisdn = this.toMsisdn;
        this.filter.status = this.status;
        this.filter.smsMtState = this.smsMtState;
      }
      this.addFilterListener();
    }
  }

  saveSettings() {
    let oldSettings = JSON.parse(sessionStorage.getItem("db2sms_log_mt"));
    let sessionSettings = {
      filter: this.embedded ? oldSettings.filter : this.filter.toObj(),
      settings: this.settings,
      dateRange: this.embedded ? oldSettings.dateRange : this.usageFilter.toObj(),
    };

    sessionStorage.setItem("db2sms_log_mt", JSON.stringify(sessionSettings));
  }

  pagignatorChanged() {
    if (this.paginator.pageIndex !== this.settings.pageIndex || this.paginator.pageSize !== this.settings.pageSize) {
      this.settings.pageIndex = this.paginator.pageIndex;
      this.settings.pageSize = this.paginator.pageSize;
      this.saveSettings();
    }
    this.reload();
  }

}
