<micro-select-combo #sel
              placeholder="Email2Msg User"
              routerBase="mail2msg/users"
              [(id)]="mail2SmsUserId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
