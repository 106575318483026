import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {debounceTime, filter} from 'rxjs/operators';
import {LookupService} from "../../srvs/lookup";
import {UsageFilter} from "../../usage/usage.filter";
import {DialogService} from "../../services/dialog.service";
import {Mail2SmsInFilter} from "./mail2SmsIn.filter";
import {MailFilter} from "../../mail/mail.filter";
import {ActivatedRoute, Params} from "@angular/router";
import {WhatsappOutgoingMsgLogFilter} from "../../chat/whatsappOutgoingMsgLogs/whatsappOutgoingMsgLog.filter";
import * as moment from "moment";
import {Mail2SmsOutFilter} from "./mail2SmsOut.filter";

@Component({
  selector: 'micro-mail2sms-log-incoming',
  templateUrl: './mail2sms-log-incoming.component.html'
})
export class Mail2SmsLogIncomingComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:Mail2SmsInFilter = new Mail2SmsInFilter();
  usageFilter:UsageFilter = new UsageFilter();
  timer:any;
  reloading:boolean = false;
  msgChannel:string = undefined;

  @Input() companyId:any;
  @Input() userId:any;
  @Input() fromEmail:any;
  @Input() recipient:any;
  @Input() status:any;
  @Input() showPolls:any;
  @Input() mtStatus:any;
  @Input() embedded:false;

  settings:any = {
    pageIndex: 0,
    pageSize: 25,
    autoRefresh: false
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public auth:AuthService,
              private env:EnvService,
              private route: ActivatedRoute,
              private http:HttpClient,
              private lookupService:LookupService,
              public dialogService:DialogService) {
  }

  ngOnInit() {
    this.route.params
      .subscribe((params: Params) => {
        this.msgChannel = params['msgChannel'];
        if (this.msgChannel) this.msgChannel = this.msgChannel.toUpperCase();
        this.loadSettings();
        this.reload();
        this.timer = setInterval(()=> {
          if (this.settings.autoRefresh && !this.embedded) {
            this.reload();
          }
        }, 30000);
      });

  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  public reload() {
    if (this.reloading) {
      return;
    }

    this.reloading = true;
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || this.settings.pageSize));

    if (!this.embedded) {
      if (!this.usageFilter.fromInfinite) {
        params = params.set('from', '' + this.usageFilter.from);
      }

      if (!this.usageFilter.untilInfinite) {
        params = params.set('until', '' + this.usageFilter.until);
      }
    }

    this.http.get(`${this.env.e.url}/mail2sms/mt/log/${this.filter.msgChannel}`, {params:params}).subscribe(
      data => {
        let page = data as any;

        let ldate = undefined;
        for (let rec of page.content) {
          if (!ldate || rec.mail2SmsReceivedAt !== ldate) {
            ldate = rec.mail2SmsReceivedAt;
            rec.displayReceivedAt = true;
          } else {
            rec.displayReceivedAt = false;
          }
        }

        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  addDateRangeListner() {
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  loadSettings() {
    let sessionSettingsJson = sessionStorage.getItem(`mail2sms_log_in.${this.msgChannel || 'all'}`);

    if (sessionSettingsJson) {
      let sessionSettings = JSON.parse(sessionSettingsJson);
      this.settings = sessionSettings.settings;
      this.paginator.pageIndex = this.settings.pageIndex;
      this.paginator.pageSize = this.settings.pageSize;

      if (!this.embedded) {
        this.filter = new Mail2SmsInFilter(sessionSettings.filter);
      } else {
        this.filter = new Mail2SmsInFilter();
        this.filter.companyId = this.companyId;
        this.filter.userId = this.userId;
        this.filter.fromEmail = this.fromEmail;
        this.filter.recipient = this.recipient;
        this.filter.status = this.status;
        this.filter.showPolls = this.showPolls;
        this.filter.mtStatus = this.mtStatus;
      }
      this.filter.msgChannel = this.msgChannel;
      this.addFilterListener();

      if (!this.embedded) {
        this.usageFilter = new UsageFilter(sessionSettings.dateRange);
        this.addDateRangeListner();
      }

    } else {
      this.usageFilter = new UsageFilter();
      this.addDateRangeListner();
      this.filter = new Mail2SmsInFilter();
      if (this.embedded) {
        this.filter.companyId = this.companyId;
        this.filter.userId = this.userId;
        this.filter.fromEmail = this.fromEmail;
        this.filter.recipient = this.recipient;
        this.filter.status = this.status;
        this.filter.showPolls = this.showPolls;
        this.filter.mtStatus = this.mtStatus;
      }
      this.filter.msgChannel = this.msgChannel;
      this.addFilterListener();
    }
  }

  saveSettings() {
    let oldSettings = JSON.parse(sessionStorage.getItem(`mail2sms_log_in.${this.msgChannel || 'all'}`));
    let sessionSettings = {
      filter: this.embedded ? oldSettings.filter : this.filter.toObj(),
      settings: this.settings,
      dateRange: this.embedded ? oldSettings.dateRange : this.usageFilter.toObj(),
    };

    sessionStorage.setItem(`mail2sms_log_in.${this.msgChannel || 'all'}`, JSON.stringify(sessionSettings));
  }

  pagignatorChanged() {
    if (this.paginator.pageIndex !== this.settings.pageIndex || this.paginator.pageSize !== this.settings.pageSize) {
      this.settings.pageIndex = this.paginator.pageIndex;
      this.settings.pageSize = this.paginator.pageSize;
      this.saveSettings();
    }
    this.reload();
  }
}
