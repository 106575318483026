import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-imap-select',
  templateUrl: './imap-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ImapSelectComponent
    }
  ]
})
export class ImapSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild("sel", {static: true}) sel: MicroSelectComponent;

  @Input()
  mode: string = 'simple';

  @Output()
  imapIdChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  imapId: any;

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value: any;

  @Input()
  required: boolean = false;

  @Input()
  disabled: boolean = false;

  options: any[];

  change(e) {
    this.onChange(e);
    this.imapIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  onChange = (e) => {};
  onTouched = () => {};
  touched = false;

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(value: string): void {
    this.imapId = value;
  }

  constructor(private auth: AuthService, private env: EnvService, private http: HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'imapId') {
        this.reload();
      }
    }
  }

  reload() {
    if (!this.sel.isPlatformServiceAvailable()) {
      return;
    }

    let params:HttpParams = new HttpParams();
    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/mail/incoming/mailboxes/all`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    this.reload();
  }
}
