import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-whatsappIncomingMsgLog-detail',
  templateUrl: './whatsappIncomingMsgLog-detail.component.html'
})
export class WhatsappIncomingMsgLogDetailComponent implements OnInit {
  whatsappIncomingMsgLog:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              public dialogService:DialogService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:number = params['id'];
        this.http.get(`${this.env.e.url}/chat/whatsapp/in/${id}`).subscribe(
          data => {
            this.setWhatsappIncomingMsgLog(data)
          }
        );
      });
  }

  setWhatsappIncomingMsgLog(whatsappIncomingMsgLog:any) {
    this.whatsappIncomingMsgLog = whatsappIncomingMsgLog;
  }

  markAsRead() {
    this.dialogService.confirm("Mark as read?", "Are you sure you want to mark this message as read?", "Mark as read").subscribe(confirmed => {
      if (confirmed) {
        var req = {
          messageId: this.whatsappIncomingMsgLog.id
        }
        this.http.get(`${this.env.e.url}/chat/whatsapp/in/${this.whatsappIncomingMsgLog.id}/read`)
          .subscribe(
            data => {
              let rsp = data as any;
              if (!rsp?.id) {
                this.alertService.warn(rsp);
              } else {
                this.alertService.info(`Marked as read`);
                this.setWhatsappIncomingMsgLog(rsp);
              }
            }
          );
      }
    });
  }
}
