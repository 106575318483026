import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {ReactorFilter} from "./reactor.filter";
import { debounceTime } from 'rxjs/operators';
import {WhatsappIncomingMsgLogFilter} from "../chat/whatsappIncomingMsgLogs/whatsappIncomingMsgLog.filter";
import * as moment from "moment/moment";

@Component({
  selector: 'micro-reactors',
  templateUrl: './reactors.component.html'
})
export class ReactorsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['name', 'eventType'];
  filter:ReactorFilter = new ReactorFilter();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  settings:any = {
    pageIndex: 0,
    pageSize: 25,
  }

  constructor(private authService:AuthService,
              private env:EnvService,
              private http:HttpClient) {

  }

  ngOnInit() {
    this.addFilterListener();
    this.loadSettings();
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 25));

    this.http.get(`${this.env.e.url}/reactor/reactors`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  pagignatorChanged() {
    if (this.paginator.pageIndex !== this.settings.pageIndex || this.paginator.pageSize !== this.settings.pageSize) {
      this.settings.pageIndex = this.paginator.pageIndex;
      this.settings.pageSize = this.paginator.pageSize;
      this.saveSettings();
    }
    this.reload();
  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }


  loadSettings() {
    let sessionSettingsJson = sessionStorage.getItem("reactors_settings");

    if (sessionSettingsJson) {
      let sessionSettings = JSON.parse(sessionSettingsJson);
      this.settings = sessionSettings.settings;
      this.filter = new ReactorFilter(sessionSettings.filter);
      this.addFilterListener();
      this.paginator.pageIndex = this.settings.pageIndex;
      this.paginator.pageSize = this.settings.pageSize;
    }
  }

  saveSettings() {
    let sessionSettings = {
      filter: this.filter.toObj(),
      settings: this.settings,
    };
    sessionStorage.setItem("reactors_settings", JSON.stringify(sessionSettings));
  }

}
