import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-mail2SmsPostmaster-select',
  templateUrl: './mail2SmsPostmaster-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: Mail2SmsPostmasterSelectComponent
    }
  ]
})
export class Mail2SmsPostmasterSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild("sel", {static: true}) sel: MicroSelectComponent;

  @Output()
  mail2SmsPostmasterIdChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  mail2SmsPostmasterId: any = '';

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value: any = '';

  @Input()
  required: boolean = false;

  @Input()
  requires: any;

  @Input()
  allLabel: any;

  @Input()
  disabled: boolean = false;

  options: any[];

  change(e) {
    this.onChange(e);
    this.mail2SmsPostmasterIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  onChange = (e) => {};
  onTouched = () => {};
  touched = false;

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(value: string): void {
    this.mail2SmsPostmasterId = value;
  }

  constructor(private auth: AuthService, private env: EnvService, private http: HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'mail2SmsPostmasterId') {
        this.reload();
      }
    }
  }

  reload() {
      let params:HttpParams = new HttpParams();
      this.sel.loading = true;
      return this.http.get(`${this.env.e.url}/mail2sms/mail2SmsPostmasters/all`, {params:params})
        .subscribe(data => {
            this.options = data as any[];
            this.sel.loading = false;
        });
    }

    ngOnInit(): void {
      this.reload();
    }
}
