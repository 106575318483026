<ng-container *ngIf="!embedded">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>cloud_download</mat-icon> DB2SMS - Incoming Log
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
      <micro-checkbox [(checked)]="settings.autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing" (click)="saveSettings()"></micro-checkbox>
      <div class="fill-space"></div>
      <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
    </div>
  </div>
  <micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="receivedAt" entityType="DB2SMS_MT" filter="" groupBy="status"></micro-do-chart>
</ng-container>

<div *ngIf="embedded">
  <div class="stick-top">
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>
    </div>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th class="dateTimeHeader">
          Received At
      </th>
      <th *ngIf="auth.hasRole('COMPANY_ADMIN')">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          DB2SMS User Account
          <input type="text" [(ngModel)]="filter.accountTag">
        </div>
      </th>
      <th>
        DB Message ID
      </th>
      <th>
        App Source Addr
      </th>
      <th>
        App Source Tag
      </th>
      <th>
        Source Address
      </th>
      <th>
        <div class="vbox">
          Recipient
          <input type="text" [(ngModel)]="filter.toMsisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          Status
          <db2sms-in-status-select [(status)]="filter.status"></db2sms-in-status-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          SMS State
          <micro-sms-state-select [(smsState)]="filter.smsMtState"></micro-sms-state-select>
        </div>
      </th>
      <th>
        Processed At
      </th>
      <th>
        SMS Completed At
      </th>
      <th>
        SMS Text Length<br>
      </th>
      <th>
        <div class="vbox">
          SMS Parts<br>
          (tot / pen / del / err)
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="!embedded">{{element.db2SmsReceivedAt | dateTimeMs}}</td>
<!--      <td><micro-db2SmsConfig-lookup [configId]="element.d2sConfigId" [link]="true"></micro-db2SmsConfig-lookup></td>-->
      <td *ngIf="auth.hasRole('COMPANY_ADMIN')"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
<!--      <td><micro-user-lookup [userId]="element.userId" [link]="true"></micro-user-lookup></td>-->
      <td><a routerLink="/db2sms/db2SmsConfigs/{{element.d2sConfigId}}" matTooltip="Jump to {{element.accountTag}}"> {{element.accountTag}}</a></td>
      <td>{{element.externalId}}</td>
      <td>{{element.appSourceAddr}}</td>
      <td>{{element.appSourceTag}}</td>
      <td>{{element.sourceAddress}}</td>
      <td>
        <ng-container *ngIf="element.smsMtId; else noSmsMt1">
          <a routerLink="/smss/{{element.smsMtId}}" [matTooltip]="'Open SMS Log to ' + element.toMsisdn">{{element.toMsisdn}}</a>
        </ng-container>
        <ng-template #noSmsMt1>{{element.toMsisdn}}</ng-template>
      </td>
      <td>
        {{element.db2SmsMtStatus}}
      </td>
      <ng-container *ngIf="element.smsMtId; else noSmsMt2">
        <td>{{element.smsMtState}}</td>
        <td>{{element.db2SmsProcessedAt | dateTimeMs}}</td>
        <td>{{element.smsMtCompletedAt | dateTimeMs}}</td>
        <td>{{element.encodedLength}}</td>
        <td><div>{{element.smsMtPartCount}} / {{element.smsMtPartPendingCount}} / {{element.smsMtPartDeliveredCount}} / {{element.smsMtPartCount - element.smsMtPartPendingCount - element.smsMtPartDeliveredCount}}</div></td>
      </ng-container>
      <ng-template #noSmsMt2>
        <td></td>
        <td></td>
        <td>{{element.encodedLength}}</td>
        <td><div>{{element.partCount ? element.partCount : '--'}} / -- / -- / --</div></td>
      </ng-template>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="pagignatorChanged()"
                       [pageSize]="settings.pageSize"
                       [pageSizeOptions]="[25, 50, 100, 250]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
