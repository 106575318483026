import {EventEmitter, Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {AuthService} from "../services/auth.service";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {debounceTime} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public activeItem:any;
  public activeItemChanged: EventEmitter<any> = new EventEmitter();
  items:any = {};
  allItems:any = [];
  navItems:any = [];
  favorites:any = [];

  public searchChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private location:Location,
    private router: Router) {
  }

  public init() {
    this.initMenu(this.menu, null);
    this.navItems.sort((a, b) => b.href.length - a.href.length);
    this.loadFavorites();
    this.load();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //this.load();
      }
    });

    this.searchChanged.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.applySearch(change);
    });
  }

  public search(filter:string) {
    this.searchChanged.emit(filter);
  }

  private applySearch(filter:string) {
    if (!filter || filter.trim() === '') {
      for (let item of this.allItems) {
        item.hidden = false;
      }
      return;
    }
    filter = filter.trim().toLowerCase();
    for (let item of this.allItems) {
      if (!item.title || item.items) {
        item.hidden = false;
        continue;
      }
      let title = item.title.toLowerCase();
      item.hidden = title.indexOf(filter) == -1;
    }
  }

  public hasFavorites() {
    return this.favorites && this.favorites.length > 0;
  }

  public menu = {
    expanded: true,
    favorite: true,
    items:
      [
        {
          title: 'eNOC',
          icon: 'notifications_active',
          items: [
            {
              title: 'Alarms',
              icon: 'alarm',
              service: 'ALARMING',
              platformService: 'alarmd',
              href: '/alarms'
            },
            {
              title: 'Dashboards',
              icon: 'pie_chart',
              service: 'DASHBOARDS',
              platformService: 'dashboardd',
              href: '/dashboards',
              queryParams: {
                mode: 'view'
              }
            },
            {
              title: 'CMDB',
              icon: 'computer',
              href: '/cmdb/explorer',
              service: 'ALARMING'
            },
            {
              title: 'Notification Logs',
              icon: 'notifications_active',
              service: 'NOTIFICATIONS',
              href: '/notif/notificationLogs',
              platformService: 'notifd',
            },
            {
              title: 'Reactor Logs',
              icon: 'blur_on',
              href: '/reactorLogs',
              service: 'REACTORS',
              platformService: 'reactord',
            },
          ]
        },
        {
          title: 'Services',
          color: '#3C678F',
          expanded: true,
          icon: 'build',
          items: [
            {
              title: 'Usage',
              icon: 'pie_chart',
              href: '/usage',
              platformService: 'chartd',
              service: '_ANY'
            },
            {
              title: 'Data',
              icon: 'timeline',
              service: 'DATA',
              platformService: 'datad',
              items: [
                {
                  title: 'Explore',
                  href: '/data/explore',
                  icon: 'explore'
                },
                {
                  title: 'Charts',
                  href: '/sm/charts',
                  icon: 'timeline'
                },
                {
                  title: 'Grids',
                  href: '/sm/grids',
                  icon: 'grid_on'
                },
              ]
            },
            {
              title: 'Service Management',
              icon: 'report_problem',
              items: [
                {
                  title: 'OneProject',
                  icon: 'work',
                  service: 'TASK',
                  platformService: 'taskd',
                  items: [
                    {
                      title: 'Search Tasks',
                      href: '/task/tasks',
                      icon: 'search'
                    },
                    {
                      title: 'Get Task by ID',
                      href: '/task/tasks/findTask',
                      icon: 'search'
                    },
                    {
                      title: 'Get Tasks by Resource',
                      href: '/task/tasks/getTasksByResource',
                      icon: 'search'
                    },
                    {
                      title: 'Create Task',
                      href: '/task/tasks/0/0',
                      queryParams: {
                        basic: 'true'
                      },
                      icon: 'add'
                    },
                    {
                      title: 'Update Task',
                      href: '/task/tasks/setStatus',
                      icon: 'work'
                    },
                    {
                      title: 'Add Feedback',
                      href: '/task/tasks/addFeedback',
                      icon: 'work'
                    },
                    {
                      title: 'Get Standby Resource',
                      href: '/task/tasks/getResource',
                      icon: 'person'
                    },
                    {
                      title: 'Get Resource by MSISDN',
                      href: '/task/tasks/getResourceByMsisdn',
                      icon: 'person'
                    },
                    {
                      title: 'Assign Resource',
                      href: '/task/tasks/setResource',
                      icon: 'person'
                    },
                    {
                      title: 'Get Sprints',
                      href: '/task/tasks/getSprints',
                      icon: 'schedule'
                    },
                    {
                      title: 'Schedule Task',
                      href: '/task/tasks/setSprint',
                      icon: 'schedule'
                    },
                    {
                      title: 'Log',
                      href: '/task/taskLogs',
                      icon: 'work'
                    }
                  ]
                },
                {
                  title: 'ITSM',
                  icon: 'work',
                  service: 'TASK',
                  platformService: 'taskd',
                  items: [
                    {
                      title: 'Get Request by ID',
                      href: '/itsm/requests/findRequest',
                      icon: 'search'
                    },
                    {
                      title: 'Create Request',
                      href: '/itsm/requests/createRequest',
                      icon: 'add'
                    },
                    {
                      title: 'Get Change by ID',
                      href: '/itsm/changes/findChange',
                      icon: 'search'
                    },
                    {
                      title: 'Create Change',
                      href: '/itsm/changes/createChange',
                      icon: 'add'
                    },
                    {
                      title: 'Get Asset by Name',
                      href: '/itsm/assets/findAsset',
                      icon: 'search'
                    },
                    {
                      title: 'Create Asset',
                      href: '/itsm/assets/createAsset',
                      icon: 'add'
                    },
                    {
                      title: 'Get Product Type by Name',
                      href: '/itsm/productTypes/findProductType',
                      icon: 'search'
                    },
                    {
                      title: 'Create Product Type',
                      href: '/itsm/productTypes/createProductType',
                      icon: 'add'
                    },
                    {
                      title: 'Log',
                      href: '/task/itsmLogs',
                      icon: 'work'
                    }
                  ]
                },
                {
                  title: 'BMC Remedy',
                  icon: 'report_problem',
                  service: 'REMEDY',
                  platformService: 'remedyd',
                  items: [
                    {
                      title: 'Work Orders',
                      href: '/workorders',
                      icon: 'work'
                    },
                    {
                      title: 'Incidents',
                      href: '/incidents',
                      icon: 'report_problem'
                    },
                    {
                      title: 'Import Contacts',
                      href: '/contacts/import/remedy',
                      icon: 'report_problem'
                    }
                  ]
                },
              ]
            },
            {
              title: 'GEO',
              icon: 'map',
              service: 'GEO',
              platformService: 'geod',
              items: [
                {
                  title: 'Maps',
                  href: '/map',
                  icon: 'map'
                }
              ]
            },
            {
              title: 'Airtime',
              icon: 'phone_iphone',
              service: 'AIRTIME',
              platformService: 'aird',
              items: [
                {
                  title: 'Purchase Airtime',
                  href: '/air/airtimePurchase',
                  icon: 'monetization_on'
                },
                {
                  title: 'Airtime Products',
                  href: '/air/airtimeProducts',
                  icon: 'phone_iphone',
                  role: ['AIRTIME_ADMIN']
                },
                {
                  title: 'Airtime Recharges',
                  href: '/air/airtimeRecharges',
                  icon: 'phone_iphone'
                },
                {
                  title: 'Request Recharge',
                  href: '/air/airtimeRecharge',
                  icon: 'monetization_on',
                  role: ['AIRTIME_ADMIN']
                },

                {
                  title: 'Dashboard',
                  href: '/air/dashboard',
                  icon: 'pie_chart'
                }
              ]
            },
            {
              title: 'Messaging',
              icon: 'send',
              items: [
                {
                  title: 'EMAIL',
                  icon: 'email',
                  service: 'EMAIL',
                  platformService: 'maild',
                  items: [
                    {
                      title: 'Send',
                      href: '/mail/send',
                      icon: 'send'
                    },
                    {
                      title: 'Incoming',
                      href: '/mail/incoming',
                      icon: 'email'
                    },
                    {
                      title: 'Outgoing',
                      href: '/mail/outgoing',
                      icon: 'email'
                    },
                    {
                      title: 'Dashboard',
                      href: '/mail/dashboard',
                      icon: 'pie_chart'
                    }
                  ]
                },
                {
                  title: 'SMS',
                  icon: 'sms',
                  service: 'SMS',
                  platformService: 'smsd',
                  items: [
                    {
                      title: 'Send',
                      href: '/sms/send',
                      icon: 'send'
                    },
                    {
                      title: 'Reply',
                      href: '/sms/smsMoToEmails',
                      icon: 'email',
                      role: 'SMS_REPLY_ADMIN'
                    },
                    {
                      title: 'File2Sms',
                      icon: 'file_upload',
                      service: 'FILE2SMS',
                      platformService: 'file2smsd',
                      items: [
                        {
                          title: 'File2Sms Upload',
                          href: '/sms/file2Sms/upload',
                          icon: 'file_upload',
                          role: 'FILE2SMS_USE'
                        },
                        {
                          title: 'File2Sms Jobs',
                          href: '/sms/file2sms/jobs',
                          icon: 'file_upload'
                        },
                      ]
                    },
                    {
                      title: 'SMS Logs',
                      icon: 'sms',
                      items: [
                        {
                          title: 'Outgoing (MT)',
                          href: '/sms/outgoing',
                          icon: 'sms'
                        },
                        {
                          title: 'Delivery Reports (DR)',
                          href: '/sms/dr',
                          icon: 'sms'
                        },
                        {
                          title: 'Incoming (MO)',
                          href: '/sms/incoming',
                          icon: 'sms'
                        },
                        {
                          title: 'Reply Logs',
                          href: '/sms/smsMoToEmailLogs',
                          icon: 'email',
                          service: 'SMS_REPLY'
                        },
                        {
                          title: 'Email2SMS (MT)',
                          href: '/mail2msg/log/in/SMS',
                          icon: 'move_to_inbox',
                          platformService: 'mail2smsd',
                          service: 'MAIL2SMS',
                          role: 'MAIL2SMS_ADMIN'
                        },
                        {
                          title: 'Email2SMS (MO)',
                          href: '/mail2msg/log/out/SMS',
                          icon: 'reply_all',
                          platformService: 'mail2smsd',
                          service: 'MAIL2SMS',
                          role: 'MAIL2SMS_ADMIN'
                        },
                        {
                          title: 'DB2SMS (MT)',
                          href: '/db2sms/db2SmsLog/mt',
                          icon: 'cloud_download',
                          platformService: 'db2smsd',
                          service: 'DB2SMS',
                        },
                        {
                          title: 'DB2SMS (MO)',
                          href: '/db2sms/db2SmsLog/mo',
                          icon: 'cloud_upload',
                          platformService: 'db2smsd',
                          service: 'DB2SMS',
                        },
                      ]
                    },
                    {
                      title: 'SMS Exception Report',
                      icon: 'report_problem',
                      href: '/sms/reports/error',
                      platformService: 'smsd',
                    },
                    {
                      title: 'Dashboard',
                      href: '/sms/dashboard',
                      icon: 'pie_chart'
                    }
                  ]
                },
                {
                  title: 'USSD',
                  icon: 'message',
                  service: 'USSD',
                  platformService: 'ussdd',
                  items: [
                    {
                      title: 'Sessions',
                      href: '/ussdSessions',
                      icon: 'message',
                    },
                    {
                      title: 'Session Logs',
                      href: '/ussdSessionLogs',
                      icon: 'message',
                    },
                    {
                      title: 'Dashboards',
                      href: '/ussdDashboards',
                      icon: 'pie_chart',
                    },
                    {
                      title: 'Simulator',
                      href: '/ussdSim',
                      icon: 'message',
                    },
                  ]
                },
                {
                  title: 'VOICE',
                  icon: 'record_voice_over',
                  service: 'VOICE',
                  platformService: 'voiced',
                  items: [
                    {
                      title: 'Voice Log',
                      href: '/voice/voiceLogs',
                      icon: 'record_voice_over'
                    },
                    {
                      title: 'Send',
                      href: '/voice/send',
                      icon: 'send'
                    },
                    {
                      title: 'Dashboard',
                      href: '/voice/dashboard',
                      icon: 'pie_chart'
                    }
                  ]
                },
                {
                  title: 'WhatsApp',
                  icon: 'chat',
                  role: ['CHAT_ADMIN'],
                  service: 'CHAT',
                  platformService: 'chatd',
                  items: [
                    {
                      title: 'Send',
                      href: '/chat/whatsapp/send',
                      icon: 'send'
                    },
                    {
                      title: 'WhatsApp Logs',
                      icon: 'chat',
                      items: [
                        {
                          title: 'Outgoing (MT)',
                          href: 'chat/whatsapp/logs/outgoing',
                          icon: 'sms'
                        },
                        {
                          title: 'Incoming (MO)',
                          href: 'chat/whatsapp/logs/incoming',
                          icon: 'sms'
                        },
                        {
                          title: 'Email2WA (MT)',
                          href: '/mail2msg/log/in/WHATSAPP',
                          icon: 'move_to_inbox',
                          platformService: 'mail2smsd',
                          service: 'MAIL2WA',
                          role: 'MAIL2SMS_ADMIN'
                        },
                        {
                          title: 'Email2WA (MO)',
                          href: '/mail2msg/log/out/WHATSAPP',
                          icon: 'reply_all',
                          platformService: 'mail2smsd',
                          service: 'MAIL2SMS',
                          role: 'MAIL2SMS_ADMIN'
                        },
                      ]
                    },
                    {
                      title: 'Dashboard',
                      href: '/chat/whatsapp/dashboard',
                      icon: 'pie_chart'
                    }
                  ]
                },
                {
                  title: 'URL Shortening',
                  icon: 'link',
                  service: 'SURL',
                  platformService: 'surld',
                  items: [
                    {
                      title: 'Short URLs',
                      href: '/surl/surls',
                      icon: 'link'
                    },
                  ]
                },
              ]
            },
            {
              title: 'Secure Access',
              platformService: 'accessd',
              service: 'SITE_ACCESS',
              icon: 'lock',
              items: [
                {
                  title: 'Request Token',
                  href: '/sa/request',
                  icon: 'lock'
                },
                {
                  title: 'Active Requests',
                  href: '/sa/saTrackers',
                  icon: 'login'
                },
                {
                  title: 'Request Token Log',
                  href: '/sa/saTokenRequestLogs',
                  icon: 'history'
                },
                {
                  title: 'Lock Activity Log',
                  href: '/access/saLockActivityLogs',
                  icon: 'history'
                },
                {
                  title: 'Cabinet Audit Trail',
                  href: '/access/saCabinetLogs',
                  icon: 'history'
                },
                {
                  title: 'Lock Audit Trail',
                  href: '/access/saLockLogs',
                  icon: 'history'
                },
              ]
            },
            {
              title: 'Integration',
              icon: 'sync_alt',
              items: [
                {
                  title: 'Webhooks',
                  icon: 'cloud_queue',
                  service: 'WEBHOOK',
                  platformService: 'webhookd',
                  items: [
                    {
                      title: 'Webhook Logs',
                      href: '/webhook/webhookLogs',
                      icon: 'cloud_queue'
                    },
                  ]
                },
                {
                  title: 'Queues',
                  icon: 'list',
                  service: 'QUEUE',
                  platformService: 'qd',
                  items: [
                    {
                      title: 'Publish Message',
                      href: '/q/queueOutMessages/0',
                      icon: 'send'
                    },
                    {
                      title: 'Poll Messages',
                      href: '/q/poll',
                      icon: 'call_split'
                    },
                    {
                      title: 'Published Messages',
                      href: '/q/queueOutMessages',
                      icon: 'list'
                    },
                    {
                      title: 'Pulled Messages',
                      href: '/q/queueInMessages',
                      icon: 'list'
                    },
                    {
                      title: 'Polls',
                      href: '/q/queuePollRequests',
                      icon: 'call_split'
                    },
                    {
                      title: 'Dashboard',
                      href: '/q/dashboard',
                      icon: 'pie_chart'
                    },
                  ]
                },
              ]
            },
            {
              title: 'Chat Apps',
              icon: 'message',
              service: 'CHAT',
              platformService: 'aid',
              items: [
                {
                  title: 'Sessions',
                  href: '/chatSessions',
                  icon: 'message',
                },
                {
                  title: 'Session Logs',
                  href: '/chatSessionLogs',
                  icon: 'message',
                },
                {
                  title: 'Chat App Logs',
                  href: '/chatAppLogs',
                  icon: 'message',
                },
                {
                  title: 'Dashboards',
                  href: '/chatDashboards',
                  icon: 'pie_chart',
                },
                {
                  title: 'Simulator',
                  href: '/chatSim',
                  icon: 'message',
                },
              ]
            },
            {
              title: 'TBB',
              icon: 'monetization_on',
              service: 'TBB',
              platformService: 'tbbd',
              items: [
                {
                  title: 'Logs',
                  href: '/tbb/logs',
                  icon: 'history'
                },
                {
                  title: 'Subscriptions',
                  href: '/tbb/subscriptions',
                  icon: 'subscriptions'
                },
                {
                  title: 'Billing',
                  href: '/tbb/billings',
                  icon: 'monetization_on'
                },
                {
                  title: 'MTN Tokens',
                  href: '/tbb/tokens',
                  icon: 'monetization_on'
                },
              ]
            },
          ]
        },
        {
          title: 'Automation',
          color: '#409996',
          icon: 'flash_auto',
          service: 'AUTOMATION',
          expanded: true,
          items: [
            {
              title: 'Notifications',
              role: ['NOTIFICATION_ADMIN'],
              icon: 'notifications_active',
              href: '/notif/subscriptions',
              platformService: 'notifd',
            },
            {
              title: 'Service Checks',
              icon: 'check_circle',
              role: ['SM_ADMIN'],
              service: 'SERVICE_MONITOR',
              platformService: 'smd',
              href: '/smcs'
            },
            {
              title: 'Reactors',
              icon: 'blur_on',
              href: '/reactors',
              service: 'REACTORS',
              role: ['REACTOR_ADMIN'],
              platformService: 'reactord',
            },
            {
              title: 'Chat Apps',
              icon: 'blur_on',
              href: '/chatApps',
              service: 'CHAT',
              role: ['CHAT_ADMIN'],
              platformService: 'aid',
            },
            {
              title: 'AI',
              icon: 'blur_on',
              service: 'AI',
              platformService: 'aid',
              items: [
                {
                  title: 'Behavior Trees',
                  icon: 'blur_on',
                  href: '/ai/bts'
                },
                {
                  title: 'BT Logs',
                  icon: 'blur_on',
                  href: '/ai/btsLogs'
                },
              ]
            },
          ]
        },
        {
          title: 'Configuration',
          color: '#3D825A',
          icon: 'settings',
          items: [
            {
              title: 'eNOC',
              icon: 'notifications_active',
              items: [
                {
                  title: 'Dashboards',
                  icon: 'pie_chart',
                  href: '/dashboards',
                  service: 'DASHBOARDS',
                  platformService: 'dashboardd',
                  role: ['DASHBOARD_ADMIN'],
                  queryParams: {
                    mode: 'edit'
                  }
                },
                {
                  title: 'Diagrams',
                  icon: 'format_shapes',
                  role: ['DIAGRAM_ADMIN'],
                  service: 'DIAGRAMS',
                  platformService: 'diagramd',
                  href: '/dia'
                },
                {
                  title: 'Alarm Grids',
                  href: '/alarmGrids',
                  icon: 'grid_on',
                  service: 'ALARMING',
                  platformService: 'alarmd',
                },
                {
                  title: 'Specific Problems',
                  href: '/specProbs',
                  icon: 'bookmark',
                  role: ['NE_ADMIN']
                },
              ]
            },
            {
              title: 'CMDB',
              icon: 'computer',
              platformService: 'domaind',
              items: [
                {
                  title: 'Object Groups',
                  icon: 'group_work',
                  role: ['OBJECT_GROUP_ADMIN'],
                  href: '/objectGroups'
                },
                {
                  title: 'Physical',
                  icon: 'terrain',
                  role: ['SITE_ADMIN'],
                  service: 'SITES',
                  platformService: 'domaind',
                  items: [
                    {
                      title: 'Regions',
                      href: '/regions',
                      icon: 'terrain',
                    },
                    {
                      title: 'Sites',
                      href: '/sites',
                      icon: 'location_on',
                    },
                  ]
                },
                {
                  title: 'Logical',
                  icon: 'group_work',
                  role: ['NE_ADMIN'],
                  service: 'NETWORK',
                  items: [
                    {
                      title: 'Network Types',
                      href: '/nets',
                      icon: 'bookmark'
                    },
                    {
                      title: 'MOCs',
                      href: '/mocs',
                      icon: 'bookmark'
                    },
                    {
                      title: 'Environments',
                      href: '/domain/environments',
                      icon: 'terrain'
                    },
                    {
                      title: 'Locations',
                      href: '/domain/locations',
                      icon: 'place'
                    },
                    {
                      title: 'NE / Hosts',
                      href: '/nes',
                      icon: 'computer'
                    },
                    {
                      title: 'Connectors',
                      icon: 'power',
                      items: [
                        {
                          title: 'SSH Connections',
                          icon: 'computer',
                          role: ['SSH_ADMIN'],
                          service: 'SSH',
                          platformService: 'sshd',
                          href: '/sshs',
                        },
                        {
                          title: 'DB Connections',
                          icon: 'storage',
                          role: ['SQL_ADMIN'],
                          service: 'SQL',
                          platformService: 'sqld',
                          href: '/sqls',
                        },
                        {
                          title: 'Web Connections',
                          icon: 'api',
                          role: ['WEB_CLIENT_ADMIN'],
                          service: 'WEB_CLIENT',
                          platformService: 'webclientd',
                          href: '/web/clients',
                        },
                      ]
                    },
                  ]
                },
                {
                  title: 'Alarms',
                  icon: 'notifications_active',
                  role: ['NE_ADMIN'],
                  service: 'NETWORK',
                  platformService: 'domaind',
                  items: [
                    {
                      title: 'Specific Problems',
                      href: '/specProbs',
                      icon: 'bookmark'
                    },
                  ]
                },
              ]
            },
            {
              title: 'Service Management',
              icon: 'report_problem',
              items: [
                {
                  title: 'Project Management',
                  icon: 'work',
                  role: ['TASK_ADMIN'],
                  service: 'TASK',
                  platformService: 'taskd',
                  items: [
                    {
                      title: 'Project Carriers',
                      href: '/task/taskCarriers',
                      icon: 'work'
                    },
                  ]
                },
                {
                  title: 'ITSM',
                  icon: 'work',
                  role: ['TASK_ADMIN'],
                  service: 'TASK',
                  platformService: 'taskd',
                  items: [
                    {
                      title: 'Objects',
                      href: '/task/itsmObjects',
                      icon: 'group_work'
                    },
                    {
                      title: 'ITSM Carriers',
                      href: '/task/itsmCarriers',
                      icon: 'work'
                    },
                  ]
                },
                {
                  title: 'BMC Remedy',
                  icon: 'report_problem',
                  role: ['REMEDY_ADMIN'],
                  service: 'REMEDY',
                  platformService: 'remedyd',
                  items: [
                    {
                      title: 'Connections',
                      href: '/remedy/configs',
                      icon: 'link'
                    },
                  ]
                },
              ]
            },
            {
              title: 'GEO',
              icon: 'map',
              service: 'GEO',
              platformService: 'geod',
              role: ['GEO_ADMIN'],
              items: [
                {
                  title: 'Maps',
                  href: '/maps',
                  icon: 'map'
                }
              ]
            },
            {
              title: 'Airtime',
              icon: 'phone_iphone',
              role: ['AIRTIME_ADMIN'],
              service: 'AIRTIME',
              platformService: 'aird',
              items: [
                {
                  title: 'Airtime Carriers',
                  href: '/air/airtimeCarriers',
                  icon: 'phone_iphone'
                },
              ]
            },
            {
              title: 'Messaging',
              icon: 'send',
              items: [
                {
                  title: 'Email',
                  icon: 'email',
                  role: ['MAIL_ADMIN'],
                  service: 'EMAIL',
                  platformService: 'maild',
                  items: [
                    {
                      title: 'Outgoing',
                      href: '/mail/smtp',
                      icon: 'mail'
                    },
                    {
                      title: 'Incoming',
                      href: '/mail/imap',
                      icon: 'mail'
                    }
                  ]
                },
                {
                  title: 'SMS',
                  icon: 'sms',
                  service: 'SMS',
                  platformService: 'smsd',
                  items: [
                    {
                      title: 'A-Numbers',
                      href: '/sms/anumbers',
                      icon: 'sms',
                      role: ['SMS_ANUMBER_ADMIN']
                    },
                    {
                      title: 'MAOs',
                      href: '/sms/maos',
                      icon: 'sms',
                      role: ['SMS_ADMIN'],
                    },
                    {
                      title: 'Blacklist',
                      href: '/sms/smsBlacklist',
                      icon: 'app_blocking',
                      role: ['SMS_ADMIN'],
                    },
                    {
                      title: 'SMS Carriers',
                      href: '/sms/carriers',
                      icon: 'call_split',
                      role: ['SMS_ADMIN'],
                    },
                    {
                      title: 'SMPP SMSCs',
                      platformService: 'smscd',
                      icon: 'call_split',
                      role: ['SMS_ADMIN'],
                      items: [
                        {
                          title: 'SMPP SMSC Clusters',
                          href: '/smsc/smscClusters',
                          icon: 'call_split'
                        },
                        {
                          title: 'SMPP SMSC Binds',
                          href: '/smsc/smscBinds',
                          icon: 'call_split'
                        },
                        {
                          title: 'SMPP SMSC Connections',
                          href: '/smsc/smscConnections',
                          icon: 'call_split'
                        },
                        {
                          title: 'SMPP SMSC Managers',
                          href: '/smsc/smscManagers',
                          icon: 'call_split'
                        },
                      ]
                    },
                    {
                      title: 'SMPP Servers',
                      platformService: 'smppserverd',
                      icon: 'call_split',
                      role: ['SMS_ADMIN'],
                      items: [
                        {
                          title: 'SMPP Servers',
                          href: '/smppserver/smppServers',
                          icon: 'call_split'
                        },
                        {
                          title: 'SMPP Server Ports',
                          href: '/smppserver/smppServerPorts',
                          icon: 'call_split'
                        },
                        {
                          title: 'SMPP Server Clients',
                          href: '/smppserver/smppServerClients',
                          icon: 'call_split'
                        }
                      ]
                    },
                  ]
                },
                {
                  title: 'Email2Msg',
                  icon: 'email',
                  platformService: 'mail2smsd',
                  items: [
                    {
                      title: 'Postmasters',
                      role: ['MAIL_ADMIN'],
                      href: '/mail2msg/postmasters',
                      icon: 'mail'
                    },
                    {
                      title: 'Msg Mailboxes',
                      role: ['MAIL_ADMIN'],
                      href: '/mail2msg/mailboxes',
                      icon: 'sms'
                    },
                    {
                      title: 'Defaults and Limits',
                      role: ['MAIL2SMS_ADMIN'],
                      href: '/mail2msg/configs',
                      icon: 'settings'
                    },
                    {
                      title: 'Users',
                      role: ['MAIL2SMS_ADMIN'],
                      href: '/mail2msg/users',
                      icon: 'contact_mail'
                    },
                  ]
                },
                {
                  title: 'DB2SMS',
                  icon: 'cloud_sync',
                  platformService: 'db2smsd',
                  role: ['DB2SMS_ADMIN', 'SYSTEM', 'SYSTEM_ADMIN'],
                  items: [
                    {
                      title: 'System Defaults',
                      role: ['SYSTEM', 'SYSTEM_ADMIN'],
                      href: '/db2sms/db2SmsDefaults',
                      icon: 'settings_suggest'
                    },
                    {
                      title: 'DB2SMS Accounts',
                      role: ['DB2SMS_ADMIN'],
                      href: '/db2sms/db2SmsAccounts',
                      icon: 'manage_accounts'
                    },
                  ]
                },
                {
                  title: 'FILE2SMS',
                  icon: 'file_upload',
                  platformService: 'file2smsd',
                  role: ['FILE2SMS_ADMIN', 'SYSTEM', 'SYSTEM_ADMIN'],
                  items: [
                    {
                      title: 'System Defaults',
                      role: ['SYSTEM', 'SYSTEM_ADMIN'],
                      href: '/sms/file2sms/defaults',
                      icon: 'settings_suggest'
                    },
                    {
                      title: 'FILE2SMS Accounts',
                      role: ['FILE2SMS_ADMIN'],
                      href: '/sms/file2sms/configs',
                      icon: 'manage_accounts'
                    },
                  ]
                },
                {
                  title: 'USSD',
                  icon: 'message',
                  role: ['USSD_ADMIN'],
                  service: 'USSD',
                  platformService: 'ussdd',
                  items: [
                    {
                      title: 'USSD Carriers',
                      href: '/ussdCarriers',
                      icon: 'call_split',
                    },
                    {
                      title: 'USSD Svc.Codes',
                      href: '/ussdSvcCodes',
                      icon: 'message',
                    },
                    {
                      title: 'USSD Servers',
                      href: '/ussdServers',
                      icon: 'subscriptions',
                    },
                  ]
                },
                {
                  title: 'Voice',
                  icon: 'record_voice_over',
                  role: ['VOICE_ADMIN'],
                  service: 'VOICE',
                  platformService: 'voiced',
                  items: [
                    {
                      title: 'Voice Carriers',
                      href: '/voice/voiceCarriers',
                      icon: 'record_voice_over'
                    },
                  ]
                },
                {
                  title: 'WhatsApp',
                  icon: 'chat',
                  role: ['CHAT_ADMIN'],
                  service: 'CHAT',
                  platformService: 'chatd',
                  items: [
                    {
                      title: 'Chat Carriers',
                      href: '/chat/chatCarriers',
                      icon: 'call_split',
                    }
                  ]
                },
                {
                  title: 'URL Shortening',
                  icon: 'link',
                  role: ['SURL_ADMIN'],
                  service: 'SURL',
                  platformService: 'surld',
                  items: [
                    {
                      title: 'Short URL Carriers',
                      href: '/surl/surlCarriers',
                      icon: 'link'
                    },
                  ]
                },
              ]
            },
            {
              title: 'Secure Access',
              icon: 'lock',
              role: ['SITE_ACCESS_ADMIN'],
              platformService: 'accessd',
              items: [
                {
                  title: 'Secure Contacts',
                  href: '/sa/contacts',
                  icon: 'phone'
                },
                {
                  title: 'Secure Regions',
                  href: '/sa/regions',
                  icon: 'terrain'
                },
                {
                  title: 'Secure Carriers',
                  icon: 'call_split',
                  href: '/sa/saCarriers'
                },
                {
                  title: 'Secure Cabinets',
                  icon: 'door_sliding',
                  href: '/access/saCabinets',
                  role: ['SITE_LOCK_CABINET']
                },
                {
                  title: 'Secure Locks',
                  icon: 'lock',
                  href: '/access/saLocks'
                },
                {
                  title: 'Secure Companies',
                  icon: 'business',
                  href: '/access/saHostCompanies',
                  role: ['COMPANY_ADMIN']
                },
                {
                  title: 'Secure Hosted Companies',
                  icon: 'apartment',
                  href: '/access/saHostedCompanies',
                  role: ['COMPANY_ADMIN']
                },
              ]
            },
            {
              title: 'Company Contacts',
              icon: 'phone',
              role: 'PHONEBOOK_ADMIN',
              service: 'PHONEBOOK',
              platformService: 'authd',
              items: [
                {
                  title: 'Contacts',
                  href: '/contacts',
                  icon: 'people'
                },
                {
                  title: 'Companies',
                  href: '/contactCompanies',
                  icon: 'business'
                }
              ]
            },
            {
              title: 'Integration',
              icon: 'sync_alt',
              items: [
                {
                  title: 'Webhooks',
                  icon: 'cloud_queue',
                  role: ['WEBHOOK_ADMIN'],
                  platformService: 'webhookd',
                  items: [
                    {
                      title: 'Webhooks',
                      href: '/webhook/webhooks',
                      icon: 'cloud_queue'
                    },
                  ]
                },
                {
                  title: 'Queues',
                  icon: 'list',
                  role: ['QUEUE_ADMIN'],
                  platformService: 'qd',
                  items: [
                    {
                      title: 'Queue Carriers',
                      href: '/q/queueCarriers',
                      icon: 'call_split'
                    },
                    {
                      title: 'Queue Configs',
                      href: '/q/queueConfigs',
                      icon: 'call_split'
                    },
                  ]
                },
              ]
            },
            {
              title: 'Forms',
              icon: 'description',
              service: 'FORM',
              platformService: 'formd',
              items: [
                {
                  title: 'Forms',
                  href: '/form/formDefs',
                  icon: 'description'
                },
              ]
            },
            {
              title: 'Payment',
              icon: 'monetization_on',
              role: ['PAYMENT_ADMIN'],
              service: 'PAYMENT',
              platformService: 'payd',
              items: [
                {
                  title: 'Payment Carriers',
                  href: '/pay/paymentCarriers',
                  icon: 'monetization_on'
                },
              ]
            },
            {
              title: 'TBB',
              icon: 'monetization_on',
              role: ['TBB_ADMIN'],
              service: 'TBB',
              platformService: 'tbbd',
              items: [
                {
                  title: 'TBB Carriers',
                  href: '/tbb/carriers',
                  icon: 'monetization_on'
                }
              ]
            },
            {
              title: 'Services',
              icon: 'group_work',
              role: ['SYSTEM_ADMIN'],
              href: '/services'
            },
          ]
        },
        {
          title: 'Platform',
          color: '#4E9940',
          role: 'PLATFORM_ADMIN',
          icon: 'settings_application',
          items: [
            {
              title: 'Platform Configuration',
              icon: 'settings',
              items: [
                {
                  title: 'Global Settings',
                  href: '/global/config',
                  icon: 'settings',
                  platformService: 'domaind',
                },
                {
                  title: 'Applications',
                  href: '/applications',
                  icon: 'settings',
                  platformService: 'authd',
                },
                {
                  title: 'SMS Types',
                  href: '/sms/smsTypes',
                  icon: 'sms',
                  platformService: 'smsd',
                },
                {
                  title: 'MNOs',
                  href: '/mnos',
                  icon: 'sms',
                  platformService: 'npd',
                },
                {
                  title: 'MSISDN Ranges',
                  href: '/np/ranges',
                  icon: 'tune',
                  platformService: 'npd',
                },
                {
                  title: 'Airtime Product Filters',
                  href: '/air/airtimeProductFilters',
                  icon: 'monetization_on',
                  platformService: 'aird',
                },
              ]
            },
            {
              title: 'Platform Services',
              icon: 'extension',
              items: [
                {
                  title: 'Services',
                  icon: 'extension',
                  href: '/system-applications'
                },
                {
                  title: 'Queues',
                  icon: 'list',
                  href: '/queues'
                },
                {
                  title: 'Cache',
                  icon: 'storage',
                  href: '/cache'
                },
              ]
            },
            {
              title: 'Global Controls',
              href: '/sys/controls',
              icon: 'slow_motion_video'
            },
            {
              title: 'Platform Health',
              href: '/sys/health',
              icon: 'healing'
            }
          ]
        },
        {
          title: 'Security',
          color: '#808F39',
          icon: 'security',
          items: [
            {
              title: 'Registrations',
              href: '/registrations',
              icon: 'assignment',
              service: 'REGISTRATION'
            },
            {
              title: 'Users',
              href: '/users',
              icon: 'people',
              role: ['USER_ADMIN']
            },
            {
              title: 'Companies',
              href: '/companies',
              icon: 'business',
              role: 'COMPANY_ADMIN'
            },
            {
              title: 'Departments',
              href: '/departments',
              icon: 'business_center',
              role: ['USER_ADMIN']
            },
            {
              title: 'Clients',
              href: '/clients',
              icon: 'security',
              role: 'CLIENT_ADMIN'
            },
            {
              title: 'Credentials',
              href: '/credentials',
              icon: 'vpn_key',
              role: ['CREDENTIAL_ADMIN']
            },
            {
              title: 'My Profile',
              href: '/me',
              icon: 'person'
            },
          ]
        },
        {
          title: 'Billing',
          icon: 'monetization_on',
          platformService: 'payd',
          items: [
            {
              service: 'PAYMENT',
              title: 'Payment Transactions',
              href: '/pay/paymentTransactions',
              icon: 'monetization_on',
              role: ['ACCOUNT_TRANSACT']
            },
            {
              title: 'Transact',
              href: '/account/accountTransact',
              icon: 'monetization_on',
              role: ['ACCOUNT_TRANSACT']
            },
            {
              title: 'Rating',
              href: '/ratings',
              icon: 'monetization_on',
              role: ['RATING_ADMIN']
            },
            {
              title: 'Discounts',
              href: '/discounts',
              icon: 'monetization_on',
              role: ['RATING_ADMIN']
            },
            {
              title: 'Audit Report',
              href: '/account/auditReport',
              icon: 'monetization_on',
              role: ['ACCOUNT_TRANSACT']
            },
          ]
        },
        {
          title: 'Account',
          icon: 'monetization_on',
          platformService: 'payd',
          role: ['ACCOUNT_ADMIN'],
          items: [
            {
              title: 'TopUp Account',
              href: '/pay/buy',
              icon: 'shopping_cart'
            },
            {
              title: 'Transactions',
              href: '/account/accountTransactions',
              icon: 'monetization_on'
            },
            {
              title: 'Invoices',
              href: '/account/accountReport',
              icon: 'monetization_on'
            },
            {
              title: 'Recons',
              href: '/account/accountShareReport',
              icon: 'monetization_on',
              role: ['REVENUE_SHARE'],
            },
          ]
        },
        {
          title: 'Support',
          icon: 'help',
          platformService: 'taskd',
          items: [
            {
              title: 'Requests',
              href: '/task/taskTickets',
              icon: 'work'
            }
          ]
        },
        {
          title: 'API',
          icon: 'code',
          service: 'API',
          items: [
            {
              title: 'API Explorer',
              href: '/apiExplorer',
              icon: 'code'
            },
            {
              title: 'HTTP2SMS Tester',
              icon: 'extension',
              href: '/http2sms/tester'
            }
          ]
        },
      ]
  };

  initMenu(menu, parent) {
    menu.expanded = menu.expanded || false;
    menu.parent = parent;
    menu.id = parent && parent.id ? parent.id + ':' + menu.title : menu.title ? menu.title : null;
    this.items[menu.id] = menu;
    this.allItems.push(menu);
    if (menu.href) {
      this.navItems.push(menu);
    }
    menu.platformService = menu.platformService || (parent && parent.platformService);
    if (menu.items) {
      menu.items.forEach((item, index) => {
        this.initMenu(item, menu);
      });
    }
  }

  thisOrChildFavorite(item) {
    if (item.items) {
      for (let i of item.items) {
        if (this.thisOrChildFavorite(i)) {
          return true;
        }
      }
    } else {
      return item.favorite;
    }

    return false;
  }

  canAccess(item: any): boolean {
    if (!item) {
      return false;
    }

    if (!item.role) {
      return true;
    }

    if (item.hidden) {
      return false;
    }

    return this.hasRole(item.role);
  }

  hasService(item: any): boolean {
    if (!item.service) {
      return true;
    }

    if (this.authService.isSystemUser()) {
      return true;
    }

    return this.authService.hasService(item.service);
  }

  hasRole(role: any) {
    if (!role) {
      return true;
    }

    if (this.authService.isSystemUser()) {
      return true;
    }

    if (typeof (role) == 'string') {
      return this.authService.hasRole(role);
    } else if (Array.isArray(role)) {
      for (let r of role) {
        if (this.authService.hasRole(r)) {
          return true;
        }
      }
    }
    return false;
  }

  openParent(item) {
    if (item.parent) {
      item.parent.expanded = true;
      this.openParent(item.parent);
    }
  }

  closeAll() {
    for (let item of this.allItems) {
      if (item.items) {
        item.expanded = false;
      }
    }
  }

  findByClosestUrl(url) {
    for (let i of this.navItems) {
      if (url.startsWith(i.href)) {
        return i;
      }
    }
  }

  setActive(item: any, nav = true): void {
    this.openParent(item);
    if (item.items) {
      item.expanded = !item.expanded;
    } else {
      if (this.activeItem) {
        this.activeItem.active = false;
      }
      this.activeItem = item;
      this.activeItemChanged.emit(this.activeItem);
      item.active = true;


      if (item.href) {
        let queryParams: any = item.queryParams || {};
        this.save();
        if (nav) {
          this.router.navigate([item.href], {queryParams: queryParams});
        }
      }
    }
  }

  save() {
    if (this.activeItem && this.activeItem.href) {
      localStorage.setItem("sidenav_activeItem", this.activeItem.id);
    }
  }

  load() {
    var active = this.findByClosestUrl(this.router.url);
    if (active) {
      if (this.canAccess(active)) { // in case permissions changed
        this.closeAll();
        this.setActive(active, false);
      }
    }
  }

  toggleFavorite(item: any) {
    item.favorite = !item.favorite;
    var favorites:any = localStorage.getItem("sidenav_favorites");
    if (!favorites) {
      favorites = [];
    } else {
      favorites = JSON.parse(favorites);
    }

    if (item.favorite) {
      favorites.push(item.id);
    } else {
      var i = favorites.indexOf(item.id);
      if (i != -1) {
        favorites.splice(i, 1)
      }
    }

    localStorage.setItem("sidenav_favorites", JSON.stringify(favorites));
    this.cloneFavorites();
  }

  removeFavorite(item) {
    if (!item) {
      return;
    }
    var i = this.favorites.indexOf(item.id);
    if (i != -1) {
      this.favorites.splice(i, 1)
    }
    localStorage.setItem("sidenav_favorites", JSON.stringify(this.favorites));
    this.cloneFavorites();
  }

  loadFavorites() {
    var favorites:any = localStorage.getItem("sidenav_favorites");
    if (favorites) {
      this.favorites = JSON.parse(favorites);
      for (let fav of this.favorites) {
        var favItem = this.items[fav];
        if (!this.canAccess(favItem)) {
            this.removeFavorite(favItem);
            continue;
        }
        favItem.favorite = true;
      }
    }
    this.cloneFavorites();
  }

  cloneFavorites() {
    var clone = [];
    for (let fav of this.favorites) {
      clone.push(fav);
    }
    this.favorites = clone;
  }
}
