import {BrowserModule, Title} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import { NgSelectModule } from '@ng-select/ng-select';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from "@angular/common/http";
import {AuthService, AuthInterceptor, AuthGuard} from "./services/auth.service";
import {LoginComponent} from "./misc/login.component";
import {MeComponent} from "./me/me.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MicroCommonModules} from "./shared/common.module";
import {SideNavComponent} from "./shared/sidenav";
import {ErrorDialogComponent} from "./shared/errorDialog";
import {DialogService} from "./services/dialog.service";
import {AlertService} from "./services/alert.service";
import {UsersComponent} from "./users/users.component";
import {UserDetailComponent} from "./users/user-detail.component";
import {EnvService} from "./services/env.service";
import {DeleteConfirmButtonComponent} from "./shared/deleteConfirm/delete-confirm.component";
import {ClientsComponent} from "./clients/clients.component";
import {ClientDetailComponent} from "./clients/client-detail.component";
import {AlarmsComponent} from "./alarms/alarms.component";
import {AlarmDetailComponent} from "./alarms/alarm-detail.component";
import {SystemApplicationsComponent} from "./system/system-applications.component";
import {QueuesComponent} from "./system/queues.component";
import {WorkOrdersComponent} from "./workorders/workorders.component";
import {IncidentsComponent} from "./incidents/incidents.component";
import {SoSmsComponent} from "./so/so-sms.component";
import {MapComponent} from "./geo/map.component";
import {CompaniesComponent} from "./companies/companies.component";
import {CompanyDetailComponent} from "./companies/company-detail.component";
import {DepartmentsComponent} from "./departments/departments.component";
import {DepartmentDetailComponent} from "./departments/department-detail.component";
import {CompanySelectComponent} from "./companies/company-select.component";
import {DepartmentSelectComponent} from "./departments/department-select.component";
import {WorkOrderDetailComponent} from "./workorders/workorder-detail.component";
import {RemedySiteSelectComponent} from "./remedy/remedy-site-select.component";
import {RemedyPeopleComponent} from "./remedy/remedy-people.component";
import {ConfirmDialogComponent} from "./shared/confirm-dialog.component";
import {RemedyPersonComponent} from "./remedy/remedy-person.component";
import {RemedyCompanySelectComponent} from "./remedy/remedy-company-select.component";
import {RemedyPersonSelectComponent} from "./remedy/remedy-person-select.component";
import {IncidentDetailComponent} from "./incidents/incident-detail.component";
import {ContactsComponent} from "./contacts/contacts.component";
import {ContactDetailComponent} from "./contacts/contact-detail.component";
import {ContactSelectComponent} from "./contacts/contact-select.component";
import {ContactDepartmentSelectComponent} from "./contactDepartments/contactDepartment-select.component";
import {ContactDepartmentDetailComponent} from "./contactDepartments/contactDepartment-detail.component";
import {ContactDepartmentsComponent} from "./contactDepartments/contactDepartments.component";
import {ContactCompanyDetailComponent} from "./contactCompanies/contactCompany-detail.component";
import {ContactCompaniesComponent} from "./contactCompanies/contactCompanies.component";
import {ContactCompanySelectComponent} from "./contactCompanies/contactCompany-select.component";
import {SmssComponent} from "./so/smss.component";
import {SmsStateSelectComponent} from "./so/sms-state-select.component";
import {ContactsRemedyImportComponent} from "./contacts/contacts-remedy-import.component";
import {SmsDashboardComponent} from "./so/sms-dashboard.component";
import {ANumbersComponent} from "./anumbers/anumbers.component";
import {ANumberDetailComponent} from "./anumbers/anumber-detail.component";
import {ANumberSelectComponent} from "./anumbers/anumber-select.component";
import {SmsMessageLogComponent} from "./so/sms-message-log.component";
import {SmsDrsComponent} from "./so/smsdrs.component";
import {SmsMosComponent} from "./so/smsmos.component";
import {SmsTypesComponent} from "./smsTypes/smsTypes.component";
import {SmsTypeDetailComponent} from "./smsTypes/smsType-detail.component";
import {SmsTypeSelectComponent} from "./smsTypes/smsType-select.component";
import {UserAuthSelectComponent} from "./security/user-auth-select.component";
import {UserAuthMultiSelectComponent} from "./security/user-auth-multiselect.component";
import {ClientAuthSelectComponent} from "./security/client-auth-select.component";
import {ClientAuthMultiSelectComponent} from "./security/client-auth-multiselect.component";
import {ClientGrantSelectComponent} from "./security/client-grant-select.component";
import {ClientGrantMultiSelectComponent} from "./security/client-grant-multiselect.component";
import {ServiceSelectComponent} from "./serviceOfferings/service-select.component";
import {ServiceMultiSelectComponent} from "./serviceOfferings/service-multiselect.component";
import {DatePickerComponent} from "./shared/datepicker/date-picker.component";
import {UserSelectComponent} from "./users/user-select.component";
import {InfobipDetailComponent} from "./infobip/infobip-detail.component";
import {SmtpDetailComponent} from "./smtp/smtp-detail.component";
import {SmtpsComponent} from "./smtp/smtps.component";
import {SmtpSelectComponent} from "./smtp/smtp-select.component";
import {ImapDetailComponent} from "./imap/imap-detail.component";
import {ImapSelectComponent} from "./imap/imap-select.component";
import {ImapsComponent} from "./imap/imaps.component";
import {RemedyConfigDetailComponent} from "./remedyConfig/remedyConfig-detail.component";
import {RemedyConfigsComponent} from "./remedyConfig/remedyConfigs.component";
import {RemedyConfigSelectComponent} from "./remedyConfig/remedyConfig-select.component";
import {MailMessageLogComponent} from "./mail/mail-message-log.component";
import {MailOverviewComponent} from "./mail/mail-overview.component";
import {MailPreviewComponent} from "./mail/mail-preview.component";
import {MailDashboardComponent} from "./mail/mail-dashboard.component";
import {MailSendComponent} from "./mail/mail-send.component";
import {FroalaViewModule, FroalaEditorModule} from "angular-froala-wysiwyg";
import {RichTextComponent} from "./shared/richtext/rich-text.component";
import {RichViewComponent} from "./shared/richtext/rich-view.component";
import {ContactTreeSelectComponent} from "./contacts/contact-tree-select.component";
import {CacheComponent} from "./system/cache.component";
import {SshServerSelectComponent} from "./ssh/ssh-select.component";
import {SshServersComponent} from "./ssh/sshs.component";
import {SshServerDetailComponent} from "./ssh/ssh-detail.component";
import {SmCheckDetailComponent} from "./sm/sm-detail.component";
import {SmChecksComponent} from "./sm/smcs.component";
import {TransformSelectComponent} from "./transform/transform-select.component";
import {SmCheckDashboard} from "./sm/sm-dashboard.component";
import {SmChecksLogComponent} from "./sm/smcs-log.component";
import {SmLogDialogComponent} from "./sm/sm-log-dialog.component";
import {SqlServerDetailComponent} from "./sql/sql-detail.component";
import {SqlServerSelectComponent} from "./sql/sql-select.component";
import {SqlServersComponent} from "./sql/sqls.component";
import {ObjectGridComponent} from "./shared/objectGrid/object-grid.component";
import {AceModule} from "ngx-ace-wrapper";
import {ACE_CONFIG} from "ngx-ace-wrapper";
import {AceConfigInterface} from "ngx-ace-wrapper";
import {NeSelectComponent} from "./ne/ne-select.component";
import {NesComponent} from "./ne/nes.component";
import {NeDetailComponent} from "./ne/ne-detail.component";
import {MicroPicklistComponent} from "./shared/picklist/micro-picklist.component";
import {LookupService} from "./srvs/lookup";
import {JpaFilterEditorComponent} from "./jpa/jpa-filter-editor.component";
import {JpaFilterOpEditorComponent} from "./jpa/jpa-filter-op-editor.component";
import {AlarmFilterDetailComponent} from "./alarmFilters/alarmFilter-detail.component";
import {AlarmFilterSelectComponent} from "./alarmFilters/alarmFilter-select.component";
import {DiagramEditorComponent} from "./diagrams/diagram-editor.component";
import {DashboardsComponent} from "./dashboards/dashboards.component";
import {DashboardDetailComponent} from "./dashboards/dashboard-detail.component";
import {DashboardViewerComponent} from "./dashboards/dashboard-viewer.component";
import {DiagramViewerComponent} from "./diagrams/diagram-viewer.component";
import {ColorService} from "./srvs/colors";
import {MapsComponent} from "./geo/maps.component";
import {MapDetailComponent} from "./geo/map-detail.component";
import {MapViewerComponent} from "./geo/map-viewer.component";
import {AlarmsDashboardComponent} from "./alarms/alarms.dashboard.component";
import {SitesComponent} from "./domain/site/sites.component";
import {SiteDetailComponent} from "./domain/site/site-detail.component";
import {SiteSelectComponent} from "./domain/site/site-select.component";
import {ReactorsComponent} from "./reactors/reactors.component";
import {ReactorDetailComponent} from "./reactors/reactor-detail.component";
import {ReactorLogsComponent} from "./reactors/reactor-logs.component";
import {ReactorLogDetailComponent} from "./reactors/reactor-log-detail.component";
import {SubscriptionsComponent} from "./subscriptions/subscriptions.component";
import {SubscriptionDetailComponent} from "./subscriptions/subscription-detail.component";
import {SmCheckDataComponent} from "./sm/sm-data.component";
import {SmChartComponent} from "./sm/sm-chart.component";
import {SmDatagridSelectComponent} from "./sm/sm-datagrid-select.component";
import {SmDatagridDetailComponent} from "./sm/sm-datagrid-detail.component";
import {AlarmGridsComponent} from "./alarmGrids/alarmGrids.component";
import {AlarmGridSelectComponent} from "./alarmGrids/alarmGrid-select.component";
import {AlarmGridDetailComponent} from "./alarmGrids/alarmGrid-detail.component";
import {SmChartsComponent} from "./sm/sm-charts.component";
import {SmCheckSelectComponent} from "./sm/sm-check-select.component";
import {SmGridsComponent} from "./sm/sm-grids.component";
import {MicroCronComponent} from "./shared/cron/micro-cron.component";
import {NetDetailComponent} from "./net/net-detail.component";
import {NetSelectComponent} from "./net/net-select.component";
import {NetsComponent} from "./net/nets.component";
import {MicroSeveritySelectComponent} from "./shared/severity-select/micro-severity-select.component";
import {MicroBackComponent} from "./shared/back/micro-back.component";
import {SqlServerTypeSelectComponent} from "./sql/sql-type-select.component";
import {SqlServerGroupDetailComponent} from "./sqlServerGroups/sqlServerGroup-detail.component";
import {SqlServerGroupSelectComponent} from "./sqlServerGroups/sqlServerGroup-select.component";
import {SqlServerGroupsComponent} from "./sqlServerGroups/sqlServerGroups.component";
import {SmsCarriersComponent} from "./smsCarriers/smsCarriers.component";
import {SmsCarrierDetailComponent} from "./smsCarriers/smsCarrier-detail.component";
import {SmsCarrierSelectComponent} from "./smsCarriers/smsCarrier-select.component";
import {SmsCarrierTypeSelectComponent} from "./smsCarriers/smsCarrierType-select.component";
import {ReasonDialogComponent} from "./shared/reasonDialog/reason-dialog.component";
import {MocDetailComponent} from "./moc/moc-detail.component";
import {MocSelectComponent} from "./moc/moc-select.component";
import {MocsComponent} from './moc/mocs.component';
import {SpecProbDetailComponent} from "./specProb/specProb-detail.component";
import {SpecProbSelectComponent} from "./specProb/specProb-select.component";
import {SpecProbsComponent} from "./specProb/specProbs.component";
import {SideNavMenuComponent} from "./shared/sidenav/sidenav-menu";
import {SmChartSelectComponent} from "./sm/sm-chart-select.component";
import {LoadingInterceptor, LoadingService} from "./services/loading.service";
import {AlarmOverviewDashboard} from "./alarms/alarm-overview.component";
import {MicroSelectComponent} from "./shared/select/micro-select.component";
import {NpRangeDetailComponent} from "./npRanges/npRange-detail.component";
import {NpRangesComponent} from "./npRanges/npRanges.component";
import {TbbCarriersComponent} from "./tbbCarriers/tbbCarriers.component";
import {TbbCarrierTypeSelectComponent} from "./tbbCarriers/tbbCarrierType-select.component";
import {TbbCarrierSelectComponent} from "./tbbCarriers/tbbCarrier-select.component";
import {TbbCarrierDetailComponent} from "./tbbCarriers/tbbCarrier-detail.component";
import {CentiliPaymentResultsComponent} from "./centili/centiliPaymentResults.component";
import {ServicesComponent} from "./srv/services.component";
import {ServiceDetailComponent} from "./srv/service-detail.component";
import {SvcSelectComponent} from "./srv/svc-select.component";
import {TbbOmniLogsComponent} from "./tbb/tbbOmniLogs.component";
import {TbbMtnLogsComponent} from "./tbb/tbbMtnLogs.component";
import {TbbLogsComponent} from "./tbb/tbbLogs.component";
import {TbbTokensComponent} from "./tbb/tbbTokens.component";
import {TbbOmniSubscriptionsComponent} from "./tbb/tbbOmniSubscriptions.component";
import {TbbOmniBillingsComponent} from "./tbb/tbbOmniBillings.component";
import {AnumberTypeSelectComponent} from "./anumbers/anumberType-select.component";
import {MaosComponent} from "./maos/maos.component";
import {MaoDetailComponent} from "./maos/mao-detail.component";
import {NotificationLogsComponent} from "./notificationLogs/notificationLogs.component";
import {SubscriptionSelectComponent} from "./subscriptions/subscription-select.component";
import {AuditsComponent} from "./audits/audits.component";
// import {NgxDiffModule} from "ngx-diff";
import {ClientSelectComponent} from "./clients/client-select.component";
import {SaRequestComponent} from "./sa/tokens/sa-request.component";
import {UssdCarriersComponent} from "./ussdCarriers/ussdCarriers.component";
import {UssdCarrierDetailComponent} from "./ussdCarriers/ussdCarrier-detail.component";
import {UssdCarrierSelectComponent} from "./ussdCarriers/ussdCarrier-select.component";
import {UssdSvcCodesComponent} from "./ussdSvcCode/ussdSvcCodes.component";
import {UssdSvcCodeDetailComponent} from "./ussdSvcCode/ussdSvcCode-detail.component";
import {UssdSvcCodeSelectComponent} from "./ussdSvcCode/ussdSvcCode-select.component";
import {UssdSessionLogsComponent} from "./ussdSessionLogs/ussdSessionLogs.component";
import {UssdSessionLogDetailComponent} from "./ussdSessionLogs/ussdSessionLog-detail.component";
import {UssdSessionsComponent} from "./ussdSessions/ussdSessions.component";
import {UssdSessionDetailComponent} from "./ussdSessions/ussdSession-detail.component";
import {UssdCarrierTypeSelectComponent} from "./ussdCarriers/ussdCarrierType-select.component";
import {UssdDashboardComponent} from "./ussdDashboards/ussd-dashboard.component";
import {PermGridComponent} from "./perm/perm-grid.component";
import {PermLogsComponent} from "./permLogs/permLogs.component";
import {PermTypeSelectComponent} from "./perm/perm-type-select.component";
import {VoiceRequestComponent} from "./voice/voice-request.component";
import {AirtimeCarriersComponent} from "./airtimeCarriers/airtimeCarriers.component";
import {AirtimeCarrierDetailComponent} from "./airtimeCarriers/airtimeCarrier-detail.component";
import {AirtimeCarrierSelectComponent} from "./airtimeCarriers/airtimeCarrier-select.component";
import {AirtimeCarrierTypeSelectComponent} from "./airtimeCarriers/airtimeCarrierType-select.component";
import {NotifService} from "./services/notif.service";
import {NotifComponent} from "./notif/notif.component";
import {HealthDetailComponent} from "./sys/health-detail.component";
import {HealthReportComponent} from "./sys/health-report.component";
import {SwaggerComponent} from "./swagger/swagger.component";
import {VoiceCarrierSelectComponent} from "./voiceCarriers/voiceCarrier-select.component";
import {VoiceCarrierDetailComponent} from "./voiceCarriers/voiceCarrier-detail.component";
import {VoiceCarrierTypeSelectComponent} from "./voiceCarriers/voiceCarrierType-select.component";
import {VoiceCarriersComponent} from "./voiceCarriers/voiceCarriers.component";
import {TaskCarrierSelectComponent} from "./taskCarriers/taskCarrier-select.component";
import {TaskCarrierTypeSelectComponent} from "./taskCarriers/taskCarrierType-select.component";
import {TaskCarrierDetailComponent} from "./taskCarriers/taskCarrier-detail.component";
import {TaskCarriersComponent} from "./taskCarriers/taskCarriers.component";
import {TaskProductSelectComponent} from "./tasks/taskProduct-select.component";
import {TaskProjectSelectComponent} from "./tasks/taskProject-select.component";
import {TaskPhaseSelectComponent} from "./tasks/taskPhase-select.component";
import {AirtimeProductSelectComponent} from "./airtimeProducts/airtimeProduct-select.component";
import {AirtimeProductDetailComponent} from "./airtimeProducts/airtimeProduct-detail.component";
import {AirtimeProductsComponent} from "./airtimeProducts/airtimeProducts.component";
import {MnoDetailComponent} from "./mnos/mno-detail.component";
import {MnosComponent} from "./mnos/mnos.component";
import {MnoSelectComponent} from "./mnos/mno-select.component";
import {MicroYesNoSelectComponent} from "./shared/yesNo-select/micro-yesNo-select.component";
import {MicroEnabledSelectComponent} from "./shared/enabled-select/micro-enabled-select.component";
import {AirtimeRechargesComponent} from "./air/airtimeRecharges/airtimeRecharges.component";
import {AirtimeRechargeDetailComponent} from "./air/airtimeRecharges/airtimeRecharge-detail.component";
import {AirtimeProductTypeSelectComponent} from "./airtimeProducts/airtimeProductType-select.component";
import {AirtimeStatusSelectComponent} from "./air/airtimeStatus-select.component";
import {AirtimeRechargeRequestDetailComponent} from "./air/airtimeRecharges/airtimeRechargeRequest-detail.component";
import {MicroLookupComponent} from "./shared/lookup/micro-lookup.component";
import {MicroCompanyLookupComponent} from "./shared/lookup/micro-company-lookup.component";
import {MicroDepartmentLookupComponent} from "./shared/lookup/micro-department-lookup.component";
import {MicroUserLookupComponent} from "./shared/lookup/micro-user-lookup.component";
import { MicroEmailLinkComponent } from './shared/emailLink/micro-emailLink.component';
import { MicroTelLinkComponent } from './shared/telLink/micro-telLink.component';
import {MicroImLinkComponent} from "./shared/imLink/micro-imLink.component";
import {EscalationsComponent} from "./notif/escalations/escalations.component";
import {EscalationDetailComponent} from "./notif/escalations/escalation-detail.component";
import {MicroContactDepartmentLookupComponent} from "./shared/lookup/micro-contactDepartment-lookup.component";
import {MicroContactLookupComponent} from "./shared/lookup/micro-contact-lookup.component";
import {MicroContactCompanyLookupComponent} from "./shared/lookup/micro-contactCompany-lookup.component";
import {MicroNeLookupComponent} from "./shared/lookup/micro-ne-lookup.component";
import {MicroSqlLookupComponent} from "./shared/lookup/micro-sql-lookup.component";
import {LinkedAccountsComponent} from "./linkedAccounts/linkedAccounts.component";
import {MicroTaskCarrierLookupComponent} from "./shared/lookup/micro-taskCarrier-lookup.component";
import {LinkedAccountTypeSelectComponent} from "./linkedAccounts/linkedAccountType-select.component";
import { UpdateConfirmButtonComponent } from './shared/updateConfirm/update-confirm.component';
import {MicroUserMsisdnLookupComponent} from "./shared/lookup/micro-userMsisdn-lookup.component";
import {MicroUserEmailLookupComponent} from "./shared/lookup/micro-userEmail-lookup.component";
import {MicroCheckboxComponent} from "./shared/checkbox/micro-checkbox.component";
import {MicroRadioComponent} from "./shared/radio/micro-radio.component";
import {NavService} from "./srvs/nav";
// import {BotDetectCaptchaModule} from "angular-captcha";
import {MicroCaptchaComponent} from "./shared/captcha/micro-captcha.component";
import {ChartModule, HIGHCHARTS_MODULES} from "angular-highcharts";
import * as highstock from 'highcharts/modules/stock.src';
import * as highmap from 'highcharts/modules/map.src';
import * as sankey from 'highcharts/modules/sankey.src';
import * as dependencywheel from 'highcharts/modules/dependency-wheel.src';
import * as accessibility from 'highcharts/modules/accessibility.src'
import {MicroChartComponent} from "./shared/chart/micro-chart.component";
import {DateRangeComponent} from "./shared/daterange/date-range.component";
import {MicroUssdSvcCodeLookupComponent} from "./shared/lookup/micro-ussdSvcCode-lookup.component";
import {MicroUssdCarrierLookupComponent} from "./shared/lookup/micro-ussdCarrier-lookup.component";
import {MicroUssdSessionLookupComponent} from "./shared/lookup/micro-ussdSession-lookup.component";
import {UssdSimComponent} from "./ussdSim/ussdSim.component";
import { AiBtTaskDefSelectComponent } from './ai/bts/aiBt-taskDef-select.component';
import {AiBtDetailComponent} from "./ai/bts/aiBt-detail.component";
import {AiBtEditorComponent} from "./ai/bts/aiBt-editor.component";
import {AiBtSelectComponent} from "./ai/bts/aiBt-select.component";
import {AiBtsComponent} from "./ai/bts/aiBts.component";
import {MicroEventTypeSelectComponent} from "./domain/event-type-select.component";
import {MicroEventDialogComponent} from "./shared/eventDialog/event-dialog.component";
import {AiBtParamsEditorComponent} from "./ai/bts/aiBt-params-editor.component";
import { TransformEditorComponent } from './transform/transform-editor.component';
import {AiBtObjectMappingsComponent} from "./ai/bts/aiBt-object-mappings.component";
import {AiBtMapComponent} from "./ai/bts/aiBt-map.component";
import { AiBtEnumComponent } from './ai/bts/aiBt-enum.component';
import { UserTreeSelectComponent } from './users/user-tree-select.component';
import {MicroNetLookupComponent} from "./shared/lookup/micro-net-lookup.component";
import { MicroSshLookupComponent } from './shared/lookup/micro-ssh-lookup.component';
import { TreeSelectComponent } from './shared/tree-select/tree-select.component';
import { NeTreeSelectComponent } from './ne/ne-tree-select.component';
import { FormDefsComponent } from './form/formDefs/formDefs.component';
import { FormDefDetailComponent } from './form/formDefs/formDef-detail.component';
import { FormDefSelectComponent } from './form/formDefs/formDef-select.component';
import {FormFieldDefTypeSelectComponent} from "./form/formDefs/formFieldDefType-select.component";
import {FormDefEditorComponent} from "./form/formDefs/formDef-editor.component";
import {FormFieldEditorComponent} from "./form/formDefs/formField-editor.component";
import {FormDefListEditorComponent} from "./form/formDefs/formDefList-editor.component";
import {FormEditorComponent} from "./form/formDefs/form-editor.component";
import {VoiceLogsComponent} from "./voice/voiceLogs/voiceLogs.component";
import {VoiceLogDetailComponent} from "./voice/voiceLogs/voiceLog-detail.component";
import {MicroVoiceCarrierLookupComponent} from "./shared/lookup/micro-voiceCarrier-lookup.component";
import {UsageDashboardComponent} from "./usage/usage-dashboard.component";
import {DoChartComponent} from "./shared/chart/do-chart.component";
import {VoiceDashboardComponent} from "./voice/voice-dashboard.component";
import {AirtimeDashboardComponent} from "./air/airtime-dashboard.component";
import {DataExploreComponent} from "./data/data-explore.component";
import {WhatsappIncomingMsgLogsComponent} from "./chat/whatsappIncomingMsgLogs/whatsappIncomingMsgLogs.component";
import {WhatsappIncomingMsgLogDetailComponent} from "./chat/whatsappIncomingMsgLogs/whatsappIncomingMsgLog-detail.component";
import {ChatCarriersComponent} from "./chat/chatCarriers/chatCarriers.component";
import {ChatCarrierSelectComponent} from "./chat/chatCarriers/chatCarrier-select.component";
import {ChatCarrierDetailComponent} from "./chat/chatCarriers/chatCarrier-detail.component";
import {ChatCarrierTypeSelectComponent} from "./chat/chatCarriers/chatCarrierType-select.component";
import {WhatsappDashboardComponent} from "./chat/whatsapp-dashboard.component";
import {WhatsappSendComponent} from "./chat/whatsapp-send.component";
import {MicroChatCarrierLookupComponent} from "./shared/lookup/micro-chatCarrier-lookup.component";
import {MicroWhatsappMessageTypeSelectComponent} from "./chat/whatsapp-message-type-select.component";
import {WhatsappOutgoingMsgLogDetailComponent} from "./chat/whatsappOutgoingMsgLogs/whatsappOutgoingMsgLog-detail.component";
import {WhatsappOutgoingMsgLogsComponent} from "./chat/whatsappOutgoingMsgLogs/whatsappOutgoingMsgLogs.component";
import {MicroChatSenderLookupComponent} from "./shared/lookup/micro-chatSender-lookup.component";
import {ChatSendersComponent} from "./chat/chatSenders/chatSenders.component";
import {ChatSenderDetailComponent} from "./chat/chatSenders/chatSender-detail.component";
import {ChatSenderSelectComponent} from "./chat/chatSenders/chatSender-select.component";
import {TasksComponent} from "./tasks/tasks.component";
import {TaskResourceSelectComponent} from "./tasks/taskResource-select.component";
import {TaskDetailComponent} from "./tasks/task-detail.component";
import {TaskStatusSelectComponent} from "./tasks/taskStatus-select.component";

// Import all Froala Editor plugins.
import 'froala-editor/js/plugins.pkgd.min.js';
import {TaskSprintSelectComponent} from "./tasks/taskSprint-select.component";
import { TaskGetResourceComponent } from './tasks/task-getResource.component';
import {TaskSetResourceComponent} from "./tasks/task-setResource.component";
import {TaskSetStatusComponent} from "./tasks/task-setStatus.component";
import {TaskAddFeedbackComponent} from "./tasks/task-addFeedback.component";
import {TaskGetSprintsComponent} from "./tasks/task-getSprints.component";
import {TaskSetSprintComponent} from "./tasks/task-setSprint.component";
import {TaskFindTaskComponent} from "./tasks/task-findTask.component";
import {TaskGetResourceByMsisdnComponent} from "./tasks/task-getResourceByMsisdn.component";
import {TaskGetTasksByResourceComponent} from "./tasks/task-getTasksByResource.component";
import {TaskLogsComponent} from "./tasks/taskLogs/taskLogs.component";
import {TaskLogDetailComponent} from "./tasks/taskLogs/taskLog-detail.component";
import {WebhooksComponent} from "./webhook/webhooks/webhooks.component";
import {WebhookSelectComponent} from "./webhook/webhooks/webhook-select.component";
import {WebhookDetailComponent} from "./webhook/webhooks/webhook-detail.component";
import {WebhookLogsComponent} from "./webhook/webhookLogs/webhookLogs.component";
import {WebhookLogDetailComponent} from "./webhook/webhookLogs/webhookLog-detail.component";
import {MicroWebhookLookupComponent} from "./shared/lookup/micro-webhook-lookup.component";
import {SurlCarriersComponent} from "./surlCarriers/surlCarriers.component";
import {SurlCarrierDetailComponent} from "./surlCarriers/surlCarrier-detail.component";
import {SurlCarrierSelectComponent} from "./surlCarriers/surlCarrier-select.component";
import {SurlCarrierTypeSelectComponent} from "./surlCarriers/surlCarrierType-select.component";
import {SurlsComponent} from "./surl/surls/surls.component";
import {SurlDetailComponent} from "./surl/surls/surl-detail.component";
import {MicroSurlCarrierLookupComponent} from "./shared/lookup/micro-surlCarrier-lookup.component";
import {SysService} from "./services/sys.service";
import {QueueCarriersComponent} from "./q/queueCarriers/queueCarriers.component";
import {QueueCarrierTypeSelectComponent} from "./q/queueCarriers/queueCarrierType-select.component";
import {QueueCarrierDetailComponent} from "./q/queueCarriers/queueCarrier-detail.component";
import {QueueCarrierSelectComponent} from "./q/queueCarriers/queueCarrier-select.component";
import {QueueInMessagesComponent} from "./q/queueInMessages/queueInMessages.component";
import {QueueInMessageDetailComponent} from "./q/queueInMessages/queueInMessage-detail.component";
import {MicroQueueCarrierLookupComponent} from "./shared/lookup/micro-queueCarrier-lookup.component";
import {QueueOutMessagesComponent} from "./q/queueOutMessages/queueOutMessages.component";
import {QueueOutMessageDetailComponent} from "./q/queueOutMessages/queueOutMessage-detail.component";
import {AiBtLogsComponent} from "./ai/bts/logs/aiBtLogs.component";
import {AiBtLogDetailComponent} from "./ai/bts/logs/aiBtLog-detail.component";
import {MicroAiBtLookupComponent} from "./shared/lookup/micro-aiBt-lookup.component";
import {QueuePollRequestDetailComponent} from "./q/queuePollRequests/queuePollRequest-detail.component";
import {QueuePollRequestsComponent} from "./q/queuePollRequests/queuePollRequests.component";
import {QueueConfigsComponent} from "./q/queueConfigs/queueConfigs.component";
import {QueueConfigDetailComponent} from "./q/queueConfigs/queueConfig-detail.component";
import {QueueConfigSelectComponent} from "./q/queueConfigs/queueConfig-select.component";
import {KafkaOverviewComponent} from "./q/kafka/kafka-overview.component";
import {MicroBreadcrumbComponent} from "./shared/breadcrumb/micro-breadcrumb.component";
import {CmdServiceSelectComponent} from "./cmd/cmd-service-select.component";
import {ObjectGroupsComponent} from "./objectGroups/objectGroups.component";
import {ObjectGroupSelectComponent} from "./objectGroups/objectGroup-select.component";
import {ObjectGroupDetailComponent} from "./objectGroups/objectGroup-detail.component";
import {MicroServiceTypeSelectComponent} from "./domain/service-type-select.component";
import {CmdObjectSelectComponent} from "./cmd/cmd-object-select.component";
import {CmdObjectPicklistComponent} from "./cmd/cmd-object-picklist.component";
import {CmdEntityTypeSelectComponent} from "./cmd/cmd-entity-type-select.component";
import {CmdServiceLookupComponent} from "./cmd/cmd-service-lookup.component";
import {ObjectGroupPicklistComponent} from "./objectGroups/objectGroup-picklist.component";
import {MicroObjectGroupLookupComponent} from "./shared/lookup/micro-objectGroup-lookup.component";
import {PaymentCarriersComponent} from "./pay/paymentCarriers/paymentCarriers.component";
import {PaymentCarrierTypeSelectComponent} from "./pay/paymentCarriers/paymentCarrierType-select.component";
import {PaymentCarrierDetailComponent} from "./pay/paymentCarriers/paymentCarrier-detail.component";
import {PaymentCarrierSelectComponent} from "./pay/paymentCarriers/paymentCarrier-select.component";
import {BuyComponent} from "./pay/buy/buy.component";
import {GlobalConfigComponent} from "./global/global-config.component";
import {PaymentTransactionsComponent} from "./pay/paymentTransactions/paymentTransactions.component";
import {PaymentTransactionDetailComponent} from "./pay/paymentTransactions/paymentTransaction-detail.component";
import {MicroPaymentCarrierLookupComponent} from "./shared/lookup/micro-paymentCarrier-lookup.component";
import {PaymentTransactionStatusSelectComponent} from "./pay/paymentTransactions/paymentTransactionStatus-select.component";
import { BuySuccessComponent } from './pay/buy/buy-success.component';
import {BuyCancelledComponent} from "./pay/buy/buy-cancelled.component";
import {MailMessageLogIncomingComponent} from "./mail/mail-message-log-incoming.component";
import { MailMessageLogOutgoingComponent } from './mail/mail-message-log-outgoing.component';
import {MicroSmsCarrierLookupComponent} from "./shared/lookup/micro-smsCarrier-lookup.component";
import { MicroSmsTypeLookupComponent } from './shared/lookup/micro-smsType-lookup.component';
import { MicroAnumberLookupComponent } from './shared/lookup/micro-anumber-lookup.component';
import { MicroMnoLookupComponent } from './shared/lookup/micro-mno-lookup.component';
import {QueueDashboardComponent} from "./q/queue-dashboard.component";
import {QueuePollComponent} from "./q/queue-poll.component";
import { TestComponent } from './test.component';
import { ItsmCarriersComponent } from './itsmCarriers/itsmCarriers.component';
import {ItsmCarrierDetailComponent} from "./itsmCarriers/itsmCarrier-detail.component";
import {ItsmCarrierTypeSelectComponent} from "./itsmCarriers/itsmCarrierType-select.component";
import {ItsmCarrierSelectComponent} from "./itsmCarriers/itsmCarrier-select.component";
import {ItsmFindRequestComponent} from "./itsm/requests/itsm-findRequest.component";
import { ItsmCreateRequestComponent } from './itsm/requests/itsm-createRequest.component';
import { ItsmObjectTypeSelectComponent } from './itsmObjects/itsmObjectType-select.component';
import { ItsmObjectsComponent } from './itsmObjects/itsmObjects.component';
import {ItsmObjectDetailComponent} from "./itsmObjects/itsmObject-detail.component";
import { ItsmObjectSelectComponent } from './itsmObjects/itsmObject-select.component';
import {MicroItsmCarrierLookupComponent} from "./shared/lookup/micro-itsmCarrier-lookup.component";
import {MicroItsmObjectLookupComponent} from "./shared/lookup/micro-itsmObject-lookup.component";
import {ItsmLogsComponent} from "./tasks/itsmLogs/itsmLogs.component";
import {ItsmLogDetailComponent} from "./tasks/itsmLogs/itsmLog-detail.component";
import {ItsmRequestDetailComponent} from "./itsm/requests/itsm-requestDetail.component";
import {ItsmFindChangeComponent} from "./itsm/changes/itsm-findChange.component";
import {ItsmChangeDetailComponent} from "./itsm/changes/itsm-changeDetail.component";
import {ItsmCreateChangeComponent} from "./itsm/changes/itsm-createChange.component";
import {ItsmObjectPicklistComponent} from "./itsmObjects/itsmObject-picklist.component";
import { ItsmFindAssetComponent } from './itsm/assets/itsm-findAsset.component';
import {ItsmCreateAssetComponent} from "./itsm/assets/itsm-createAsset.component";
import {ItsmAssetDetailComponent} from "./itsm/assets/itsm-assetDetail.component";
import { ItsmFindProductTypeComponent } from './itsm/productTypes/itsm-findProductType.component';
import { ItsmCreateProductTypeComponent } from './itsm/productTypes/itsm-createProductType.component';
import { MicroSubscriptionLookupComponent } from './shared/lookup/micro-subscription-lookup.component';
import {AccountTransactionsComponent} from "./account/accountTransactions/accountTransactions.component";
import {AccountTransactionDetailComponent} from "./account/accountTransactions/accountTransaction-detail.component";
import {AccountTransactionStatusSelectComponent} from "./account/accountTransactions/accountTransactionStatus-select.component";
import {AccountTransactionTypeSelectComponent} from "./account/accountTransactions/accountTransactionType-select.component";
import { AccountTransactComponent } from './account/transact/accountTransact.component';
import {AuthorizeComponent} from "./auth/authorize.component";
import { RegisterComponent } from './auth/register.component';
import {CountrySelectComponent} from "./auth/country-select.component";
import {RatingsComponent} from "./ratings/ratings.component";
import {DiscountsComponent} from "./discounts/discounts.component";
import {DiscountDetailComponent} from "./discounts/discount-detail.component";
import {RatingDetailComponent} from "./ratings/rating-detail.component";
import {RatingSelectComponent} from "./ratings/rating-select.component";
import {MicroRatingLookupComponent} from "./shared/lookup/micro-rating-lookup.component";
import { MicroMonthSelectComponent } from './shared/month-select/micro-month-select.component';
import { MicroSmtpMailboxLookupComponent } from './shared/lookup/micro-smtp-mailbox-lookup.component';
import {SmsMoToEmailsComponent} from "./smsMoToEmails/smsMoToEmails.component";
import {SmsMoToEmailDetailComponent} from "./smsMoToEmails/smsMoToEmail-detail.component";
import {AccountReportComponent} from "./account/accountReport/accountReport.component";
import {AccountReportMonthComponent} from "./account/accountReport/accountReportMonth.component";
import { AirtimePurchaseComponent } from './air/airtimePurchase.component';
import {AirtimeProductFiltersComponent} from "./airtimeProductFilters/airtimeProductFilters.component";
import {AirtimeProductFilterDetailComponent} from "./airtimeProductFilters/airtimeProductFilter-detail.component";
import {AirtimeProductFilterSelectComponent} from "./airtimeProductFilters/airtimeProductFilter-select.component";
import {CacheService} from "./services/cache.service";
import {MicroAirtimeProductLookupComponent} from "./shared/lookup/micro-airtimeProduct-lookup.component";
import { VatTypeSelectComponent } from './vat/vatType-select.component';
import { MicroAirtimeCarrierLookupComponent } from './shared/lookup/micro-airtimeCarrier-lookup.component';
import { AuditReportComponent } from './account/auditReport/auditReport.component';
import {AuditReportMonthComponent} from "./account/auditReport/auditReportMonth.component";
import {MicroYearMonthSelectComponent} from "./shared/year-month-select/micro-year-month-select.component";
import {TaskTicketsComponent} from "./taskTickets/taskTickets.component";
import {TaskTicketDetailComponent} from "./taskTickets/taskTicket-detail.component";
import {TaskTicketTypeSelectComponent} from "./taskTickets/taskTicketType-select.component";
import { RegionsComponent } from './domain/regions/regions.component';
import {RegionDetailComponent} from "./domain/regions/region-detail.component";
import {RegionSelectComponent} from "./domain/regions/region-select.component";
import {MicroRegionLookupComponent} from "./shared/lookup/micro-region-lookup.component";
import { SaCarriersComponent } from './sa/carriers/saCarriers.component';
import { SaCarrierDetailComponent } from './sa/carriers/saCarrier-detail.component';
import { SaCarrierSelectComponent } from './sa/carriers/saCarrier-select.component';
import { SaCarrierTypeSelectComponent } from './sa/carriers/saCarrierType-select.component';
import {SaContactsComponent} from "./sa/contacts/sa-contacts.component";
import {SaRegionsComponent} from "./sa/regions/sa-regions.component";
import {MicroEntitySearchDialogComponent} from "./shared/entitySearchDialog/entity-search-dialog.component";
import {SaTokenStatusSelectComponent} from "./sa/tokens/saTokenStatus-select.component";
import {SaTokenRequestLogsComponent} from "./sa/tokens/saTokenRequestLogs.component";
import {SaTokenRequestLogDetailComponent} from "./sa/tokens/saTokenRequestLog-detail.component";
import { MicroSaCarrierLookupComponent } from './shared/lookup/micro-saCarrier-lookup.component';
import {MicroSiteLookupComponent} from "./shared/lookup/micro-site-lookup.component";
import {SaTokenSourceTypeSelectComponent} from "./sa/tokens/saTokenSourceType-select.component";
import {RegionAuditLogsComponent} from "./sa/regions/regionAuditLogs.component";
import {SaTrackersComponent} from "./sa/trackers/saTrackers.component";
import {ReactorSelectComponent} from "./reactors/reactor-select.component";
import {MicroReactorLookupComponent} from "./shared/lookup/micro-reactor-lookup.component";
import {BlockAllowTypeSelectComponent} from "./shared/blockAllowType/blockAllowType-select.component";
import {MicroNotificationTypeSelectComponent} from "./domain/notification-type-select.component";
import {ColorSketchModule} from "ngx-color/sketch";
import {ThemeService} from "./services/theme.service";
import {ColorChromeModule} from "ngx-color/chrome";
import {MicroValidLabelComponent} from "./shared/valid-label/micro-valid-label.component";
import {MicroMsisdnInputComponent} from "./shared/msisdn-input/micro-msisdn-input.component";
import {ValidatorService} from "./services/validator.service";
import {RegistrationsComponent} from "./registrations/registrations.component";
import {RegistrationDetailComponent} from "./registrations/registration-detail.component";
import {MicroCountryLookupComponent} from "./shared/lookup/micro-country-lookup.component";
import {ApplicationDetailComponent} from "./applications/application-detail.component";
import {ApplicationSelectComponent} from "./applications/application-select.component";
import {ApplicationsComponent} from "./applications/applications.component";
import {MicroApplicationLookupComponent} from "./shared/lookup/micro-application-lookup.component";
import {RegisterSuccessComponent} from "./auth/register-success.component";
import {EmailVerificationComponent} from "./auth/email-verification.component";
import {MsisdnVerificationComponent} from "./auth/msisdn-verification.component";
import {MicroPasswordChangeComponent} from "./shared/password-change/micro-password-change.component";
import {MicroChatAppLookupComponent} from "./shared/lookup/micro-chatApp-lookup.component";
import {ChatAppsComponent} from "./chatApps/chatApps.component";
import {ChatAppSelectComponent} from "./chatApps/chatApp-select.component";
import {ChatAppDetailComponent} from "./chatApps/chatApp-detail.component";
import { ChatSimComponent } from './chatSim/chatSim.component';
import {ColorPickerDialogComponent} from "./shared/colorPicker/color-picker-dialog.component";
import {ColorPickerComponent} from "./shared/colorPicker/color-picker.component";
import {ChatSessionsComponent} from "./chatSessions/chatSessions.component";
import {ChatSessionDetailComponent} from "./chatSessions/chatSession-detail.component";
import {ChatSessionLogsComponent} from "./chatSessionLogs/chatSessionLogs.component";
import {ChatSessionLogDetailComponent} from "./chatSessionLogs/chatSessionLog-detail.component";
import {ChatDashboardComponent} from "./chatDashboards/chat-dashboard.component";
import {ChatAppLogsComponent} from "./chatAppLogs/chatAppLogs.component";
import {ChatAppLogDetailComponent} from "./chatAppLogs/chatAppLog-detail.component";
import {MicroMaoLookupComponent} from "./shared/lookup/micro-mao-lookup.component";
import {RatingTypeSelectComponent} from "./shared/ratingType/ratingType-select.component";
import {AccountSharesComponent} from "./account/accountShares/accountShares.component";
import {RatingUnitSelectComponent} from "./pay/ratingUnit-select.component";
import {MetricsComponent} from "./system/metrics.component";
import {SmscBindsComponent} from "./smsc/smscBinds/smscBinds.component";
import {SmscBindSelectComponent} from "./smsc/smscBinds/smscBind-select.component";
import {SmscBindDetailComponent} from "./smsc/smscBinds/smscBind-detail.component";
import {MpsScopeSelectComponent} from "./smsc/mpsScope-select.component";
import {BindCountScopeSelectComponent} from "./smsc/bindCountScope-select.component";
import {MicroDetailDialogComponent} from "./shared/detailDialog/detail-dialog.component";
import {SmscClustersComponent} from "./smsc/smscClusters/smscClusters.component";
import {SmscClusterDetailComponent} from "./smsc/smscClusters/smscCluster-detail.component";
import {SmscClusterSelectComponent} from "./smsc/smscClusters/smscCluster-select.component";
import {SmscConnectionsComponent} from "./smsc/smscConnections/smscConnections.component";
import {SmscConnectionSelectComponent} from "./smsc/smscConnections/smscConnection-select.component";
import {SmscConnectionDetailComponent} from "./smsc/smscConnections/smscConnection-detail.component";
import {MicroSmscBindLookupComponent} from "./shared/lookup/micro-smscBind-lookup.component";
import {MicroSmscClusterLookupComponent} from "./shared/lookup/micro-smscCluster-lookup.component";
import {MicroSmscConnectionLookupComponent} from "./shared/lookup/micro-smscConnection-lookup.component";
import {SmscManagersComponent} from "./smsc/smscManagers/smscManagers.component";
import {SmscManagerDetailComponent} from "./smsc/smscManagers/smscManager-detail.component";
import {SmscManagerSelectComponent} from "./smsc/smscManagers/smscManager-select.component";
import {MicroSmscManagerLookupComponent} from "./shared/lookup/micro-smscManager-lookup.component";
import {SmppServersComponent} from "./smppserver/smppServers/smppServers.component";
import {SmppServerSelectComponent} from "./smppserver/smppServers/smppServer-select.component";
import {SmppServerDetailComponent} from "./smppserver/smppServers/smppServer-detail.component";
import {SmppServerPortSelectComponent} from "./smppserver/smppServerPorts/smppServerPort-select.component";
import {SmppServerPortDetailComponent} from "./smppserver/smppServerPorts/smppServerPort-detail.component";
import {SmppServerPortsComponent} from "./smppserver/smppServerPorts/smppServerPorts.component";
import {SmppServerClientsComponent} from "./smppserver/smppServerClients/smppServerClients.component";
import {SmppServerClientDetailComponent} from "./smppserver/smppServerClients/smppServerClient-detail.component";
import {SmppServerClientSelectComponent} from "./smppserver/smppServerClients/smppServerClient-select.component";
import {MicroSmppServerLookupComponent} from "./shared/lookup/micro-smppServer-lookup.component";
import {MicroSmppServerClientLookupComponent} from "./shared/lookup/micro-smppServerClient-lookup.component";
import {MicroSmppServerPortLookupComponent} from "./shared/lookup/micro-smppServerPort-lookup.component";
import {CredentialsComponent} from "./credentials/credentials.component";
import {CredentialSelectComponent} from "./credentials/credential-select.component";
import {CredentialDetailComponent} from "./credentials/credential-detail.component";
import {CredentialAuthTypeSelectComponent} from "./credentials/credentialAuthType-select.component";
import {UserAuthHelpComponent} from "./help/user-auth-help.component";
import {ServiceHelpComponent} from "./help/service-help.component";
import {MicroHelpDialogComponent} from "./help/help-dialog.component";
import {ApiExplorerComponent} from "./api/api-explorer.component";
import {ApiExplorerMethodComponent} from "./api/api-explorer-method.component";
import {ApiExplorerSchemaComponent} from "./api/api-explorer-schema.component";
import {ApiExplorerResponseComponent} from "./api/api-explorer-response.component";
import {CompanyStatusSelectComponent} from "./companies/companyStatus-select.component";
import {Http2SmsTesterComponent} from "./http2sms/http2sms-tester.component";
import {DrStatusSelectComponent} from "./so/dr-status-select.component";
import {SmsMoToEmailLogsComponent} from "./sms/smsMoToEmailLogs/smsMoToEmailLogs.component";
import {SmsMoToEmailLogDetailComponent} from "./sms/smsMoToEmailLogs/smsMoToEmailLog-detail.component";
import {MicroSmsMoToEmailLookupComponent} from "./shared/lookup/micro-smsMoToEmail-lookup.component";
import {SmsDetailComponent} from "./so/sms-detail.component";
import {SmsMoToEmailSelectComponent} from "./smsMoToEmails/smsMoToEmail-select.component";
import {SmsMoDetailComponent} from "./so/smsmo-detail.component";
import {File2SmsUploadComponent} from "./sms/fileToSms/file2Sms-upload.component";
import {File2SmsJobsComponent} from "./sms/fileToSms/file2smsJobs.component";
import {File2SmsJobStatusSelectComponent} from "./sms/fileToSms/file2smsJob-status-select.component";
import {File2SmsJobDetailComponent} from "./sms/fileToSms/file2smsJob-detail.component";
import {File2SmsConfigsComponent} from "./sms/fileToSms/file2smsConfigs.component";
import {File2SmsConfigDetailComponent} from "./sms/fileToSms/file2smsConfig-detail.component";
import {File2SmsJobLogsComponent} from "./sms/fileToSms/file2smsJobLogs.component";
import {SmsContentTypeSelectComponent} from "./so/sms-contentType-select.component";
import {ChatAppCountersComponent} from "./chatApps/chatApp-counters.component";
import {Mail2SmsPostmastersComponent} from "./mail2sms/mail2SmsPostmasters/mail2SmsPostmasters.component";
import {Mail2SmsPostmasterDetailComponent} from "./mail2sms/mail2SmsPostmasters/mail2SmsPostmaster-detail.component";
import {Mail2SmsPostmasterSelectComponent} from "./mail2sms/mail2SmsPostmasters/mail2SmsPostmaster-select.component";
import {MicroImapMailboxLookupComponent} from "./shared/lookup/micro-imap-mailbox-lookup.component";
import {MicroMail2SmsPostmasterLookupComponent} from "./shared/lookup/micro-mail2sms-postmaster-lookup.component";
import {Mail2SmsMailboxDetailComponent} from "./mail2sms/mail2SmsMailboxes/mail2SmsMailbox-detail.component";
import {Mail2SmsMailboxesComponent} from "./mail2sms/mail2SmsMailboxes/mail2SmsMailboxes.component";
import {Mail2SmsMailboxSelectComponent} from "./mail2sms/mail2SmsMailboxes/mail2SmsMailbox-select.component";
import {Mail2SmsUsersComponent} from "./mail2sms/mail2SmsUsers/mail2SmsUsers.component";
import {Mail2SmsUserDetailComponent} from "./mail2sms/mail2SmsUsers/mail2SmsUser-detail.component";
import {Mail2SmsUserSelectComponent} from "./mail2sms/mail2SmsUsers/mail2SmsUser-select.component";
import {MicroImapQueueingStrategySelectComponent} from "./imap/micro-imap-queueing-strategy-select.component";
import {Mail2SmsInStatusSelectComponent} from "./mail2sms/mail2SmsLog/mail2sms-in-status-select.component";
import {Mail2SmsLogIncomingComponent} from "./mail2sms/mail2SmsLog/mail2sms-log-incoming.component";
import { Mail2SmsConfigsComponent } from './mail2sms/mail2SmsConfigs/mail2SmsConfigs.component';
import { Mail2SmsConfigDetailComponent } from './mail2sms/mail2SmsConfigs/mail2SmsConfig-detail.component';
import { Mail2SmsConfigSelectComponent } from './mail2sms/mail2SmsConfigs/mail2SmsConfig-select.component';
import {AccountShareReportComponent} from "./account/accountShares/accountShareReport.component";
import {AccountShareReportMonthComponent} from "./account/accountShares/accountShareReportMonth.component";
import {Mail2SmsLogOutgoingComponent} from "./mail2sms/mail2SmsLog/mail2sms-log-outgoing.component";
import {Mail2SmsOutStatusSelectComponent} from "./mail2sms/mail2SmsLog/mail2sms-out-status-select.component";
import {EnvironmentsComponent} from "./domain/environments/environments.component";
import {EnvironmentDetailComponent} from "./domain/environments/environment-detail.component";
import {EnvironmentSelectComponent} from "./domain/environments/environment-select.component";
import {LocationsComponent} from "./domain/locations/locations.component";
import {LocationSelectComponent} from "./domain/locations/location-select.component";
import {LocationDetailComponent} from "./domain/locations/location-detail.component";
import {MicroEnvironmentLookupComponent} from "./shared/lookup/micro-environment-lookup.component";
import {MicroLocationLookupComponent} from "./shared/lookup/micro-location-lookup.component";
import {CmdbExplorerComponent} from "./cmdb/cmdb-explorer.component";
import { Db2SmsConfigsComponent } from './db2sms/db2SmsConfigs/db2SmsConfigs.component';
import {Db2SmsConfigDetailComponent} from "./db2sms/db2SmsConfigs/db2SmsConfig-detail.component";
import {Db2SmsConfigSelectComponent} from "./db2sms/db2SmsConfigs/db2SmsConfig-select.component";
import {MicroDb2SmsConfigLookupComponent} from "./shared/lookup/micro-db2SmsConfig-lookup.component";
import {Db2SmsInStatusSelectComponent} from "./db2sms/db2SmsLog/db2sms-in-status-select.component";
import {Db2SmsLogMtComponent} from "./db2sms/db2SmsLog/db2sms-log-mt.component";
import {Db2SmsOutStatusSelectComponent} from "./db2sms/db2SmsLog/db2sms-out-status-select.component";
import {Db2SmsLogMoComponent} from "./db2sms/db2SmsLog/db2sms-log-mo.component";
import {Db2SmsDefaultsComponent} from "./db2sms/db2SmsConfigs/db2SmsDefaults.component";
import {MicroMinDirective} from "./shared/min-max-validator/micro-min-validator.directive";
import {MicroMaxDirective} from "./shared/min-max-validator/micro-max-validator.directive";
import {SaLocksComponent} from "./sa/locks/saLocks.component";
import {SaLockSelectComponent} from "./sa/locks/saLock-select.component";
import {SaLockDetailComponent} from "./sa/locks/saLock-detail.component";
import {SaLockStatusSelectComponent} from "./sa/locks/saLock-status-select.component";
import {MicroSaLockLookupComponent} from "./shared/lookup/micro-saLock-lookup.component";
import {SaLockLogsComponent} from "./sa/locks/saLockLogs.component";
import {SaHostCompaniesComponent} from "./sa/companies/saHostCompanies.component";
import {SaHostCompanySelectComponent} from "./sa/companies/saHostCompany-select.component";
import {SaHostedCompaniesComponent} from "./sa/companies/saHostedCompanies.component";
import {SaCabinetsComponent} from "./sa/cabinets/saCabinets.component";
import {SaCabinetSelectComponent} from "./sa/cabinets/saCabinet-select.component";
import {SaCabinetDetailComponent} from "./sa/cabinets/saCabinet-detail.component";
import {MicroSaCabinetLookupComponent} from "./shared/lookup/micro-saCabinet-lookup.component";
import {SaLockTypeSelectComponent} from "./sa/locks/saLock-type-select.component";
import {CharacterSetSelectComponent} from "./smsc/characterSet-select.component";
import {SmsBlacklistComponent} from "./sms/smsBlacklist/smsBlacklist.component";
import {SmsBlacklistDetailComponent} from "./sms/smsBlacklist/smsBlacklist-detail.component";
import {SmsBlacklistSelectComponent} from "./sms/smsBlacklist/smsBlacklist-select.component";
import {SmsBlacklistTypeSelectComponent} from "./sms/smsBlacklist/smsBlacklistType-select.component";
import {SmsReportMsisdnsErrorComponent} from "./so/reports/sms-report-msisdns-error.component";
import {ReportRatingSelectComponent} from "./so/reports/report-rating-select.component";
import {SaCabinetLogsComponent} from "./sa/cabinets/saCabinetLogs.component";
import {SaLockActivityLogsComponent} from "./sa/locks/saLockActivityLogs.component";
import {WebClientConfigDetailComponent} from "./webClients/webClientConfig-detail.component";
import {WebClientConfigSelectComponent} from "./webClients/webClientConfig-select.component";
import {HttpSchemeSelectComponent} from "./webClients/httpScheme-select.component";
import {HttpAuthTypeSelectComponent} from "./webClients/httpAuthType-select.component";
import {WebClientConfigsComponent } from './webClients/webClientConfigs.component';
import {HttpMethodSelectComponent} from "./webClients/httpMethod-select.component";
import {UssdSubscriptionServersComponent} from "./ussdServers/ussdSubscriptionServers.component";
import {UssdSubscriptionServerDetailComponent} from "./ussdServers/ussdSubscriptionServer-detail.component";
import {Oauth2GrantTypeSelectComponent} from "./credentials/oauth2GrantType-select.component";
import {Oauth2AuthCredentialsMethodSelectComponent} from "./credentials/oauth2AuthCredentialsMethod-select.component";
import {MicroCredentialLookupComponent} from "./shared/lookup/micro-credential-lookup.component";
import {SmtpAuthTypeSelectComponent} from "./smtp/smtpAuthType-select.component";
import {ImapAuthTypeSelectComponent} from "./imap/imapAuthType-select.component";
import {SaContactAuditLogsComponent} from "./sa/contacts/saContactAuditLogs.component";
import {CompanyContactsComponent} from "./companies/contacts/companyContacts.component";
import {MicroUserFirstNameLastNameLookupComponent} from "./shared/lookup/micro-userFirstNameLastName-lookup.component";
import {CompanyContactDetailComponent} from "./companies/contacts/companyContact-detail.component";
import {CompanyContactRoleSelectComponent} from "./companies/contacts/companyContactRole-select.component";
import {MicroContactPhoneLookupComponent} from "./shared/lookup/micro-contactPhone-lookup.component";
import {MicroContactEmailLookupComponent} from "./shared/lookup/micro-contactEmail-lookup.component";
import {MicroSelectComboComponent} from "./shared/select/micro-select-combo.component";
import {MicroSelectOptionComponent} from "./shared/select/micro-select-option.component";
import {MicroWebClientConfigLookupComponent} from "./shared/lookup/micro-webClientConfig-lookup.component";
import {File2SmsConfigDefaultsComponent} from "./sms/fileToSms/file2smsConfig-defaults.component";
import {MicroAceEditorComponent} from "./shared/ace-editor/micro-ace-editor.component";
import {InlineDiffComponent} from "ngx-diff";
import {GlobalControlsComponent} from "./sys/global-controls.component";
import {GlobalOnOffControlComponent} from "./sys/global-on-off-control.component";
import {KafkaSecurityProtocolSelectComponent} from "./q/kafka/kafkaSecurityProtocol-select.component";
import {KafkaSaslMechanismSelectComponent} from "./q/kafka/kafkaSaslMechanism-select.component";
import {SaLockServoModelSelectComponent} from "./sa/locks/saLock-servoModel-select.component";
import {SshAuthTypeSelectComponent} from "./ssh/sshAuthType-select.component";
import {Mail2SmsMessagingChannelSelectComponent} from "./mail2sms/mail2SmsMessagingChannel-select.component";
// Import a single Froala Editor plugin.
// import 'froala-editor/js/plugins/align.min.js';
// import 'froala-editor/js/plugins/table.min.js';
// import 'froala-editor/js/plugins/image.min.js';

// Import a Froala Editor language file.
// import 'froala-editor/js/languages/de.js';

// // Import a third-party plugin.
// import 'froala-editor/js/third_party/font_awesome.min';
// import 'froala-editor/js/third_party/image_tui.min';
// import 'froala-editor/js/third_party/spell_checker.min';
// import 'froala-editor/js/third_party/embedly.min';

const DEFAULT_ACE_CONFIG: AceConfigInterface = {
};
@NgModule({
  declarations: [
    AppComponent,
    ApiExplorerComponent,
    ApiExplorerMethodComponent,
    ApiExplorerSchemaComponent,
    ApiExplorerResponseComponent,
    TestComponent,
    SideNavComponent,
    SideNavMenuComponent,
    MicroSelectComponent,
    MicroSelectComboComponent,
    MicroSelectOptionComponent,
    PermGridComponent,
    PermLogsComponent,
    PermTypeSelectComponent,
    MicroYesNoSelectComponent,
    MicroMonthSelectComponent,
    MicroYearMonthSelectComponent,
    MicroEnabledSelectComponent,
    CountrySelectComponent,
    MicroCaptchaComponent,
    MicroEmailLinkComponent,
    MicroTelLinkComponent,
    MicroValidLabelComponent,
    MicroMsisdnInputComponent,
    MicroImLinkComponent,
    MicroLookupComponent,
    MicroCompanyLookupComponent,
    MicroRatingLookupComponent,
    MicroDepartmentLookupComponent,
    MicroUserLookupComponent,
    MicroSaCarrierLookupComponent,
    MicroSaLockLookupComponent,
    MicroSaCabinetLookupComponent,
    MicroSiteLookupComponent,
    MicroApplicationLookupComponent,
    MicroUserMsisdnLookupComponent,
    MicroUserEmailLookupComponent,
    MicroUserFirstNameLastNameLookupComponent,
    MicroContactCompanyLookupComponent,
    MicroContactDepartmentLookupComponent,
    MicroContactLookupComponent,
    MicroUssdSvcCodeLookupComponent,
    MicroChatAppLookupComponent,
    MicroUssdCarrierLookupComponent,
    MicroChatCarrierLookupComponent,
    MicroUssdSessionLookupComponent,
    MicroAirtimeProductLookupComponent,
    MicroCountryLookupComponent,
    MicroNeLookupComponent,
    MicroNetLookupComponent,
    MicroEnvironmentLookupComponent,
    MicroLocationLookupComponent,
    MicroSshLookupComponent,
    MicroSqlLookupComponent,
    MicroTaskCarrierLookupComponent,
    MicroWebClientConfigLookupComponent,
    MicroWebhookLookupComponent,
    MicroCheckboxComponent,
    MicroObjectGroupLookupComponent,
    MicroRadioComponent,
    MicroChartComponent,
    DateRangeComponent,
    MicroEventTypeSelectComponent,
    MicroNotificationTypeSelectComponent,
    MicroServiceTypeSelectComponent,
    MicroEventDialogComponent,
    MicroDetailDialogComponent,
    MicroHelpDialogComponent,
    MicroEntitySearchDialogComponent,
    TransformEditorComponent,
    TreeSelectComponent,
    MicroVoiceCarrierLookupComponent,
    MicroAirtimeCarrierLookupComponent,
    DataExploreComponent,
    CmdServiceSelectComponent,
    VatTypeSelectComponent,
    RatingUnitSelectComponent,
    BlockAllowTypeSelectComponent,
    RatingTypeSelectComponent,
    LoginComponent,
    AuthorizeComponent,
    RegisterComponent,
    RegisterSuccessComponent,
    EmailVerificationComponent,
    MsisdnVerificationComponent,
    MicroPasswordChangeComponent,
    MeComponent,
    QueuesComponent,
    CacheComponent,
    MetricsComponent,
    ErrorDialogComponent,
    ColorPickerComponent,
    ColorPickerDialogComponent,
    DeleteConfirmButtonComponent,
    UpdateConfirmButtonComponent,
    SystemApplicationsComponent,
    ConfirmDialogComponent,
    ReasonDialogComponent,
    DatePickerComponent,
    ObjectGridComponent,
    MicroPicklistComponent,
    JpaFilterEditorComponent,
    JpaFilterOpEditorComponent,
    MicroCronComponent,
    MicroSeveritySelectComponent,
    MicroBackComponent,
    SwaggerComponent,
    MicroBreadcrumbComponent,
    UsersComponent,
    UserDetailComponent,
    UserSelectComponent,
    UserTreeSelectComponent,
    ClientsComponent,
    ClientDetailComponent,
    ClientSelectComponent,
    AlarmsComponent,
    AlarmDetailComponent,
    AlarmFilterDetailComponent,
    AlarmFilterSelectComponent,
    AlarmsDashboardComponent,
    AlarmOverviewDashboard,
    AlarmGridsComponent,
    AlarmGridDetailComponent,
    AlarmGridSelectComponent,
    WorkOrdersComponent,
    WorkOrderDetailComponent,
    IncidentsComponent,
    IncidentDetailComponent,
    SoSmsComponent,
    SmssComponent,
    SmsStateSelectComponent,
    SmsReportMsisdnsErrorComponent,
    ReportRatingSelectComponent,
    MapComponent,
    MapsComponent,
    MapDetailComponent,
    MapViewerComponent,
    CompaniesComponent,
    CompanyDetailComponent,
    CompanySelectComponent,
    CompanyStatusSelectComponent,
    CompanyContactsComponent,
    CompanyContactDetailComponent,
    CompanyContactRoleSelectComponent,
    DepartmentsComponent,
    DepartmentDetailComponent,
    DepartmentSelectComponent,
    RemedySiteSelectComponent,
    RemedyPeopleComponent,
    RemedyCompanySelectComponent,
    RemedyPersonComponent,
    RemedyPersonSelectComponent,
    ContactsComponent,
    ContactDetailComponent,
    ContactSelectComponent,
    ContactTreeSelectComponent,
    MicroContactPhoneLookupComponent,
    MicroContactEmailLookupComponent,
    ContactCompaniesComponent,
    ContactCompanyDetailComponent,
    ContactCompanySelectComponent,
    ContactDepartmentsComponent,
    ContactDepartmentDetailComponent,
    ContactDepartmentSelectComponent,
    ContactsRemedyImportComponent,
    SmsMessageLogComponent,
    SmssComponent,
    SmsDetailComponent,
    SmsDashboardComponent,
    SmsStateSelectComponent,
    SmsContentTypeSelectComponent,
    DrStatusSelectComponent,
    SmsDrsComponent,
    SmsMosComponent,
    SmsMoDetailComponent,
    MicroSmsCarrierLookupComponent,
    MicroSmscBindLookupComponent,
    MicroSmscClusterLookupComponent,
    MicroSmscConnectionLookupComponent,
    MicroSmscManagerLookupComponent,
    MicroSmppServerLookupComponent,
    MicroSmppServerPortLookupComponent,
    MicroSmppServerClientLookupComponent,
    MicroSmsTypeLookupComponent,
    MicroAnumberLookupComponent,
    MicroMnoLookupComponent,
    MicroMaoLookupComponent,
    MicroSmtpMailboxLookupComponent,
    MicroImapMailboxLookupComponent,
    MicroImapQueueingStrategySelectComponent,
    ANumbersComponent,
    ANumberDetailComponent,
    ANumberSelectComponent,
    AnumberTypeSelectComponent,
    SmsBlacklistComponent,
    SmsBlacklistDetailComponent,
    SmsBlacklistSelectComponent,
    SmsBlacklistTypeSelectComponent,
    SmsTypesComponent,
    SmsTypeDetailComponent,
    SmsTypeSelectComponent,
    SmsCarriersComponent,
    SmsCarrierDetailComponent,
    SmsCarrierSelectComponent,
    SmsCarrierTypeSelectComponent,
    SmsMoToEmailsComponent,
    SmsMoToEmailDetailComponent,
    SmsMoToEmailSelectComponent,
    MicroSmsMoToEmailLookupComponent,
    SmsMoToEmailLogsComponent,
    SmsMoToEmailLogDetailComponent,
    SmscBindsComponent,
    SmscBindDetailComponent,
    SmscBindSelectComponent,
    MpsScopeSelectComponent,
    CharacterSetSelectComponent,
    BindCountScopeSelectComponent,
    SmscClustersComponent,
    SmscClusterDetailComponent,
    SmscClusterSelectComponent,
    SmscConnectionsComponent,
    SmscConnectionDetailComponent,
    SmscConnectionSelectComponent,
    SmscManagersComponent,
    SmscManagerDetailComponent,
    SmscManagerSelectComponent,
    SmppServersComponent,
    SmppServerDetailComponent,
    SmppServerSelectComponent,
    SmppServerPortsComponent,
    SmppServerPortDetailComponent,
    SmppServerPortSelectComponent,
    SmppServerClientsComponent,
    SmppServerClientDetailComponent,
    SmppServerClientSelectComponent,
    UserAuthSelectComponent,
    UserAuthMultiSelectComponent,
    ClientAuthSelectComponent,
    ClientAuthMultiSelectComponent,
    ClientGrantSelectComponent,
    ClientGrantMultiSelectComponent,
    ServiceSelectComponent,
    ServiceMultiSelectComponent,
    InfobipDetailComponent,
    SmtpDetailComponent,
    SmtpsComponent,
    SmtpSelectComponent,
    SmtpAuthTypeSelectComponent,
    ImapDetailComponent,
    ImapsComponent,
    ImapSelectComponent,
    ImapAuthTypeSelectComponent,
    RemedyConfigDetailComponent,
    RemedyConfigsComponent,
    RemedyConfigSelectComponent,
    MailMessageLogComponent,
    MailMessageLogIncomingComponent,
    MailMessageLogOutgoingComponent,
    MailOverviewComponent,
    MailPreviewComponent,
    MailDashboardComponent,
    MailSendComponent,
    RichTextComponent,
    RichViewComponent,
    SshServerDetailComponent,
    SshServersComponent,
    SshServerSelectComponent,
    SshAuthTypeSelectComponent,
    SmCheckDetailComponent,
    SmChecksComponent,
    SmCheckDashboard,
    SmCheckSelectComponent,
    SmChecksLogComponent,
    SmLogDialogComponent,
    SmCheckDataComponent,
    SmChartComponent,
    SmDatagridSelectComponent,
    SmDatagridDetailComponent,
    SmGridsComponent,
    SmChartsComponent,
    SmChartSelectComponent,
    TransformSelectComponent,
    SqlServerDetailComponent,
    SqlServersComponent,
    SqlServerSelectComponent,
    SqlServerTypeSelectComponent,
    SqlServerGroupsComponent,
    SqlServerGroupDetailComponent,
    SqlServerGroupSelectComponent,
    NeDetailComponent,
    NesComponent,
    NeSelectComponent,
    NeTreeSelectComponent,
    NetDetailComponent,
    NetsComponent,
    NetSelectComponent,
    MocDetailComponent,
    MocsComponent,
    MocSelectComponent,
    SpecProbDetailComponent,
    SpecProbsComponent,
    SpecProbSelectComponent,
    DiagramEditorComponent,
    DiagramViewerComponent,
    DashboardsComponent,
    DashboardDetailComponent,
    DashboardViewerComponent,
    SitesComponent,
    SiteDetailComponent,
    SiteSelectComponent,
    RegionsComponent,
    RegionDetailComponent,
    RegionSelectComponent,
    MicroRegionLookupComponent,
    RegionAuditLogsComponent,
    ReactorsComponent,
    ReactorDetailComponent,
    ReactorLogsComponent,
    ReactorLogDetailComponent,
    ReactorSelectComponent,
    MicroReactorLookupComponent,
    SubscriptionsComponent,
    SubscriptionDetailComponent,
    SubscriptionSelectComponent,
    MicroSubscriptionLookupComponent,
    NpRangeDetailComponent,
    NpRangesComponent,
    TbbCarriersComponent,
    TbbCarrierDetailComponent,
    TbbCarrierSelectComponent,
    TbbCarrierTypeSelectComponent,
    TbbMtnLogsComponent,
    TbbOmniLogsComponent,
    TbbLogsComponent,
    TbbTokensComponent,
    TbbOmniSubscriptionsComponent,
    TbbOmniBillingsComponent,
    CentiliPaymentResultsComponent,
    ServicesComponent,
    ServiceDetailComponent,
    SvcSelectComponent,
    MaosComponent,
    MaoDetailComponent,
    NotificationLogsComponent,
    AuditsComponent,
    SaRequestComponent,
    SaContactsComponent,
    SaRegionsComponent,
    SaCarriersComponent,
    SaCarrierDetailComponent,
    SaCarrierSelectComponent,
    SaCarrierTypeSelectComponent,
    SaTokenStatusSelectComponent,
    SaTokenSourceTypeSelectComponent,
    SaTokenRequestLogsComponent,
    SaTokenRequestLogDetailComponent,
    SaTrackersComponent,
    SaContactAuditLogsComponent,
    UssdCarriersComponent,
    UssdCarrierDetailComponent,
    UssdCarrierSelectComponent,
    UssdSvcCodesComponent,
    UssdSvcCodeDetailComponent,
    UssdSvcCodeSelectComponent,
    UssdSessionLogsComponent,
    UssdSessionLogDetailComponent,
    UssdSessionsComponent,
    UssdSessionDetailComponent,
    UssdCarrierSelectComponent,
    UssdCarrierTypeSelectComponent,
    UssdDashboardComponent,
    UssdSimComponent,
    UssdSubscriptionServersComponent,
    UssdSubscriptionServerDetailComponent,
    VoiceRequestComponent,
    AirtimeCarriersComponent,
    AirtimeCarrierDetailComponent,
    AirtimeCarrierSelectComponent,
    AirtimeCarrierTypeSelectComponent,
    AirtimeProductsComponent,
    AirtimeProductDetailComponent,
    AirtimeProductSelectComponent,
    AirtimeProductTypeSelectComponent,
    AirtimeStatusSelectComponent,
    NotifComponent,
    HealthDetailComponent,
    HealthReportComponent,
    GlobalControlsComponent,
    GlobalOnOffControlComponent,
    VoiceCarriersComponent,
    VoiceCarrierDetailComponent,
    VoiceCarrierSelectComponent,
    VoiceCarrierTypeSelectComponent,
    VoiceDashboardComponent,
    TaskCarriersComponent,
    TaskCarrierDetailComponent,
    TaskCarrierSelectComponent,
    TaskCarrierTypeSelectComponent,
    TaskTicketsComponent,
    TaskTicketDetailComponent,
    TaskTicketTypeSelectComponent,
    TaskProductSelectComponent,
    TaskProjectSelectComponent,
    TaskPhaseSelectComponent,
    TaskResourceSelectComponent,
    TasksComponent,
    TaskDetailComponent,
    TaskStatusSelectComponent,
    TaskSprintSelectComponent,
    TaskGetResourceComponent,
    TaskSetResourceComponent,
    TaskSetStatusComponent,
    TaskAddFeedbackComponent,
    TaskGetSprintsComponent,
    TaskSetSprintComponent,
    TaskFindTaskComponent,
    TaskGetResourceByMsisdnComponent,
    TaskGetTasksByResourceComponent,
    TaskLogsComponent,
    TaskLogDetailComponent,
    ItsmCarriersComponent,
    ItsmCarrierDetailComponent,
    ItsmCarrierSelectComponent,
    ItsmCarrierTypeSelectComponent,
    MnosComponent,
    MnoDetailComponent,
    MnoSelectComponent,
    AirtimeRechargesComponent,
    AirtimeRechargeDetailComponent,
    AirtimeRechargeRequestDetailComponent,
    AirtimeDashboardComponent,
    AirtimePurchaseComponent,
    AirtimeProductFiltersComponent,
    AirtimeProductFilterDetailComponent,
    AirtimeProductFilterSelectComponent,
    EscalationsComponent,
    EscalationDetailComponent,
    LinkedAccountsComponent,
    LinkedAccountTypeSelectComponent,
    AiBtTaskDefSelectComponent,
    AiBtDetailComponent,
    AiBtEditorComponent,
    AiBtsComponent,
    AiBtSelectComponent,
    AiBtParamsEditorComponent,
    AiBtObjectMappingsComponent,
    AiBtMapComponent,
    AiBtEnumComponent,
    MicroAiBtLookupComponent,
    AiBtLogsComponent,
    AiBtLogDetailComponent,
    FormDefsComponent,
    FormDefDetailComponent,
    FormDefSelectComponent,
    FormFieldDefTypeSelectComponent,
    FormDefEditorComponent,
    FormFieldEditorComponent,
    FormDefListEditorComponent,
    FormEditorComponent,
    VoiceLogsComponent,
    VoiceLogDetailComponent,
    UsageDashboardComponent,
    DoChartComponent,
    WhatsappDashboardComponent,
    WhatsappSendComponent,
    WhatsappIncomingMsgLogsComponent,
    WhatsappIncomingMsgLogDetailComponent,
    WhatsappOutgoingMsgLogsComponent,
    WhatsappOutgoingMsgLogDetailComponent,
    MicroWhatsappMessageTypeSelectComponent,
    ChatCarriersComponent,
    ChatCarrierTypeSelectComponent,
    ChatCarrierDetailComponent,
    ChatCarrierSelectComponent,
    MicroChatSenderLookupComponent,
    ChatSendersComponent,
    ChatSenderDetailComponent,
    ChatSenderSelectComponent,
    ChatAppsComponent,
    ChatAppDetailComponent,
    ChatAppSelectComponent,
    ChatAppLogsComponent,
    ChatAppLogDetailComponent,
    ChatAppCountersComponent,
    ChatSimComponent,
    ChatSessionsComponent,
    ChatSessionDetailComponent,
    ChatSessionLogsComponent,
    ChatSessionLogDetailComponent,
    ChatDashboardComponent,
    WebhooksComponent,
    WebhookDetailComponent,
    WebhookSelectComponent,
    WebhookLogsComponent,
    WebhookLogDetailComponent,
    SurlCarriersComponent,
    SurlCarrierDetailComponent,
    SurlCarrierSelectComponent,
    SurlCarrierTypeSelectComponent,
    SurlsComponent,
    SurlDetailComponent,
    MicroSurlCarrierLookupComponent,
    QueueCarriersComponent,
    QueueCarrierDetailComponent,
    QueueCarrierSelectComponent,
    QueueCarrierTypeSelectComponent,
    MicroQueueCarrierLookupComponent,
    QueueDashboardComponent,
    QueuePollComponent,
    QueueInMessagesComponent,
    QueueInMessageDetailComponent,
    QueueOutMessagesComponent,
    QueueOutMessageDetailComponent,
    QueuePollRequestsComponent,
    QueuePollRequestDetailComponent,
    QueueConfigsComponent,
    QueueConfigDetailComponent,
    QueueConfigSelectComponent,
    KafkaOverviewComponent,
    KafkaSecurityProtocolSelectComponent,
    KafkaSaslMechanismSelectComponent,
    ObjectGroupsComponent,
    ObjectGroupDetailComponent,
    ObjectGroupSelectComponent,
    ObjectGroupPicklistComponent,
    CmdObjectSelectComponent,
    CmdObjectPicklistComponent,
    CmdEntityTypeSelectComponent,
    CmdServiceLookupComponent,
    PaymentCarriersComponent,
    PaymentCarrierDetailComponent,
    PaymentCarrierSelectComponent,
    PaymentCarrierTypeSelectComponent,
    MicroPaymentCarrierLookupComponent,
    PaymentTransactionStatusSelectComponent,
    BuyComponent,
    BuySuccessComponent,
    BuyCancelledComponent,
    GlobalConfigComponent,
    PaymentTransactionsComponent,
    PaymentTransactionDetailComponent,
    ItsmFindRequestComponent,
    ItsmCreateRequestComponent,
    ItsmRequestDetailComponent,
    ItsmObjectTypeSelectComponent,
    ItsmObjectsComponent,
    ItsmObjectDetailComponent,
    ItsmObjectSelectComponent,
    ItsmObjectPicklistComponent,
    MicroItsmCarrierLookupComponent,
    MicroItsmObjectLookupComponent,
    ItsmLogsComponent,
    ItsmLogDetailComponent,
    ItsmFindChangeComponent,
    ItsmCreateChangeComponent,
    ItsmChangeDetailComponent,
    ItsmFindAssetComponent,
    ItsmCreateAssetComponent,
    ItsmAssetDetailComponent,
    ItsmFindProductTypeComponent,
    ItsmCreateProductTypeComponent,
    AccountTransactionsComponent,
    AccountTransactionDetailComponent,
    AccountTransactionStatusSelectComponent,
    AccountTransactionTypeSelectComponent,
    AccountTransactComponent,
    AccountReportComponent,
    AccountReportMonthComponent,
    AuditReportComponent,
    AuditReportMonthComponent,
    AccountSharesComponent,
    AccountShareReportComponent,
    AccountShareReportMonthComponent,
    DiscountsComponent,
    DiscountDetailComponent,
    RatingsComponent,
    RatingDetailComponent,
    RatingSelectComponent,
    RegistrationsComponent,
    RegistrationDetailComponent,
    ApplicationsComponent,
    ApplicationDetailComponent,
    ApplicationSelectComponent,
    CredentialsComponent,
    CredentialDetailComponent,
    CredentialSelectComponent,
    CredentialAuthTypeSelectComponent,
    MicroCredentialLookupComponent,
    Oauth2GrantTypeSelectComponent,
    Oauth2AuthCredentialsMethodSelectComponent,
    UserAuthHelpComponent,
    ServiceHelpComponent,
    Http2SmsTesterComponent,
    File2SmsUploadComponent,
    File2SmsJobsComponent,
    File2SmsJobDetailComponent,
    File2SmsJobLogsComponent,
    File2SmsJobStatusSelectComponent,
    File2SmsConfigsComponent,
    File2SmsConfigDetailComponent,
    File2SmsConfigDefaultsComponent,
    Mail2SmsPostmastersComponent,
    Mail2SmsPostmasterDetailComponent,
    Mail2SmsPostmasterSelectComponent,
    MicroMail2SmsPostmasterLookupComponent,
    Mail2SmsMailboxDetailComponent,
    Mail2SmsMailboxesComponent,
    Mail2SmsMailboxSelectComponent,
    Mail2SmsUsersComponent,
    Mail2SmsUserDetailComponent,
    Mail2SmsUserSelectComponent,
    Mail2SmsLogIncomingComponent,
    Mail2SmsInStatusSelectComponent,
    Mail2SmsLogOutgoingComponent,
    Mail2SmsOutStatusSelectComponent,
    Mail2SmsConfigsComponent,
    Mail2SmsConfigDetailComponent,
    Mail2SmsConfigSelectComponent,
    Mail2SmsMessagingChannelSelectComponent,
    Db2SmsDefaultsComponent,
    Db2SmsConfigsComponent,
    Db2SmsConfigDetailComponent,
    Db2SmsConfigSelectComponent,
    MicroDb2SmsConfigLookupComponent,
    Db2SmsInStatusSelectComponent,
    Db2SmsOutStatusSelectComponent,
    Db2SmsLogMtComponent,
    Db2SmsLogMoComponent,
    EnvironmentsComponent,
    EnvironmentDetailComponent,
    EnvironmentSelectComponent,
    LocationsComponent,
    LocationDetailComponent,
    LocationSelectComponent,
    CmdbExplorerComponent,
    MicroMinDirective,
    MicroMaxDirective,
    SaLocksComponent,
    SaLockDetailComponent,
    SaLockSelectComponent,
    SaLockStatusSelectComponent,
    SaLockTypeSelectComponent,
    SaLockLogsComponent,
    SaLockActivityLogsComponent,
    SaLockServoModelSelectComponent,
    SaCabinetLogsComponent,
    SaHostCompaniesComponent,
    SaHostedCompaniesComponent,
    SaHostCompanySelectComponent,
    SaCabinetsComponent,
    SaCabinetDetailComponent,
    SaCabinetSelectComponent,
    WebClientConfigsComponent,
    WebClientConfigDetailComponent,
    WebClientConfigSelectComponent,
    HttpSchemeSelectComponent,
    HttpAuthTypeSelectComponent,
    HttpMethodSelectComponent,
    MicroAceEditorComponent,
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        MicroCommonModules,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        AceModule,
        NgxJsonViewerModule,
        // NgxDiffModule,
        ChartModule,
        // BotDetectCaptchaModule,
        ColorChromeModule,
        NgSelectModule,
        InlineDiffComponent,
    ],
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingInterceptor,
        multi: true
      },
      {
        provide: HIGHCHARTS_MODULES,
        useFactory: () => [highstock, highmap, sankey, dependencywheel, accessibility]
      },
      {
        provide: LOCALE_ID,
        useValue: 'en-ZA'
      },
      {
        provide: DEFAULT_CURRENCY_CODE,
        useValue: 'ZAR'
      },
      {
        provide: ACE_CONFIG,
        useValue: DEFAULT_ACE_CONFIG
      },
      Title, EnvService, AuthService, AuthGuard, AlertService, DialogService, LookupService, ColorService, NavService, LoadingService, NotifService, SysService, CacheService, ThemeService, ValidatorService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
