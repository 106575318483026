<mat-toolbar style="padding: 16px 16px">
  <mat-icon>link</mat-icon> Linked Accounts
<!--  <div class="hbox-nowrap lightly-padded">-->
<!--    <button mat-raised-button class="primary" [disabled]="!userId || userId.trim() == ''" (click)="syncLinkedAccount()">-->
<!--      <mat-icon>add</mat-icon> Link/Sync-->
<!--    </button>-->
<!--  </div>-->
</mat-toolbar>

<table class="basicTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Type
          <micro-linkedAccountType-select [(linkedAccountTypeId)]="filter.type"></micro-linkedAccountType-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Carrier
          <micro-taskCarrier-select [(taskCarrierId)]="filter.carrierId" [disabled]="filter.type !== 'TASK'"></micro-taskCarrier-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Linked.ID
<!--          <input [(ngModel)]="filter.linkedId">-->
        </div>
      </th>
      <th>
        <div class="vbox">
          Username
<!--          <input [(ngModel)]="filter.username">-->
        </div>
      </th>
      <th>
        <div class="vbox">
          Name
<!--          <input [(ngModel)]="filter.name">-->
        </div>
      </th>
      <th>
        <div class="vbox">
          E-Mail
<!--          <input [(ngModel)]="filter.email">-->
        </div>
      </th>
      <th>
        <div class="vbox">
          MSISDN
<!--          <input [(ngModel)]="filter.msisdn">-->
        </div>
      </th>
      <th>
        <div class="vbox">
          Department
<!--          <input [(ngModel)]="filter.department">-->
        </div>
      </th>
      <th>
        <div class="lightly-padded">
          <button mat-raised-button class="primary" [disabled]="!userId || userId.trim() === '' || filter.type !== 'TASK' || !filter.carrierId" (click)="syncLinkedAccount()">
            <mat-icon>add_link</mat-icon> Link/Sync
          </button>

        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td>{{element.type}}</td>
      <td>
        <micro-taskCarrier-lookup *ngIf="element.type === 'TASK'" [taskCarrierId]="element.carrierId"></micro-taskCarrier-lookup>
      </td>
      <td>{{element.linkedId}}</td>
      <td>{{element.username}}</td>
      <td>{{element.name}}</td>
      <td>{{element.email}}</td>
      <td>{{element.msisdn}}</td>
      <td>{{element.department}}</td>
      <td>
        <div class="lightly-padded">
          <button mat-raised-button class="primary" [disabled]="element.type !== 'TASK'" (click)="unLinkedAccount(element.id)">
            <mat-icon>link_off</mat-icon> Unlink
          </button>

        </div>

      </td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No linked accounts found</em></td>
    </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="100">
      <mat-paginator #paginator
                     (page)="reload()"
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 20]">
      </mat-paginator>
    </td>
  </tr>
  </tfoot>
</table>

