<div *ngIf="mail2SmsConfig">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>settings</mat-icon> {{!mail2SmsConfig.id ? 'Create new Email2Msg Company Config' : ('Email2Msg Company Config ' + mail2SmsConfig.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!mail2SmsConfig.id ? 'add' : 'save'}}</mat-icon>
        {{(mail2SmsConfig.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="mail2SmsConfig.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100" style="min-width: 470px;">Company Defaults and Limits</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>
              <micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label>
            </th>
            <td>
              <micro-company-select #companyId="ngModel" [(ngModel)]="mail2SmsConfig.companyId" [(companyId)]="mail2SmsConfig.companyId" [required]="true" [disabled]="!!mail2SmsConfig.id" id="companyId" name="companyId"></micro-company-select>
            </td>
          </tr>
          <tr>
            <th>Postmaster</th>
            <td>
              <micro-mail2SmsPostmaster-select [(mail2SmsPostmasterId)]="mail2SmsConfig.postmasterId" mode="select" [allLabel]="'System Postmaster'"></micro-mail2SmsPostmaster-select>
            </td>
          </tr>
          <tr>
            <th colspan="99"><div class="text-center lightly-padded">SMS Channel Defaults</div></th>
          </tr>
          <tr>
            <th>SMS Receiving domain</th>
            <td>
              <input #smsEmailDomain="ngModel" [(ngModel)]="mail2SmsConfig.smsEmailDomain" placeholder="{{defaultConfig.smsEmailDomain}} (default)" id="smsEmailDomain" name="smsEmailDomain">
            </td>
          </tr>
          <tr>
            <th>Max SMS Recipients / email</th>
            <td>
              <input #smsMaxRecipients="ngModel" [(ngModel)]="mail2SmsConfig.smsMaxRecipients" placeholder="{{defaultConfig.smsMaxRecipients}} (default)" id="smsMaxRecipients" name="smsMaxRecipients">
            </td>
          </tr>
          <tr>
            <th class="align-top">Max SMS Parts</th>
            <td>
              <input #smsMaxSmsParts="ngModel" [(ngModel)]="mail2SmsConfig.smsMaxSmsParts" placeholder="{{defaultConfig.smsMaxSmsParts}} (default)" id="smsMaxSmsParts" name="smsMaxSmsParts"><br>
            </td>
          </tr>
          <tr>
            <th colspan="99"><div class="text-center lightly-padded">WhatsApp Channel Defaults</div></th>
          </tr>
            <tr>
              <th><micro-valid-label for="waMsisdn" [model]="waMsisdn" label="WhatsApp MSISDN"></micro-valid-label></th>
              <td>
                <input #waMsisdn="ngModel" [(ngModel)]="mail2SmsConfig.waMsisdn" placeholder="Default MSISDN used for WhatsApp messages" id="waMsisdn" name="waMsisdn">
              </td>
            </tr>
          <tr>
            <th><micro-valid-label for="waEmailDomain" [model]="waEmailDomain" label="WhatsApp Receiving Domain"></micro-valid-label></th>
            <td>
              <input #waEmailDomain="ngModel" [(ngModel)]="mail2SmsConfig.waEmailDomain" placeholder="{{defaultConfig.waEmailDomain}} (default)" id="waEmailDomain" name="waEmailDomain">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="waMaxRecipients" [model]="waMaxRecipients" label="Max WhatsApp Recipients / Email"></micro-valid-label></th>
            <td>
              <input #waMaxRecipients="ngModel" [(ngModel)]="defaultConfig.waMaxRecipients" placeholder="{{defaultConfig.waMaxRecipients}} (default)" id="waMaxRecipients" name="waMaxRecipients" [microMin]="1">
            </td>
          </tr>
          <tr>
            <th colspan="99"><div class="text-center lightly-padded">Email Defaults</div></th>
          </tr>
          <tr>
            <th><micro-valid-label for="defaultMoToEmail" [model]="defaultMoToEmail" label="MO Default Email Address"></micro-valid-label></th>
            <td>
              <input #defaultMoToEmail="ngModel" type="email" [(ngModel)]="mail2SmsConfig.defaultMoToEmail" placeholder="Email address" id="defaultMoToEmail" name="defaultMoToEmail">
            </td>
          </tr>

          <tr>
            <th class="align-top">Send MO to default email</th>
            <td>
              <micro-yesNo-select [disabled]="!isValidEmail(mail2SmsConfig.defaultMoToEmail)" [(yesNo)]="mail2SmsConfig.defaultMoToEmailOnlyNoRecipient" [yesOption]="'Only when no recipients'" [noOption]="'Always'" [allLabel]="defaultConfig.defaultMoToEmailOnlyNoRecipient ? 'Only when no recipients (default)' : 'Always (default)'"></micro-yesNo-select>
            </td>
          </tr>
          <tr>
            <th colspan="100" class="text-center">Auto Match MO Messages to Last Email</th>
          </tr>
          <tr>
            <td colspan="99" class="text-left">
              <div class="text-left lightly-padded">
                If enabled, this will search the incoming email history:
                <ul style="margin-block: 0.25em;">
                  <li>for the past specified hours (period)</li>
                  <li>for max specified unique email addresses</li>
                </ul>
                that last sent a SMS message to the MO mobile.<br>
                All matched email addresses will receive the MO message via email.
              </div>
            </td>
          </tr>
          <tr>
            <th>Default Mode</th>
            <td>
              <micro-yesNo-select [(yesNo)]="mail2SmsConfig.autoMoToLastEmail" [yesOption]="'Enabled'" [noOption]="'Disabled'" [allLabel]="defaultConfig.autoMoToLastEmail ? 'Enabled (default)' : 'Disabled (default)'"></micro-yesNo-select>
            </td>
          </tr>
          <tr>
            <th>Match back period (hours)</th>
            <td>
              <input #autoMoToLastEmailHours="ngModel" [(ngModel)]="mail2SmsConfig.autoMoToLastEmailHours" placeholder="{{defaultConfig.autoMoToLastEmailHours}} (default)" id="autoMoToLastEmailHours" name="autoMoToLastEmailHours">
            </td>
          </tr>
          <tr>
            <th>Maximum email addresses</th>
            <td>
              <input #autoMoToMaxLastEmails="ngModel" [(ngModel)]="mail2SmsConfig.autoMoToMaxLastEmails" placeholder="{{defaultConfig.autoMoToMaxLastEmails}} (default)" name="autoMoToMaxLastEmails">
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
