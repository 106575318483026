<div class="stick-top">
  <mat-toolbar>
    <mat-icon>settings</mat-icon> Email2Msg Defaults and Limits
  </mat-toolbar>
</div>

<div class="padded vbox-space">
  <div *ngIf="!defaultConfig" class="hbox">
    <i>Loading System Defaults and Limits...</i>
  </div>
  <div *ngIf="defaultConfig">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">Email2Msg System Defaults</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="isRoot">
            <th colspan="100" class="text-center">Worker Threads</th>
          </tr>
          <tr *ngIf="isRoot">
            <th><micro-valid-label for="mailWorkerThreads" [model]="mailWorkerThreads" label="Incoming worker threads"></micro-valid-label></th>
            <td>
              <input #mailWorkerThreads="ngModel" [(ngModel)]="defaultConfig.mailWorkerThreads" required placeholder="5" id="mailWorkerThreads" name="mailWorkerThreads" [microMin]="1">
            </td>
          </tr>
          <tr *ngIf="isRoot">
            <th><micro-valid-label for="moWorkerThreads" [model]="moWorkerThreads" label="Outgoing worker threads"></micro-valid-label></th>
            <td>
              <input #moWorkerThreads="ngModel" [(ngModel)]="defaultConfig.moWorkerThreads" required placeholder="5" id="moWorkerThreads" name="moWorkerThreads" [microMin]="1">
            </td>
          </tr>
          <tr *ngIf="isRoot">
            <th><micro-valid-label for="postmasterWorkerThreads" [model]="postmasterWorkerThreads" label="Postmaster worker threads"></micro-valid-label></th>
            <td>
              <input #postmasterWorkerThreads="ngModel" [(ngModel)]="defaultConfig.postmasterWorkerThreads" required placeholder="5" id="postmasterWorkerThreads" name="postmasterWorkerThreads" [microMin]="1">
            </td>
          </tr>
          <tr>
            <th colspan="100" class="text-center">System Defaults and Limits</th>
          </tr>
          <tr>
            <th colspan="99"><div class="text-left lightly-padded">The System Defaults and Limits will be used for a company, if not configured or specified for a specific company</div></th>
          </tr>
          <tr>
            <th [ngClass]="{'text-error':!defaultConfig.postmasterId}">Postmaster <span *ngIf="!defaultConfig.postmasterId">*</span></th>
            <td>
              <micro-mail2SmsPostmaster-select *ngIf="editDefault" [(mail2SmsPostmasterId)]="defaultConfig.postmasterId" [required]="true" mode="select"></micro-mail2SmsPostmaster-select>
              <micro-mail2sms-postmaster-lookup *ngIf="!editDefault" [postmasterId]="defaultConfig.postmasterId" [link]="true"></micro-mail2sms-postmaster-lookup>
            </td>
          </tr>
          <tr>
            <th colspan="99"><div class="text-center lightly-padded">SMS Channel Defaults</div></th>
          </tr>
          <tr>
            <th><micro-valid-label for="smsEmailDomain" [model]="smsEmailDomain" label="SMS Receiving Domain"></micro-valid-label></th>
            <td>
              <input #smsEmailDomain="ngModel" [(ngModel)]="defaultConfig.smsEmailDomain" [disabled]="!editDefault" required placeholder="Domain used to receive SMS emails (msg.taulite.co.za)" id="smsEmailDomain" name="smsEmailDomain">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="smsMaxRecipients" [model]="smsMaxRecipients" label="Max SMS Recipients / Email"></micro-valid-label></th>
            <td>
              <input #smsMaxRecipients="ngModel" [(ngModel)]="defaultConfig.smsMaxRecipients" [disabled]="!editDefault" required placeholder="Max SMS recipients per Email" id="smsMaxRecipients" name="smsMaxRecipients" [microMin]="1">
            </td>
          </tr>
          <tr>
            <th class="align-top"><micro-valid-label for="smsMaxSmsParts" [model]="smsMaxSmsParts" label="Max SMS Parts"></micro-valid-label>
            </th>
            <td>
              <input #smsMaxSmsParts="ngModel" [(ngModel)]="defaultConfig.smsMaxSmsParts" required [disabled]="!editDefault" placeholder="Max SMS Parts" id="smsMaxSmsParts" name="smsMaxSmsParts" [microMin]="1" [microMax]="255"><br>
              <div class="lightly-padded text-left">
                This limits the maximum SMS messages (parts) that can<br>
                be used to send email message to a mobile recipient.<br>
                The number of SMS parts is determined by the type of characters.<br>
                Unicode ±70 characters, ASCII ±160 characters per SMS part.<br>
              </div>
            </td>
          </tr>
          <tr>
            <th colspan="99"><div class="text-center lightly-padded">WhatsApp Channel Defaults</div></th>
          </tr>
          <tr>
            <th><micro-valid-label for="waEmailDomain" [model]="waEmailDomain" label="WhatsApp Receiving Domain"></micro-valid-label></th>
            <td>
              <input #waEmailDomain="ngModel" [(ngModel)]="defaultConfig.waEmailDomain" [disabled]="!editDefault" required placeholder="Domain used to receive WhatsApp emails (wa.taulite.co.za)" id="waEmailDomain" name="waEmailDomain">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="waMaxRecipients" [model]="waMaxRecipients" label="Max WhatsApp Recipients / Email"></micro-valid-label></th>
            <td>
              <input #waMaxRecipients="ngModel" [(ngModel)]="defaultConfig.waMaxRecipients" [disabled]="!editDefault" required placeholder="Max WhatsApp recipients per Email" id="waMaxRecipients" name="waMaxRecipients" [microMin]="1">
            </td>
          </tr>
          <tr>
            <th colspan="99"><div class="text-center lightly-padded">Email Defaults</div></th>
          </tr>
          <tr>
            <th class="align-top"><micro-valid-label for="defaultMoToEmailOnlyNoRecipient" [model]="defaultConfig.defaultMoToEmailOnlyNoRecipient" label="Send MO to default email"></micro-valid-label></th>
            <td>
              <micro-yesNo-select [(yesNo)]="defaultConfig.defaultMoToEmailOnlyNoRecipient" [yesOption]="'Only when no recipients'" [noOption]="'Always'" [required]="true" [disabled]="!editDefault"></micro-yesNo-select>
              <div class="text-left lightly-padded">
                The default email address can be specified per company default config.
              </div>
            </td>
          </tr>
          <tr>
            <th colspan="100" class="text-center">Auto Match MO Messages to Last Email</th>
          </tr>
          <tr>
            <td colspan="99" class="text-left">
              <div class="text-left lightly-padded">
                If enabled, this will search the incoming email history:
                <ul style="margin-block: 0.25em;">
                  <li>for the past specified hours (period)</li>
                  <li>for max specified unique email addresses</li>
                </ul>
                that last sent a SMS message to the MO mobile.<br>
                All matched email addresses will receive the MO message via email.
              </div>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="autoMoToLastEmail" [model]="defaultConfig.autoMoToLastEmail" label="Default Mode"></micro-valid-label></th>
            <td>
              <micro-yesNo-select [(yesNo)]="defaultConfig.autoMoToLastEmail" [yesOption]="'Enabled'" [noOption]="'Disabled'" [required]="true" [disabled]="!editDefault"></micro-yesNo-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="autoMoToLastEmailHours" [model]="autoMoToLastEmailHours" label="Match back period (hours)"></micro-valid-label></th>
            <td>
              <input #autoMoToLastEmailHours="ngModel" [(ngModel)]="defaultConfig.autoMoToLastEmailHours" required [disabled]="!editDefault" placeholder="0 = anytime" id="autoMoToLastEmailHours" name="autoMoToLastEmailHours" [microMin]="0">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="autoMoToMaxLastEmails" [model]="autoMoToMaxLastEmails" label="Maximum email addresses"></micro-valid-label></th>
            <td>
              <input #autoMoToMaxLastEmails="ngModel" [(ngModel)]="defaultConfig.autoMoToMaxLastEmails" required [disabled]="!editDefault" placeholder="1 = last email only" id="autoMoToMaxLastEmails" name="autoMoToMaxLastEmails" [microMin]="1">
            </td>
          </tr>
          <tr *ngIf="editDefault">
            <th colspan="99" style="padding: 0">
              <div class="hbox lightly-padded button-bar">
                <button mat-raised-button class="primary" type="button" (click)="updateDefaultConfig()" [disabled]="!form.form.valid || !defaultConfig.postmasterId">
                  <mat-icon>save</mat-icon> Update System Defaults
                </button>
              </div>
            </th>
          </tr>
      </tbody>
      </table>
    </form>
  </div>
</div>
<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>
        <th colspan="99" style="padding: 0">
          <div class="hbox lightly-padded button-bar">
            <button mat-raised-button class="primary" routerLink="/mail2msg/configs/0">
              <mat-icon>add</mat-icon>
              Create a new Company Default Config
            </button>
          </div>
        </th>

      </tr>
      <tr>
        <th colspan="100">Company Defaults and Limits</th>
      </tr>
      <tr>
        <th colspan="3"></th>
        <th colspan="3">SMS Channel Defaults</th>
        <th colspan="3">WhatsApp Channel Defaults</th>
        <th colspan="2">Send MO to Default Email</th>
        <th colspan="3">Auto Match MO Messages to Last Email</th>
      </tr>
      <tr>
        <th>
          <div class="vbox">
            Company Config
            <input [(ngModel)]="filter.companyAbbr">
          </div>
        </th>
        <th>
          <div class="vbox">
            Company
            <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Postmaster
            <micro-mail2SmsPostmaster-select [(mail2SmsPostmasterId)]="filter.postmasterId"></micro-mail2SmsPostmaster-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Rx Domain
            <input style="width: 120px;" [(ngModel)]="filter.smsEmailDomain">
          </div>
        </th>
        <th>
          <div class="vbox">
            Max Recipients
          </div>
        </th>
        <th>
          <div class="vbox">
            Max SMS Parts
          </div>
        </th>
        <th>
          <div class="vbox">
            MSISDN
            <input style="width: 120px;" [(ngModel)]="filter.waMsisdn">
          </div>
        </th>
        <th>
          <div class="vbox">
            Rx Domain
            <input style="width: 120px;" [(ngModel)]="filter.waEmailDomain">
          </div>
        </th>
        <th>
          <div class="vbox">
            Max Recipients
          </div>
        </th>
        <th>
          <div class="vbox">
            Email Address
            <input [(ngModel)]="filter.defaultMoToEmail">
          </div>
        </th>
        <th>
          <div class="vbox">
            Send to Email Address
          </div>
        </th>
        <th>
          <div class="vbox">
            Mode
          </div>
        </th>
        <th>
          <div class="vbox">
            Period (hrs)
          </div>
        </th>
        <th>
          <div class="vbox">
            Max Emails
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/mail2msg/configs/{{element.id}}">{{element.companyAbbr}}</a></td>
        <td ><micro-company-lookup [companyId]="element.companyId" ></micro-company-lookup></td>
        <td >
          <micro-mail2sms-postmaster-lookup *ngIf="!isNullOrUndefined(element.postmasterId)" [postmasterId]="element.postmasterId"></micro-mail2sms-postmaster-lookup>
          <ng-container *ngIf="isNullOrUndefined(element.postmasterId)"><div style="color: gray">System Postmaster</div></ng-container>
        </td>
        <td >
          <ng-container *ngIf="!isNullOrUndefined(element.smsEmailDomain)">{{element?.smsEmailDomain}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.smsEmailDomain)"><div style="color: gray">{{defaultConfig?.smsEmailDomain}}</div></ng-container>
        </td>
        <td >
          <ng-container *ngIf="!isNullOrUndefined(element.smsMaxRecipients)">{{element.smsMaxRecipients}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.smsMaxRecipients)"><div style="color: gray">{{defaultConfig.smsMaxRecipients}}</div></ng-container>
        </td>
        <td >
          <ng-container *ngIf="!isNullOrUndefined(element.smsMaxSmsParts)">{{element.smsMaxSmsParts}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.smsMaxSmsParts)"><div style="color: gray">{{defaultConfig.smsMaxSmsParts}}</div></ng-container>
        </td>
        <td >
          <ng-container>{{element?.waMsisdn}}</ng-container>
        </td>
        <td >
          <ng-container *ngIf="!isNullOrUndefined(element.waEmailDomain)">{{element?.waEmailDomain}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.waEmailDomain)"><div style="color: gray">{{defaultConfig?.waEmailDomain}}</div></ng-container>
        </td>
        <td >
          <ng-container *ngIf="!isNullOrUndefined(element.waMaxRecipients)">{{element.waMaxRecipients}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.waMaxRecipients)"><div style="color: gray">{{defaultConfig.waMaxRecipients}}</div></ng-container>
        </td>
        <td >
          <ng-container *ngIf="!isNullOrUndefined(element.defaultMoToEmail)">{{element.defaultMoToEmail}}</ng-container>
        </td>
        <td >
          <ng-container *ngIf="!isNullOrUndefined(element.defaultMoToEmailOnlyNoRecipient)">{{element.defaultMoToEmailOnlyNoRecipient ? 'When No Recipients' : 'Always'}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.defaultMoToEmailOnlyNoRecipient)"><div style="color: gray">{{defaultConfig.defaultMoToEmailOnlyNoRecipient ? 'When No Recipients' : 'Always'}}</div></ng-container>
        </td>
        <td >
          <ng-container *ngIf="!isNullOrUndefined(element.autoMoToLastEmail)">{{element.autoMoToLastEmail ? 'Enabled' : 'Disabled'}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.autoMoToLastEmail)"><div style="color: gray">{{defaultConfig.autoMoToLastEmail ? 'Enabled' : 'Disabled'}}</div></ng-container>
        </td>
        <td class="text-right">
          <ng-container *ngIf="!isNullOrUndefined(element.autoMoToLastEmailHours)">{{element.autoMoToLastEmailHours}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.autoMoToLastEmailHours)"><div style="color: gray">{{defaultConfig.autoMoToLastEmailHours}}</div></ng-container>
        </td>
        <td class="text-right">
          <ng-container *ngIf="!isNullOrUndefined(element.autoMoToMaxLastEmails)">{{element.autoMoToMaxLastEmails}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.autoMoToMaxLastEmails)"><div style="color: gray">{{defaultConfig.autoMoToMaxLastEmails}}</div></ng-container>
        </td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
