<div *ngIf="mail2SmsMailbox">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>sms</mat-icon> {{!mail2SmsMailbox.id ? 'Create new Msg Mailbox' : ('Msg Mailbox ' + mail2SmsMailbox.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!mail2SmsMailbox.id ? 'add' : 'save'}}</mat-icon>
        {{(mail2SmsMailbox.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="mail2SmsMailbox.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">Mailbox Configuration</th>
          </tr>
          </thead>
          <tbody>

          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td>
              <input #name="ngModel" [(ngModel)]="mail2SmsMailbox.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="imapMailboxId" [model]="imapMailboxId" label="Incoming Mailbox"></micro-valid-label></th>
            <td>
              <micro-imap-select #imapMailboxId="ngModel" [(ngModel)]="mail2SmsMailbox.imapMailboxId" mode="select" required="true" id="imapMailboxId" name="imapMailboxId"></micro-imap-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="postmasterId" [model]="postmasterId" label="Email2Msg Postmaster"></micro-valid-label></th>
            <td>
              <micro-mail2SmsPostmaster-select #postmasterId="ngModel" [(ngModel)]="mail2SmsMailbox.postmasterId" mode="select" required="true" id="postmasterId" name="postmasterId"></micro-mail2SmsPostmaster-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="recipientAddressFilter" [model]="recipientAddressFilter" label="Domain Address Filter"></micro-valid-label></th>
            <td>
              <input #recipientAddressFilter="ngModel" [(ngModel)]="mail2SmsMailbox.recipientAddressFilter" required placeholder=".*\.*msg.taulite.co.za" id="recipientAddressFilter" name="recipientAddressFilter">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="messagingChannel" [model]="messagingChannel" label="Messaging Channel"></micro-valid-label></th>
            <td>
              <micro-mail2smsMessagingChannel-select #messagingChannel="ngModel" [(ngModel)]="mail2SmsMailbox.messagingChannel" mode="select" required="true" id="messagingChannel" name="messagingChannel"></micro-mail2smsMessagingChannel-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="enabled" [model]="enabled" label="Enabled"></micro-valid-label></th>
            <td>
              <input type="checkbox" #enabled="ngModel" [(ngModel)]="mail2SmsMailbox.enabled" placeholder="Enabled" id="enabled" name="enabled">
            </td>
          </tr>
          <tr *ngIf="mail2SmsMailbox.id">
            <th>Incoming Mailbox Queueing Strategy</th>
            <td>
              <span *ngIf="mail2SmsMailbox.incomingQueueingStrategy.toUpperCase().includes('DIRECT')"><mat-icon class="icon-small" style="color: green">check_circle</mat-icon></span>
              <span *ngIf="!mail2SmsMailbox.incomingQueueingStrategy.toUpperCase().includes('DIRECT')"><mat-icon  class="icon-small" style="color: red">cancel</mat-icon></span>
              &nbsp;{{mail2SmsMailbox.incomingQueueingStrategy}}
            </td>
          </tr>

          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
