import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-mail2SmsUser-detail',
  templateUrl: './mail2SmsUser-detail.component.html'
})
export class Mail2SmsUserDetailComponent implements OnInit, OnChanges {
  mail2SmsUser:any;
  accountChecked:boolean = false;
  moToEmailFlag:any = "always";

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/mail2sms/mail2SmsUsers/${id}`).subscribe(
            data => {
              this.setMail2SmsUser(data);
              this.accountChecked = true;
            }
          );
        }
      });
  }

  setMail2SmsUser(mail2SmsUser:any) {
    this.mail2SmsUser = mail2SmsUser;
    this.setMoToEmailFlag();
  }

  createNew() {
    this.mail2SmsUser = {
      companyId: undefined,
      userId: undefined,
      senderOnly: false,
    };

    if (!this.authService.hasRole("COMPANY_ADMIN") && !this.authService.isSystemUser())
      this.mail2SmsUser.companyId = this.authService.getCompanyId();
  }

  save() {
    if (!this.mail2SmsUser.id) {
      this.http.post(`${this.env.e.url}/mail2sms/mail2SmsUsers`, this.mail2SmsUser)
        .subscribe(
          data => {
            this.setMail2SmsUser(data)
            this.accountChecked = true;
            this.alertService.info(`Created ${this.mail2SmsUser.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/mail2sms/mail2SmsUsers/${this.mail2SmsUser.id}`, this.mail2SmsUser)
        .subscribe(
          data => {
            this.setMail2SmsUser(data)
            this.accountChecked = true;
            this.alertService.info(`Updated  ${this.mail2SmsUser.name}`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/mail2sms/mail2SmsUsers/${this.mail2SmsUser.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.mail2SmsUser.name}`);
          this.router.navigate(["/mail2msg/users"]);
        }
      );
  }

  modelChangeUserId(value:any): void {
    this.http.get(`${this.env.e.url}/mail2sms/mail2SmsUsers/checkUser/${value}`)
      .subscribe(
        data => {
          var accStatus:any = data;
          this.mail2SmsUser.emailVerified = accStatus.emailVerified;
          this.mail2SmsUser.accountEnabled = accStatus.accountEnabled;
          this.mail2SmsUser.mail2SmsServiceAssigned = accStatus.mail2SmsServiceAssigned;
          this.accountChecked = true;
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.accountChecked = true;
    for (let prop in changes) {
      if (prop === "companyId") {
        this.mail2SmsUser.userId = undefined;
        this.accountChecked = false;
      }

      if (prop === 'userId') {
        this.http.get(`${this.env.e.url}/mail2sms/mail2SmsUsers/checkUser/${changes.userId.currentValue}`)
          .subscribe(
            data => {
              var accStatus:any = data;
              this.mail2SmsUser.emailVerified = accStatus.emailVerified;
              this.mail2SmsUser.accountEnabled = accStatus.accountEnabled;
              this.mail2SmsUser.mail2SmsServiceAssigned = accStatus.mail2SmsServiceAssigned;
              this.accountChecked = true;
            }
          );
      }
    }
  }

  setMoToEmailFlag() {
    if (this.mail2SmsUser.senderOnly) this.moToEmailFlag = "never";
    else if (!this.mail2SmsUser.receiveMoEmails) this.moToEmailFlag = "none";
    else if (this.mail2SmsUser.receiveMoEmailOnlyNoRecipient) this.moToEmailFlag = "noother";
    else this.moToEmailFlag = "always";
  }

  setReceiveMoEmails(flag) {
    switch (flag) {
      case "never":
        break;
      case "none":
        this.mail2SmsUser.receiveMoEmails = false;
        this.mail2SmsUser.receiveMoEmailOnlyNoRecipient = false;
        break;
      case "noother":
        this.mail2SmsUser.receiveMoEmails = true;
        this.mail2SmsUser.receiveMoEmailOnlyNoRecipient = true;
        break;
      case "always":
        this.mail2SmsUser.receiveMoEmails = true;
        this.mail2SmsUser.receiveMoEmailOnlyNoRecipient = false;
        break;
    }
  }

  moToEmailFlagChanged() {
    this.setReceiveMoEmails(this.moToEmailFlag);
  }

}
