<div *ngIf="smsCarrier">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>call_split</mat-icon> {{!smsCarrier.id ? 'Create new SMS Carrier' : smsCarrier.name}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!smsCarrier.id ? 'add' : 'save'}}</mat-icon>
        {{(smsCarrier.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="smsCarrier.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>


  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">SMS Carrier Detail</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
          <td>
            <input #name="ngModel" [(ngModel)]="smsCarrier.name" required placeholder="Name" id="name" name="name">
          </td>
        </tr>
        <tr>
          <th><micro-valid-label for="type" [model]="type" label="Type"></micro-valid-label></th>
          <td>
            <micro-smsCarrierType-select #type="ngModel" [(ngModel)]="smsCarrier.smsCarrierType" [required]="true" id="smsCarrierType" name="smsCarrierType"></micro-smsCarrierType-select>
          </td>
        </tr>
        <tr>
          <th [ngClass]="{'WARNING': !smsCarrier.enabled}"><micro-valid-label for="enabled" [model]="enabled" label="Enabled"></micro-valid-label></th>
          <td>
            <input #enabled="ngModel" type="checkbox" [(ngModel)]="smsCarrier.enabled" id="enabled" name="enabled">
          </td>
        </tr>
        <tr *ngIf="smsCarrier.smsCarrierType !== 'SMPP'">
          <th><micro-valid-label for="url" [model]="url" label="URL"></micro-valid-label></th>
          <td>
            <input #url="ngModel" type="url" [(ngModel)]="smsCarrier.url" required placeholder="URL" id="url" name="url">
          </td>
        </tr>
        <tr *ngIf="smsCarrier.smsCarrierType === 'HTTP2SMS'">
          <th><micro-valid-label for="node" [model]="node" label="Node"></micro-valid-label></th>
          <td>
            <input #node="ngModel" [(ngModel)]="smsCarrier.node" required placeholder="Node" id="node" name="node">
          </td>
        </tr>
        <tr *ngIf="smsCarrier.smsCarrierType !== 'SMPP'">
          <th >Username</th>
          <td>
            <input [(ngModel)]="smsCarrier.username" required placeholder="Username" id="username" name="username">
          </td>
        </tr>
        <ng-container *ngIf="smsCarrier.smsCarrierType !== 'SMPP'">
          <tr *ngIf="smsCarrier.id">
            <th>Change Password</th>
            <td>
              <input [(ngModel)]="changePassword" type="checkbox" id="changePassword" name="changePassword">
            </td>
          </tr>
          <tr *ngIf="changePassword || !smsCarrier.id">
            <th><micro-valid-label for="password" [model]="password" label="Password"></micro-valid-label></th>
            <td>
              <input type="password" #password="ngModel" [(ngModel)]="smsCarrier.clearPassword" required placeholder="Password" id="password" name="password">
            </td>
          </tr>
        </ng-container>
        <tr *ngIf="smsCarrier.smsCarrierType === 'SMPP'">
          <th><micro-valid-label for="smppCluster" [model]="smppCluster" label="SMPP Cluster"></micro-valid-label></th>
          <td>
            <micro-smscCluster-select #smppCluster="ngModel" [(ngModel)]="smsCarrier.smppClusterId" [required]="true" id="smppCluster" name="smppCluster"></micro-smscCluster-select>
          </td>
        </tr>
        <tr *ngIf="smsCarrier.smsCarrierType === 'SMPP'">
          <th><micro-valid-label for="tlvMultiPartSupported" [model]="tlvMultiPartSupported" label="Supports Multipart with TLVs"></micro-valid-label></th>
          <td>
            <input #tlvMultiPartSupported="ngModel" type="checkbox" [(ngModel)]="smsCarrier.tlvMultiPartSupported" id="tlvMultiPartSupported" name="tlvMultiPartSupported">
          </td>
        </tr>
        <tr *ngIf="smsCarrier.smsCarrierType === 'SMPP'">
          <th><micro-valid-label for="ecnsCarrier" [model]="ecnsCarrier" label="ECNS Carrier"></micro-valid-label></th>
          <td>
            <input #ecnsCarrier="ngModel" type="checkbox" [(ngModel)]="smsCarrier.ecnsCarrier" id="ecnsCarrier" name="ecnsCarrier">
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

