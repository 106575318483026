<micro-select-combo #sel
              placeholder="Mail2Msg Postmaster"
              routerBase="mail2msg/postmasters"
              [(id)]="mail2SmsPostmasterId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [requires]="requires"
              [allLabel]="allLabel"
>
</micro-select-combo>
