import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {WhatsappOutgoingMsgLogFilter} from "./whatsappOutgoingMsgLog.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../../srvs/lookup";
import {UsageFilter} from "../../usage/usage.filter";
import * as moment from "moment/moment";
import {WhatsappIncomingMsgLogFilter} from "../whatsappIncomingMsgLogs/whatsappIncomingMsgLog.filter";

@Component({
  selector: 'micro-whatsappOutgoingMsgLogs',
  templateUrl: './whatsappOutgoingMsgLogs.component.html'
})
export class WhatsappOutgoingMsgLogsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:WhatsappOutgoingMsgLogFilter = new WhatsappOutgoingMsgLogFilter();
  usageFilter:UsageFilter = new UsageFilter();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input()
  autoRefresh:boolean = false;

  timer:any;

  settings:any = {
    autoRefresh: false
  }

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              public authService:AuthService,
              public lookupService:LookupService) {
  }

  ngOnInit() {
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.reload();
    });

    this.addFilterListener();
    this.loadSettings();
    this.reload();
    this.timer = setInterval(()=> {
      if (this.autoRefresh) {
        this.reload();
      }
    }, 30000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    if (!this.usageFilter.fromInfinite) {
      params = params.set('from', '' + this.usageFilter.from);
    }
    if (!this.usageFilter.untilInfinite) {
      params = params.set('until', '' + this.usageFilter.until);
    }

    this.http.get(`${this.env.e.url}/chat/whatsapp/out`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }

  refresh() {
    this.reload();
  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  loadSettings() {
    let sessionSettingsJson = sessionStorage.getItem("chat_whatsapp_out_log_settings");
    let appSettings: any = JSON.parse(localStorage.getItem('chat_whatsapp_out_log_settings'));

    if (sessionSettingsJson) {
      let sessionSettings = JSON.parse(sessionSettingsJson);
      this.settings = sessionSettings.settings;
      this.filter = new WhatsappOutgoingMsgLogFilter(sessionSettings.filter);
      this.addFilterListener();
    }

    if (appSettings && appSettings?.dateRange) {
      let dateRange = appSettings.dateRange;

      this.usageFilter.dateRangeUnit = dateRange?.dateRangeUnit;
      if (!dateRange?.dateRangeUnit) dateRange.dateRangeUnit = 'day';
      if (dateRange?.dateRangeUnit === 'custom') {
        this.usageFilter.from = dateRange?.from;
        this.usageFilter.until = dateRange?.until;
        this.usageFilter.fromInfinite = dateRange?.fromInfinite;
        this.usageFilter.untilInfinite = dateRange?.untilInfinite;
      } else {
        this.usageFilter.from = moment().add(-1, dateRange.dateRangeUnit).startOf(dateRange.dateRangeUnit).toDate().getTime();
        this.usageFilter.until = undefined;
        this.usageFilter.fromInfinite = false;
        this.usageFilter.untilInfinite = true;
        this.usageFilter.dateRangeUnit = dateRange.dateRangeUnit;
      }
    }
  }

  saveSettings() {
    let sessionSettings = {
      filter: this.filter.toObj(),
      settings: this.settings,
    };
    sessionStorage.setItem("chat_whatsapp_out_log_settings", JSON.stringify(sessionSettings));

    let appSettings = {
      dateRange: {
        from: this.usageFilter.from,
        fromInfinite: this.usageFilter.fromInfinite,
        until: this.usageFilter.until,
        untilInfinite: this.usageFilter.untilInfinite,
        dateRangeUnit: this.usageFilter.dateRangeUnit
      }
    };
    localStorage.setItem("chat_whatsapp_out_log_settings", JSON.stringify(appSettings));
  }
}
