import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class Mail2SmsInFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.companyIdChange.next(filter.companyId || '');
      this.userIdChange.next(filter.userId || '');
      this.fromEmailChange.next(filter.fromEmail || '');
      this.recipientChange.next(filter.recipient || '');
      this.statusChange.next(filter.status || '');
      this.showPollsChange.next(filter.showPolls || '');
      this.mtStatusChange.next(filter.mtState || '');
      this.anyChange.emit();
    }
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(value:any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }

  fromEmailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get fromEmail():any {
    return this.fromEmailChange.value;
  }
  set fromEmail(value:any) {
    this.fromEmailChange.next(value);
    this.emitChange("fromEmail", value);
  }

  recipientChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get recipient():any {
    return this.recipientChange.value;
  }
  set recipient(value:any) {
    this.recipientChange.next(value);
    this.emitChange("recipient", value);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(value:any) {
    this.statusChange.next(value);
    this.emitChange("status", value);
  }

  showPollsChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get showPolls():any {
    return this.showPollsChange.value;
  }
  set showPolls(value:any) {
    this.showPollsChange.next(value);
    this.emitChange("showPolls", value);
  }

  mtStatusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mtStatus():any {
    return this.mtStatusChange.value;
  }
  set mtStatus(value:any) {
    this.mtStatusChange.next(value);
    this.emitChange("mtStatus", value);
  }

  msgChannelChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msgChannel():any {
    return this.msgChannelChange.value;
  }
  set msgChannel(value:any) {
    this.msgChannelChange.next(value);
    this.emitChange("msgChannel", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }
    if (this.fromEmail && this.fromEmail !== '') {
      params = params.set("fromEmail", this.fromEmail);
    }
    if (this.recipient && this.recipient !== '') {
      params = params.set("recipient", this.recipient);
    }
    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }
    if (this.showPolls && this.showPolls !== '') {
      params = params.set("showPolls", this.showPolls);
    }

    if (this.mtStatus && this.mtStatus !== '') {
      params = params.set("mtStatus", this.mtStatus);
    }

    return params;
  }

  toObj():any {
    return {
      companyId: this.companyId,
      userId: this.userId,
      fromEmail: this.fromEmail,
      recipient: this.recipient,
      status: this.status,
      showPolls: this.showPolls,
      mtStatus: this.mtStatus,
    }
  }

  public clear():void {
    this.companyId = undefined;
    this.userId = undefined;
    this.fromEmail = undefined;
    this.recipient = undefined;
    this.status = undefined;
    this.showPolls = false;
    this.mtStatus = undefined;
    this.msgChannel = undefined;
  }
}
