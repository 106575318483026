import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {AlarmFilter} from "./alarm.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
  selector: 'micro-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss']
})
export class AlarmsComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  showHeader:boolean = true;

  @Input()
  allowCreateNew:boolean = true;

  @Input()
  companyId:any;

  @Input()
  specProb:any;

  @Input()
  nwtype:any;

  @Input()
  ne:any;

  @Input()
  moClass:any;

  @Input()
  moInst:any;

  @Input()
  filter:AlarmFilter = new AlarmFilter();

  @Input()
  hideFilter:boolean;

  @Input()
  listOrLog:string = 'list';

  @Input()
  autoRefresh:boolean;

  @Input()
  showEditControls:boolean = true;

  timer:any;
  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = [];
  columnDefs:any[];

  @Input()
  columns:string[] = ['severity','specProb','nwType','ne','source','moClass','moInst','evtTime','evtType','siteId','systemGenerated','company','department','user'];

  @Input()
  allColumns:Set<string>;

  @Input()
  useFilter:boolean = false;

  @Input()
  dashboard:boolean = false;

  @Input()
  embedded:boolean = false;

  searching:boolean = false;

  showFilterCtrls:boolean = true;

  colLookup:any = {}

  @Input()
  alarmFilter:any = {
    id: -1
  };

  @Input()
  selectionMode:string;

  @Input()
  selectedRow:any;

  @Output()
  selectedRowChange:EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  barChartError:any;
  barChartData:any;
  barChartOptions:any;

  showChart:boolean = true;
  smallChart:boolean = false;

  @Input()
  ignoreDateFilter:boolean = false;

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  alarmSeverities:string[] = [
    'CLEARED',
    'INFO',
    'MINOR',
    'WARNING',
    'MAJOR',
    'CRITICAL',
    'INTERMEDIATE',
  ];
  systemGeneratedOptions:any[] = [
    {id: "true", name: "SYSTEM"},
    {id: "false", name: "USER"},
  ]

  constructor(public auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              public lookupService:LookupService,
              private route: ActivatedRoute,
              private router: Router) {
    this.addAnyListener();
    this.addFilterListener();
  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(100)).subscribe(change => {
      this.onAnyChange();
    });
  }

  onAnyChange() {
    this.anyChange.emit();
  }

  addAnyListener() {
    this.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.saveSettings();
      this.doReload();
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'alarmFilter') {
        this.reload();
      }
      if (prop === 'companyId') {
        this.filter.companyId = changes.companyId.currentValue;
        console.log("###DEBUG: companyId changes.currentValue: " + changes.companyId.currentValue + " filter.companyId: " + JSON.stringify(this.filter.companyId));
        // this.reload();
      }
    }
  }

  saveSettings() {
    if (this.embedded) {
      let existing:any = JSON.parse(localStorage.getItem('alarms_settings'));
      if (existing) {
        existing.smallChart = this.smallChart;
        existing.showChart = this.showChart;
        existing.filter.from = this.filter.from;
        existing.filter.fromInfinite = this.filter.fromInfinite;
        existing.filter.until = this.filter.until;
        existing.filter.untilInfinite = this.filter.untilInfinite;
        existing.filter.dateRangeUnit = this.filter.dateRangeUnit;

        localStorage.setItem("alarms_settings", JSON.stringify(existing));
      }
      return;
    }
    let settings:any = {
      autoRefresh: this.autoRefresh,
      listOrLog: this.listOrLog,
      useFilter: this.useFilter,
      filterId: this.alarmFilter ? this.alarmFilter.id : undefined,
      hideFilter: this.hideFilter,
      showFilterCtrls: this.showFilterCtrls,
      filter: this.filter.toObj(),
      columnDefs: this.columnDefs,
      pageSize: this.paginator.pageSize,
      showChart: this.showChart,
      smallChart: this.smallChart
    };
    localStorage.setItem("alarms_settings", JSON.stringify(settings));
    return settings;
  }

  loadSettings() {
    let settings:any = JSON.parse(localStorage.getItem('alarms_settings'));

    if (!settings) {
      settings = this.saveSettings();
    }

    this.showChart = settings.showChart === false ? false : true;
    this.smallChart = settings.smallChart === false ? false : true;
    this.paginator.pageSize = settings.pageSize || 50;

    if (!this.ignoreDateFilter) {
      if (settings.filter) {
        this.filter.from = settings.filter.from;
        this.filter.fromInfinite = settings.filter.fromInfinite;
        this.filter.until = settings.filter.until;
        this.filter.untilInfinite = settings.filter.untilInfinite;
        this.filter.dateRangeUnit = settings.filter.dateRangeUnit;
      }
    }

    if (this.embedded) {
      return;
    }

    this.autoRefresh = settings.autoRefresh || this.autoRefresh;
    this.listOrLog = settings.listOrLog || this.listOrLog;
    this.useFilter = settings.useFilter || this.useFilter;
    this.alarmFilter.id = settings.filterId;
    this.hideFilter = settings.hideFilter || this.hideFilter;
    this.showFilterCtrls = settings.showFilterCtrls || this.showFilterCtrls;
    this.columnDefs = settings.columnDefs || this.columnDefs;

    this.applyColDefs();

    this.filter = new AlarmFilter(settings.filter);
    this.addFilterListener();
  }

  showAllCols() {
    for (let columnDef of this.columnDefs) {
      columnDef.visible = true;
    }
    this.applyColDefs();
    this.saveSettings();
  }

  loadDefaultColDefs() {
    this.columnDefs = [
      {
        id: 'severity',
        desc: 'Severity',
        visible: true,
        size: '30px',
      },
      {
        id: 'specProb',
        desc: 'Specific Problem',
        visible: true,
      },
      {
        id: 'nwType',
        desc: 'Network Type',
        visible: true,
        size: '80px',
      },
      {
        id: 'ne',
        desc: 'Network Element',
        visible: true,
        size: '200px',
      },
      {
        id: 'moClass',
        desc: 'MO. Class',
        visible: true,
      },
      {
        id: 'moInst',
        desc: 'MO. Instance',
        visible: true,
        size: '200px',
      },
      {
        id: 'evtTime',
        desc: 'Event Time',
        visible: true,
        size: '180px',
      },
      {
        id: 'source',
        desc: 'Source',
        visible: false,
        size: '90px',
      },
      {
        id: 'evtType',
        desc: 'Event Type',
        visible: false,
        size: '100px',
      },
      {
        id: 'siteId',
        desc: 'Site ID',
        visible: false,
        size: '80px',
      },
      {
        id: 'systemGenerated',
        desc: 'System Generated',
        visible: false,
        size: '60px',
      },
      {
        id: 'company',
        desc: 'Company',
        visible: false,
        size: '80px',
      },
      {
        id: 'department',
        desc: 'Department',
        visible: false,
        size: '80px',
      },
      {
        id: 'user',
        desc: 'User',
        visible: false,
        size: '80px',
      }
    ];
    this.applyColDefs();
  }

  applyColDefs() {
    for (let colDef of this.columnDefs) {
      this.colLookup[colDef.id] = colDef;
    }
  }

  isColHidden(col) {
    return !this.colLookup[col].visible;
  }

  getColWidth(col) {
    return this.colLookup[col].size;
  }

  ngOnInit() {
    this.loadDefaultColDefs();
    this.applyColDefs();

    this.allColumns = new Set<string>(this.columns);

    this.route.queryParams
      .subscribe((params: Params) => {
        let filterId = params['filterId'];
        let filter = params['filter'];
        if (filterId || filter) {
          this.embedded = true;
          this.useFilter = true;
          if (filterId) {
            this.alarmFilter.id = filterId;
          } else {
            this.alarmFilter.value = filter;
          }
          this.reload();
          this.timer = setInterval(()=> {
            if (this.autoRefresh) {
              this.reload();
            }
          }, 10000);
          return;
        }
      });

    this.loadSettings();

    if (this.specProb) {
      this.filter.specProb = this.specProb;
    }

    if (this.specProb) {
      this.filter.specProb = this.specProb;
    }

    if (this.companyId) {
      this.filter.companyId = this.companyId;
    }

    if (this.nwtype) {
      this.filter.nwType = this.nwtype;
    }

    if (this.ne) {
      this.filter.ne = this.ne;
    }

    if (this.moClass) {
      this.filter.moClass = this.moClass;
    }

    if (this.moInst) {
      this.filter.moInst = this.moInst;
    }

    this.reload();
    this.timer = setInterval(()=> {
      if (this.autoRefresh) {
        this.reload();
      }
    }, 10000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  alarmFilterChanged() {
    this.saveSettings();
    this.paginator.pageIndex = 0;
    this.reload();
  }

  reload() {
    this.onAnyChange();
  }

  doReload() {
    this.reloadChart();
    if (this.useFilter) {
      if (!this.alarmFilter || !this.alarmFilter.value) {
        return;
      }
      let params:HttpParams = new HttpParams()
        .set("filter", this.alarmFilter.value)
        .set("limit", '' + (this.paginator.pageSize || 50))
        .set("offset", '' + (this.paginator.pageIndex || 0))
      ;

      if (!this.ignoreDateFilter) {
        if (!this.filter.fromInfinite) {
          params = params.set('from', '' + this.filter.from);
        }

        if (!this.filter.untilInfinite) {
          params = params.set('until', '' + this.filter.until);
        }

        if (this.filter.companyId && this.filter.companyId !== '') {
          params = params.set('companyId', this.filter.companyId);
        }
      }

      this.searching = true;
      this.http.get(`${this.env.e.url}/alarm${this.listOrLog === 'log' ? '/log' : ''}/search`, {params: params}).subscribe(
        data => {
          let page = data as any;
          this.dataSource.data = page.entries;
          this.paginator.pageIndex = page.offset;
          this.paginator.length = page.count;
          this.searching = false;
        }
      );
      return;
    }

    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 50))
      ;

    this.searching = true;
    this.http.get(`${this.env.e.url}/alarm${this.listOrLog === 'log' ? '/log' : ''}`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.length = page.totalElements;
        this.searching = false;
      }
    );
  }

  reloadChart():void {
    if (!this.filter.from || !this.filter.until || !this.showChart) {
      return;
    }
    let params:HttpParams = this.filter.getParams()
      .set("maxRows", '5000')
      .set("dateRangeUnit", this.filter.dateRangeUnit)
    ;

    if (!this.filter.fromInfinite) {
      params = params.set('from', '' + this.filter.from);
    }

    if (!this.filter.untilInfinite) {
      params = params.set('until', '' + this.filter.until)
    }

    var prefix = '';
    if (this.useFilter) {
      if (!this.alarmFilter || !this.alarmFilter.value) {
        return;
      }
      params = params.set("filter", this.alarmFilter.value);
      prefix = "/search"
    }

    this.http.get(`${this.env.e.url}/alarm/chart${prefix}/${this.listOrLog}/bar`, {params:params}).subscribe(
      data => {
        var rsp:any = data as any;
        this.barChartError = rsp.error;
        if (!rsp.error) {
          this.barChartData = rsp.data;
          this.barChartOptions = this.createBarChartOptions();
        }
      }
    );
  }

  createBarChartOptions() {
    return {
      legend: {
        enabled: false
      },
      chart: {
        animation: false,
        type: 'column',
        height: this.smallChart ? 100 : 350
      },
      navigator: {
        enabled: !this.smallChart
      },
      rangeSelector: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      yAxis: {
        min: 0,
        visible: true,
      },
      xAxis: {
        showEmpty: true,
        type: 'datetime',
      },
      scrollbar: {
        enabled: false
      },
      plotOptions: {
        series: {
          animation: false
        },
        column: {
          stacking: 'normal'
        }
      },
      series: this.barChartData
    };
  }

  regenCharts() {
    this.barChartOptions = this.createBarChartOptions();
  }

  listLogChange() {
    this.saveSettings();
    this.reload();
  }

  hideFilterChange() {
    this.saveSettings();
  }

  showFilterControls() {
    return !this.useFilter && this.showFilterCtrls;
  }

  toggleShowFilterControls() {
    this.showFilterCtrls = !this.showFilterCtrls;
    this.saveSettings();
  }

  showHideCol(col, show) {
    console.log(col, show);
  }

  onSelectedRowChange() {
    this.selectedRowChange.emit(this.selectedRow);
  }

  getTooltipForJump(alarm) {
    return 'Jump to ' + alarm.props._display + ' ' + this.lookupService.lookup(alarm.props._domain, alarm.props._collection, alarm.props._id);
  }
}
