<div class="stick-top">
  <mat-toolbar>
    <mat-icon>sms</mat-icon> Email2Msg Mailboxes
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/mail2msg/mailboxes/0">
        <mat-icon>add</mat-icon>
        Create a new Mailbox
      </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>

        <th>
          <div class="vbox">
            Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th>
          <div class="vbox">
            Incoming Mailbox
            <micro-imap-select [(imapId)]="filter.imapMailboxId"></micro-imap-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Postmaster
            <micro-mail2SmsPostmaster-select [(mail2SmsPostmasterId)]="filter.postmasterId"></micro-mail2SmsPostmaster-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Address Filter
            <input [(ngModel)]="filter.recipientAddressFilter">
          </div>
        </th>
        <th>
          <div class="vbox">
            Channel
            <micro-mail2smsMessagingChannel-select [(messagingChannel)]="filter.messagingChannel"></micro-mail2smsMessagingChannel-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Enabled
            <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Incoming Mailbox<br>
            Queueing Strategy
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td><a routerLink="/mail2msg/mailboxes/{{element.id}}">{{element.name}}</a></td>
        <td><micro-imap-mailbox-lookup [imapMailboxId]="element.imapMailboxId"></micro-imap-mailbox-lookup></td>
        <td><micro-mail2sms-postmaster-lookup [postmasterId]="element.postmasterId"></micro-mail2sms-postmaster-lookup></td>
        <td>{{element.recipientAddressFilter}}</td>
        <td>{{element.messagingChannel}}</td>
        <td>{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
        <td>
          <mat-icon *ngIf="element.incomingQueueingStrategy.toUpperCase().includes('DIRECT')" class="icon-small" style="color: green">check_circle</mat-icon>
          <mat-icon *ngIf="!element.incomingQueueingStrategy.toUpperCase().includes('DIRECT')" class="icon-small" style="color: red">cancel</mat-icon>
          &nbsp;{{element.incomingQueueingStrategy}}
        </td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
