import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class WhatsappIncomingMsgLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.typeChange.next(filter.type || '');
      this.textChange.next(filter.text || '');
      this.fromMsisdnChange.next(filter.fromMsisdn || '');
      this.toMsisdnChange.next(filter.toMsisdn || '');
      this.userIdChange.next(filter.userId || '');
      this.chatCarrierIdChange.next(filter.chatCarrierId || '');
      this.companyIdChange.next(filter.companyId || '');
      this.anyChange.emit();
    }
  }

  typeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get type():any {
    return this.typeChange.value;
  }
  set type(type:any) {
    this.typeChange.next(type);
    this.emitChange("type", type);
  }

  textChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get text():any {
    return this.textChange.value;
  }
  set text(text:any) {
    this.textChange.next(text);
    this.emitChange("text", text);
  }

  fromMsisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get fromMsisdn():any {
    return this.fromMsisdnChange.value;
  }
  set fromMsisdn(fromMsisdn:any) {
    this.fromMsisdnChange.next(fromMsisdn);
    this.emitChange("fromMsisdn", fromMsisdn);
  }

  toMsisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get toMsisdn():any {
    return this.toMsisdnChange.value;
  }
  set toMsisdn(toMsisdn:any) {

    this.toMsisdnChange.next(toMsisdn);
    this.emitChange("toMsisdn", toMsisdn);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  chatCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get chatCarrierId():any {
    return this.chatCarrierIdChange.value;
  }
  set chatCarrierId(chatCarrierId:any) {

    this.chatCarrierIdChange.next(chatCarrierId);
    this.emitChange("chatCarrierId", chatCarrierId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.type && this.type !== '') {
      params = params.set("type", this.type);
    }

    if (this.text && this.text !== '') {
      params = params.set("text", this.text);
    }

    if (this.fromMsisdn && this.fromMsisdn !== '') {
      params = params.set("fromMsisdn", this.fromMsisdn);
    }

    if (this.toMsisdn && this.toMsisdn !== '') {
      params = params.set("toMsisdn", this.toMsisdn);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.chatCarrierId && this.chatCarrierId !== '') {
      params = params.set("chatCarrierId", this.chatCarrierId);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    return params;
  }
  toObj():any {
    return {
      type: this.type,
      text: this.text,
      fromMsisdn: this.fromMsisdn,
      toMsisdn: this.toMsisdn,
      userId: this.userId,
      chatCarrierId: this.chatCarrierId,
      companyId: this.companyId
    }
  }

  public clear():void {
    this.type = undefined;
    this.text = undefined;
    this.fromMsisdn = undefined;
    this.toMsisdn = undefined;
    this.userId = undefined;
    this.chatCarrierId = undefined;
    this.companyId = undefined;
  }
}
