<mat-toolbar><mat-icon>chat</mat-icon> WhatsApp Outgoing Logs</mat-toolbar>
<div class="hbox lightly-padded button-bar">
  <button mat-raised-button type="button" (click)="refresh()">Refresh</button>&nbsp;
  <micro-checkbox [(checked)]="settings.autoRefresh" (checkedChange)="saveSettings()" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
  <div class="fill-space"></div>
  <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
</div>

<micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="datetime" entityType="WHATSAPP_OUT" filter="" groupBy="from"></micro-do-chart>

<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div *ngIf="authService.hasRole('COMPANY_ADMIN')" class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          User
          <micro-user-select [companyId]="filter.companyId" [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Date
        </div>
      </th>
      <th>
        <div class="vbox">
          Chat Carrier
          <micro-chatCarrier-select [(chatCarrierId)]="filter.chatCarrierId"></micro-chatCarrier-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Batch
          <input [(ngModel)]="filter.batchId"/>
        </div>
      </th>
      <th>
        <div class="vbox">
          From
          <input [(ngModel)]="filter.fromMsisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          To
          <input [(ngModel)]="filter.toMsisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          Type
          <micro-whatsapp-message-type-select [(messageType)]="filter.type"></micro-whatsapp-message-type-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Text
          <input [(ngModel)]="filter.text">
        </div>
      </th>
      <th>
        <div class="vbox">
          Source Channel
          <input [(ngModel)]="filter.sourceChannel">
        </div>
      </th>
      <th>
        <div class="vbox">
          Status
          <input [(ngModel)]="filter.status">
        </div>
      </th>
      <th>
        <div class="vbox">
          Error
          <input [(ngModel)]="filter.error">
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="authService.hasRole('COMPANY_ADMIN')"><micro-company-lookup [companyId]="element.companyId" [link]="false"></micro-company-lookup></td>
      <td><micro-user-lookup [userId]="element.userId" [link]="false"></micro-user-lookup></td>
      <td><a routerLink="/chat/whatsapp/logs/outgoing/{{element.id}}">{{element.datetime | dateTime}}</a></td>
      <td><micro-chatCarrier-lookup [chatCarrierId]="element.chatCarrierId"></micro-chatCarrier-lookup></td>
      <td>{{element.batchId}}</td>
      <td>{{element.from}}</td>
      <td>{{element.to}}</td>
      <td>{{element.type}}</td>
      <td matTooltip="{{element.text}}">{{element.text}}</td>
      <td>{{element.sourceChannel}}</td>
      <td [ngClass]="element.error ? 'CRITICAL' : ''">{{element.status}}</td>
      <td matTooltip="{{element.error}}{{element.errorDetail ? ': '+element.errorDetail:''}}" >{{element.error}}{{element.errorDetail ? ': '+element.errorDetail:''}}</td>
    </tr>
  </tbody>
</table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="50"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
