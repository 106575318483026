<div *ngIf="whatsappIncomingMsgLog">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>help</mat-icon>
      {{'WhatsApp Incoming Log ' + whatsappIncomingMsgLog.id}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button *ngIf="!whatsappIncomingMsgLog.read || whatsappIncomingMsgLog.read" mat-raised-button class="primary" type="button" (click)="markAsRead()">
        <mat-icon>visibility</mat-icon>
        Mark as read
      </button>
    </div>
  </div>

  <div class="padded vbox-space">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">WhatsApp Incoming Message</th>
      </tr>
      <tr>
        <th>Carrier</th>
        <th>Received At</th>
        <th>Type</th>
        <th>From</th>
        <th>To</th>
        <th>Read</th>
        <th>Price</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td><micro-chatCarrier-lookup [chatCarrierId]="whatsappIncomingMsgLog.chatCarrierId"></micro-chatCarrier-lookup></td>
        <td>{{whatsappIncomingMsgLog.receivedAt|dateTime}}</td>
        <td>{{whatsappIncomingMsgLog.type}}</td>
        <td>{{whatsappIncomingMsgLog.from}}</td>
        <td>{{whatsappIncomingMsgLog.to}}</td>
        <td>{{whatsappIncomingMsgLog.read}}</td>
        <td>{{whatsappIncomingMsgLog.pricePerMessage | currency:whatsappIncomingMsgLog.currency:'code':'1.4-4'}}</td>
      </tr>
      </tbody>
    </table>

    <table class="basicTable" *ngIf="whatsappIncomingMsgLog.type === 'TEXT'" style="width: 500px;">
      <thead>
        <tr>
          <th colspan="100">TEXT Message</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="text-wrap: wrap">{{whatsappIncomingMsgLog.text}}</td>
        </tr>
      </tbody>
    </table>

    <table class="basicTable" *ngIf="whatsappIncomingMsgLog.type === 'VIDEO'
                                    || whatsappIncomingMsgLog.type === 'AUDIO'
                                    || whatsappIncomingMsgLog.type === 'IMAGE'
                                    || whatsappIncomingMsgLog.type === 'DOCUMENT'
                                    || whatsappIncomingMsgLog.type === 'STICKER'"
           style="max-width: 100%; min-width: 500px;">
      <thead>
      <tr>
        <th colspan="100">{{whatsappIncomingMsgLog.type}} Message</th>
      </tr>
      <tr *ngIf="whatsappIncomingMsgLog.caption">
        <th>Caption</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="whatsappIncomingMsgLog.caption">
        <td>{{whatsappIncomingMsgLog.caption}}</td>
      </tr>
      <tr *ngIf="whatsappIncomingMsgLog.data">
        <td colspan="100" *ngIf="whatsappIncomingMsgLog.type === 'IMAGE' || whatsappIncomingMsgLog.type === 'STICKER'" style="max-width: 100%;">
          <img [src]="'data:;base64,' + whatsappIncomingMsgLog.data | safe">
        </td>
      </tr>
      </tbody>
    </table>

    <table class="basicTable" *ngIf="whatsappIncomingMsgLog.type === 'LOCATION'">
      <thead>
      <tr>
        <th colspan="100">LOCATION Message</th>
      </tr>
      <tr>
        <th>Latitute</th>
        <th>Longitude</th>
        <th>Name</th>
        <th>Address</th>
        <th>Map Link</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{whatsappIncomingMsgLog.lat}}</td>
        <td>{{whatsappIncomingMsgLog.lon}}</td>
        <td>{{whatsappIncomingMsgLog.locationName}}</td>
        <td>{{whatsappIncomingMsgLog.locationAddress}}</td>
        <td><a href="https://maps.google.com/maps?q=loc:{{whatsappIncomingMsgLog.lat}}+{{whatsappIncomingMsgLog.lon}}" target="_blank">Open</a></td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="whatsappIncomingMsgLog.type === 'CONTACT'">
    <mat-toolbar>Contacts</mat-toolbar>

    <div class="padded vbox vbox-space">
      <div class="boxwrapper" *ngFor="let c of whatsappIncomingMsgLog.contactsAsObject">
        <mat-toolbar>{{c.name.formattedName}}</mat-toolbar>
        <div class="padded vbox vbox-space">
          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Contact Details</th>
            </tr>
            <tr>
              <th>First.Name</th>
              <th>Last.Name</th>
              <th>Middle.Name</th>
              <th>Formatted.Name</th>
              <th>Name.Suffix</th>
              <th>Name.Prefix</th>
              <th>Birthday</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{c.name.firstName}}</td>
              <td>{{c.name.lastName}}</td>
              <td>{{c.name.middleName}}</td>
              <td>{{c.name.formattedName}}</td>
              <td>{{c.name.nameSuffix}}</td>
              <td>{{c.name.namePrefix}}</td>
              <td>{{c.birthday}}</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Addresses</th>
            </tr>
            <tr>
              <th>Street</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th>Country</th>
              <th>Country.Code</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let address of c.addresses">
              <td>{{address.street}}</td>
              <td>{{address.city}}</td>
              <td>{{address.state}}</td>
              <td>{{address.zip}}</td>
              <td>{{address.country}}</td>
              <td>{{address.countryCode}}</td>
              <td>{{address.type}}</td>
            </tr>
            <tr *ngIf="!c.addresses || c.addresses?.length == 0">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">E-Mails</th>
            </tr>
            <tr>
              <th>E-Mail</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let email of c.emails">
              <td>{{email.email}}</td>
              <td>{{email.type}}</td>
            </tr>
            <tr *ngIf="!c.emails || c.emails?.length == 0">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Phones</th>
            </tr>
            <tr>
              <th>Phone</th>
              <th>WhatsApp.ID</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let phone of c.phones">
              <td>{{phone.phone}}</td>
              <td>{{phone.waId}}</td>
              <td>{{phone.type}}</td>
            </tr>
            <tr *ngIf="!c.phones || c.phones?.length == 0">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">URLs</th>
            </tr>
            <tr>
              <th>URL</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let u of c.urls">
              <td>{{u.url}}</td>
              <td>{{u.type}}</td>
            </tr>
            <tr *ngIf="!c.urls || c.urls?.length == 0">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Organization</th>
            </tr>
            <tr>
              <th>Company</th>
              <th>Department</th>
              <th>Title</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="c.org">
              <td>{{c.org.company}}</td>
              <td>{{c.org.department}}</td>
              <td>{{c.org.title}}</td>
            </tr>
            <tr *ngIf="!c.org">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
