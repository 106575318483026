<ng-container *ngIf="!embedded">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>sms</mat-icon> Outgoing SMS
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
      <micro-checkbox [(checked)]="settings.autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing" (click)="saveSettings()"></micro-checkbox>
      <div class="fill-space"></div>
      <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
    </div>
  </div>
  <micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="requestedAt" entityType="SMS_MT" filter="" groupBy="state"></micro-do-chart>
</ng-container>

<div *ngIf="embedded">
  <div class="stick-top">
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>
    </div>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th class="dateTimeHeader">
        <div class="vbox">
          Requested At
        </div>
      </th>
      <th *ngIf="auth.hasRole('COMPANY_ADMIN')">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th style="width: 114px;">
        <div class="vbox">
          From
          <micro-anumber-select [(value)]="filter.clientSourceAddress"></micro-anumber-select>
        </div>
      </th>
      <th class="msisdnHeader">
        <div class="vbox">
          To
          <input [(ngModel)]="filter.destinationAddress">
        </div>
      </th>
      <th>
        <div class="vbox">
          Text
          <input [(ngModel)]="filter.textData">
        </div>
      </th>
      <th style="width: 93px;">
        <div class="vbox">
          State
          <micro-sms-state-select [(smsState)]="filter.state"></micro-sms-state-select>
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Sent At
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Completed At
        </div>
      </th>
      <th style="width: 59px;">
        <div class="vbox">
          Raised By
          <select [(ngModel)]="filter.systemGenerated">
            <option value="">ALL</option>
            <option value="true">SYSTEM</option>
            <option value="false">USER</option>
          </select>
        </div>
      </th>
      <th style="width: 50px;">
        <div class="vbox">
          User
          <micro-user-select [(userId)]="filter.userId" ></micro-user-select>
        </div>
      </th>
      <th style="width: 120px;">
        <div class="vbox">
          MNO
          <micro-mno-select [(mnoId)]="filter.mnoId" ></micro-mno-select>
        </div>
      </th>
      <th style="width: 100px;">
        <div class="vbox">
          Via
        </div>
      </th>
      <th>
        <div class="vbox">
          Content-Type
          <micro-sms-contentType-select [(contentType)]="filter.contentType"></micro-sms-contentType-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="!embedded"><a routerLink="/smss/{{element.id}}">{{element.requestedAt | dateTimeMs}}</a></td>
      <td *ngIf="embedded"><a (click)="dialogService.openDetail({objectType: 'SmsMt', objectId: element.id})">{{element.requestedAt|dateTimeMs}}</a></td>
      <td *ngIf="auth.hasRole('COMPANY_ADMIN')"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
      <td [matTooltip]="element.clientSourceAddress?.addr + ' ' + element.carrierSourceAddress?.addr">{{element.clientSourceAddress?.addr}}</td>
      <td [matTooltip]="element.destinationAddress?.addr">{{element.destinationAddress?.addr}}</td>
      <td style="max-width: 120px" [matTooltip]="element.textData">{{element.textData}}</td>
      <td [ngClass]="element.error ? 'CRITICAL' : ''">
        {{element.state}}
      </td>
      <td [matTooltip]="element.sentAt | dateTime">{{element.sentAt | dateTime}}</td>
      <td [matTooltip]="element.completedAt | dateTime">{{element.completedAt | dateTime}}</td>
      <td [matTooltip]="element.systemGenerated ? 'SYSTEM' : 'USER'">{{element.systemGenerated ? 'SYSTEM' : 'USER'}}</td>
      <td [matTooltip]="lookupService.lookup('auth', 'users', element.userId)">
        <micro-user-lookup [userId]="element.userId"></micro-user-lookup>
      </td>
      <td><micro-mno-lookup [mnoId]="element.mnoId"></micro-mno-lookup></td>
      <td [matTooltip]="element.via">{{element.via}}</td>
      <td [matTooltip]="element.contentType">{{element.contentType}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="pagignatorChanged()"
                       [pageSize]="settings.pageSize"
                       [pageSizeOptions]="[25, 50, 100, 250]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
