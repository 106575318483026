<micro-select-combo #sel
              placeholder="Email2Msg Config"
              routerBase="mail2msg/configs"
              [(id)]="mail2SmsConfigId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
