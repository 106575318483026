<mat-toolbar *ngIf="!embedded"><mat-icon>chat</mat-icon> Chat WhatsApp Incoming Logs</mat-toolbar>
<div class="hbox lightly-padded button-bar">
  <button mat-raised-button type="button" (click)="refresh()">Refresh</button>&nbsp;
  <micro-checkbox [(checked)]="settings.autoRefresh" (checkedChange)="saveSettings()" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
  <div class="fill-space"></div>
  <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
</div>

<micro-do-chart *ngIf="!embedded" [smallChart]="true" [doChartFilter]="usageFilter" dateField="datetime" entityType="WHATSAPP_IN" filter="" groupBy="to"></micro-do-chart>

<mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
<table class="basicTable">
  <thead>
    <tr>
      <th *ngIf="selectionMode" style="width: 30px;"></th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Date/Time
        </div>
      </th>
      <th>
        <div class="vbox">
          Chat Carrier
          <micro-chatCarrier-select [(chatCarrierId)]="filter.chatCarrierId"></micro-chatCarrier-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          From
          <input [(ngModel)]="filter.fromMsisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          To
          <input [(ngModel)]="filter.toMsisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          Type
          <micro-whatsapp-message-type-select [(messageType)]="filter.type"></micro-whatsapp-message-type-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Text
          <input [(ngModel)]="filter.text">
        </div>
      </th>
      <th>
        <div class="vbox" style="min-width: 80px;">
          Price
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="selectionMode">
        <mat-radio-button [value]="element"></mat-radio-button>
      </td>
      <td><a routerLink="/chat/whatsapp/logs/incoming/{{element.id}}">{{element.datetime|dateTimeMs}}</a></td>
      <td><micro-chatCarrier-lookup [chatCarrierId]="element.chatCarrierId"></micro-chatCarrier-lookup></td>
      <td>{{element.from}}</td>
      <td>{{element.to}}</td>
      <td>{{element.type}}</td>
      <td style="min-width: 300px; max-width: 700px;">{{element.text?.substring(0, 150)}}</td>
      <td>{{element.pricePerMessage|currency:element.currency:'code':'1.4-4'}}</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="50"
                       [pageSizeOptions]="[5, 10, 25, 50, 100, 250, 500]">
        </mat-paginator>
      </td>
    </tr>
  </tfoot>
</table>
</mat-radio-group>
