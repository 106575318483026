import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class Mail2SmsConfigFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  defaultMoToEmailOnlyNoRecipientChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get defaultMoToEmailOnlyNoRecipient():any {
    return this.defaultMoToEmailOnlyNoRecipientChange.value;
  }
  set defaultMoToEmailOnlyNoRecipient(defaultMoToEmailOnlyNoRecipient:any) {
    this.defaultMoToEmailOnlyNoRecipientChange.next(defaultMoToEmailOnlyNoRecipient);
    this.emitChange("defaultMoToEmailOnlyNoRecipient", defaultMoToEmailOnlyNoRecipient);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  postmasterIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get postmasterId():any {
    return this.postmasterIdChange.value;
  }
  set postmasterId(postmasterId:any) {
    this.postmasterIdChange.next(postmasterId);
    this.emitChange("postmasterId", postmasterId);
  }

  smsEmailDomainChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smsEmailDomain():any {
    return this.smsEmailDomainChange.value;
  }
  set smsEmailDomain(val:any) {
    this.smsEmailDomainChange.next(val);
    this.emitChange("smsEmailDomain", val);
  }

  waEmailDomainChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get waEmailDomain():any {
    return this.waEmailDomainChange.value;
  }
  set waEmailDomain(val:any) {
    this.waEmailDomainChange.next(val);
    this.emitChange("waEmailDomain", val);
  }

  waMsisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get waMsisdn():any {
    return this.waMsisdnChange.value;
  }
  set waMsisdn(val:any) {
    this.waMsisdnChange.next(val);
    this.emitChange("waMsisdn", val);
  }

  defaultMoToEmailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get defaultMoToEmail():any {
    return this.defaultMoToEmailChange.value;
  }
  set defaultMoToEmail(defaultMoToEmail:any) {
    this.defaultMoToEmailChange.next(defaultMoToEmail);
    this.emitChange("defaultMoToEmail", defaultMoToEmail);
  }

  companyAbbrChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyAbbr():any {
    return this.companyAbbrChange.value;
  }
  set companyAbbr(companyAbbr:any) {
    this.companyAbbrChange.next(companyAbbr);
    this.emitChange("companyAbbr", companyAbbr);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.defaultMoToEmailOnlyNoRecipient && this.defaultMoToEmailOnlyNoRecipient !== '') {
      params = params.set("defaultMoToEmailOnlyNoRecipient", this.defaultMoToEmailOnlyNoRecipient);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.postmasterId && this.postmasterId !== '') {
      params = params.set("postmasterId", this.postmasterId);
    }

    if (this.smsEmailDomain && this.smsEmailDomain !== '') {
      params = params.set("smsEmailDomain", this.smsEmailDomain);
    }

    if (this.waEmailDomain && this.waEmailDomain !== '') {
      params = params.set("waEmailDomain", this.waEmailDomain);
    }

    if (this.waMsisdn && this.waMsisdn !== '') {
      params = params.set("waMsisdn", this.waMsisdn);
    }

    if (this.defaultMoToEmail && this.defaultMoToEmail !== '') {
      params = params.set("defaultMoToEmail", this.defaultMoToEmail);
    }

    if (this.companyAbbr && this.companyAbbr !== '') {
      params = params.set("companyAbbr", this.companyAbbr);
    }

    return params;
  }

  public clear():void {

    this.defaultMoToEmailOnlyNoRecipient = undefined;
    this.companyId = undefined;
    this.postmasterId = undefined;
    this.smsEmailDomain = undefined;
    this.waEmailDomain = undefined;
    this.waMsisdn = undefined;
    this.defaultMoToEmail = undefined;
    this.companyAbbr = undefined;
    this.id = undefined;

  }
}
