<div *ngIf="mail2SmsUser">
  <div class="stick-top">
    <mat-toolbar>
      <div class="hbox-nowrap" *ngIf="!mail2SmsUser.id"><mat-icon>contact_mail</mat-icon> Create new Email2Msg User <div class="hbox-nowrap" *ngIf="mail2SmsUser.s">:&nbsp;<micro-userEmail-lookup [userId]="mail2SmsUser.userId"></micro-userEmail-lookup></div></div>
      <div class="hbox-nowrap" *ngIf="mail2SmsUser.id"><mat-icon>contact_mail</mat-icon> Email2Msg User:&nbsp;<micro-userEmail-lookup [userId]="mail2SmsUser.userId"></micro-userEmail-lookup></div>
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || !mail2SmsUser.companyId || !mail2SmsUser.userId">
        <mat-icon>{{!mail2SmsUser.id ? 'add' : 'save'}}</mat-icon>
        {{(mail2SmsUser.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="mail2SmsUser.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100" style="min-width: 470px;">User Configuration</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>
              <micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label>
            </th>
            <td>
              <micro-company-select #companyId="ngModel" [(ngModel)]="mail2SmsUser.companyId" [(companyId)]="mail2SmsUser.companyId" [required]="true" [disabled]="!!mail2SmsUser.id" id="companyId" name="companyId"></micro-company-select>
            </td>
          </tr>
          <tr>
            <th>
              <micro-valid-label for="userId" [model]="userId" label="User"></micro-valid-label>
            </th>
            <td>
              <micro-user-select #userId="ngModel" [(ngModel)]="mail2SmsUser.userId" [companyId]="mail2SmsUser.companyId" [(userId)]="mail2SmsUser.userId" [required]="true" [disabled]="!!mail2SmsUser.id" id="userId" name="userId" (ngModelChange)="modelChangeUserId($event)"></micro-user-select>
            </td>
          </tr>
          <tr>
            <th [ngClass]="{'WARNING': !mail2SmsUser.smsEnabled, 'SUCCESS': mail2SmsUser.smsEnabled}">Enabled for SMS Channel</th>
            <td>
              <input type="checkbox" [(ngModel)]="mail2SmsUser.smsEnabled" id="smsEnabled" name="smsEnabled">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="smsAnumberId" [model]="smsAnumberId" label="SMS A-Number"></micro-valid-label></th>
            <td>
              <micro-anumber-select #smsAnumberId="ngModel"
                                    [(ngModel)]="mail2SmsUser.smsAnumberId"
                                    [companyId]="mail2SmsUser.companyId"
                                    [(anumberId)]="mail2SmsUser.smsAnumberId"
                                    [required]="mail2SmsUser.smsEnabled"
                                    [disabled]="!mail2SmsUser.smsEnabled"
                                    id="smsAnumberId"
                                    name="smsAnumberId">
              </micro-anumber-select>
            </td>
          </tr>
          <tr>
            <th [ngClass]="{'WARNING': !mail2SmsUser.whatsappEnabled, 'SUCCESS': mail2SmsUser.whatsappEnabled}">Enabled for WhatsApp Channel</th>
            <td>
              <input type="checkbox" [(ngModel)]="mail2SmsUser.whatsappEnabled" id="whatsappEnabled" name="whatsappEnabled">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="waMsisdn" [model]="waMsisdn" label="WhatsApp MSISDN"></micro-valid-label></th>
            <td>
              <input #waMsisdn="ngModel"
                     [(ngModel)]="mail2SmsUser.waMsisdn"
                     [required]="false"
                     placeholder="Use company WhatsApp MSISDN"
                     [disabled]="!mail2SmsUser.whatsappEnabled"
                     id="waMsisdn"
                     name="waMsisdn">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="senderOnly" [model]="senderOnly" label="Sender Only"></micro-valid-label></th>
            <td>
              <input type="checkbox" #senderOnly="ngModel" [(ngModel)]="mail2SmsUser.senderOnly" (ngModelChange)="setMoToEmailFlag()" placeholder="Sender Only" id="senderOnly" name="senderOnly">
            </td>
          </tr>
          <tr>
            <th>Receive MO Emails</th>
            <td>
<!--              <ng-container *ngIf="mail2SmsUser.senderOnly">Never</ng-container>-->
              <ng-container *ngIf="!mail2SmsUser.senderOnly || true">
              <select [(ngModel)]="moToEmailFlag" (ngModelChange)="moToEmailFlagChanged()" name="moToSelect" [disabled]="mail2SmsUser.senderOnly">
                <option value="never">Never</option>
                <option value="none">Only when matched</option>
                <option value="noother">Only when no recipients</option>
                <option value="always">Always</option>
              </select>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="accountChecked">
            <th colspan="2" class="text-center">Account Status Check</th>
          </tr>
          <tr *ngIf="accountChecked">
            <th>
              <mat-icon *ngIf="mail2SmsUser.accountEnabled" class="icon-medium" style="color: green">check_circle</mat-icon>
              <mat-icon *ngIf="!mail2SmsUser.accountEnabled" class="icon-medium" style="color: red">cancel</mat-icon>
              </th>
            <td>Account Enabled</td>
          </tr>
          <tr *ngIf="accountChecked">
            <th>
              <mat-icon *ngIf="mail2SmsUser.emailVerified" class="icon-medium" style="color: green">check_circle</mat-icon>
              <mat-icon *ngIf="!mail2SmsUser.emailVerified" class="icon-medium" style="color: red">cancel</mat-icon>
            </th>
            <td>Email Address Verified</td>
          </tr>
          <tr *ngIf="accountChecked">
            <th>
              <mat-icon *ngIf="mail2SmsUser.mail2SmsServiceAssigned" class="icon-medium" style="color: green">check_circle</mat-icon>
              <mat-icon *ngIf="!mail2SmsUser.mail2SmsServiceAssigned" class="icon-medium" style="color: red">cancel</mat-icon>
            </th>
            <td>MAIL2SMS Service Assigned</td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
