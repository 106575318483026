import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {SmsMoToEmailLogFilter} from "./smsMoToEmailLog.filter";
import { debounceTime } from 'rxjs/operators';
import {DialogService} from "../../services/dialog.service";
import {UsageFilter} from "../../usage/usage.filter";
import {SmsMtFilter} from "../../so/smsMtFilter";

@Component({
  selector: 'micro-smsMoToEmailLogs',
  templateUrl: './smsMoToEmailLogs.component.html'
})
export class SmsMoToEmailLogsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:SmsMoToEmailLogFilter = new SmsMoToEmailLogFilter();
  usageFilter:UsageFilter = new UsageFilter();
  timer:any;
  reloading:boolean = false;

  settings:any = {
    pageIndex: 0,
    pageSize: 25,
    autoRefresh: false
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient) {
  }

  ngOnInit() {
    this.timer = setInterval(()=> {
      if (this.settings.autoRefresh) {
        this.reload();
      }
    }, 30000);
    this.loadSettings();
    this.reload();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  reload() {
    if (this.reloading) {
      return;
    }

    this.reloading = true;
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || this.settings.pageSize))
      ;

    this.http.get(`${this.env.e.url}/sms/smsMoToEmailLogs`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  addDateRangeListner() {
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  loadSettings() {
    let sessionSettingsJson = sessionStorage.getItem("sms_log_mo_to_email");

    if (sessionSettingsJson) {
      let sessionSettings = JSON.parse(sessionSettingsJson);
      this.settings = sessionSettings.settings;
      this.paginator.pageIndex = this.settings.pageIndex;
      this.paginator.pageSize = this.settings.pageSize;

      this.filter = new SmsMoToEmailLogFilter(sessionSettings.filter);
      this.addFilterListener();

      this.usageFilter = new UsageFilter(sessionSettings.dateRange);
      this.addDateRangeListner();
    } else {
      this.usageFilter = new UsageFilter();
      this.addDateRangeListner();
      this.filter = new SmsMoToEmailLogFilter();
      this.addFilterListener();
    }
  }

  saveSettings() {
    let sessionSettings = {
      filter: this.filter.toObj(),
      settings: this.settings,
      dateRange: this.usageFilter.toObj(),
    };
    sessionStorage.setItem("sms_log_mo_to_email", JSON.stringify(sessionSettings));
  }

  pagignatorChanged() {
    if (this.paginator.pageIndex !== this.settings.pageIndex || this.paginator.pageSize !== this.settings.pageSize) {
      this.settings.pageIndex = this.paginator.pageIndex;
      this.settings.pageSize = this.paginator.pageSize;
      this.saveSettings();
    }
    this.reload();
  }

}
