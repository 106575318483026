<div class="stick-top">
  <mat-toolbar>
    <mat-icon>contact_mail</mat-icon> Email2Msg Users
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/mail2msg/users/0">
        <mat-icon>add</mat-icon>
        Create a new Email2Msg User
      </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>
        <th>
          <div class="vbox">
            User Email Address
            <input [(ngModel)]="filter.email">
          </div>
        </th>
        <th>
          <div class="vbox">
            User
            <micro-user-select [companyId]="auth.getCompanyId()" [(userId)]="filter.userId"></micro-user-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Company
            <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            SMS A-Number
            <micro-anumber-select [(anumberId)]="filter.smsAnumberId"></micro-anumber-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            WhatsApp MSISDN
            <input [(ngModel)]="filter.waMsisdn">
          </div>
        </th>
        <th>
          <div class="vbox">
            Sender Only
          </div>
        </th>
        <th>
          <div class="vbox">
            Receive MO Emails
          </div>
        </th>
        <th>
          <div class="vbox">
            SMS Enabled
            <micro-yesNo-select [(yesNo)]="filter.smsEnabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            WhatsApp Enabled
            <micro-yesNo-select [(yesNo)]="filter.whatsappEnabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            User Enabled
            <micro-yesNo-select [(yesNo)]="filter.accountEnabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Email Verified
            <micro-yesNo-select [(yesNo)]="filter.emailVerified" yesOption="YES" noOption="NO"></micro-yesNo-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            MAIL2SMS Service
          </div>
        </th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td ><a routerLink="/mail2msg/users/{{element.id}}"><micro-userEmail-lookup [userId]="element.userId"></micro-userEmail-lookup></a></td>
        <td ><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
        <td ><micro-company-lookup [companyId]="element.companyId" ></micro-company-lookup></td>
        <td ><micro-anumber-lookup [anumberId]="element.smsAnumberId"></micro-anumber-lookup></td>
        <td [ngStyle]="{'color': element.waMsisdn ? 'unset' : 'grey'}">{{element.waMsisdn ? element.waMsisdn : 'Company MSISDN'}}</td>
        <td >{{element.senderOnly ? "YES" : "NO"}}</td>
        <td >{{displayReceiveMoMode(element)}}</td>
        <td >
          <span *ngIf="element.smsEnabled"><mat-icon class="icon-small" style="color: green">check_circle</mat-icon> ENABLED</span>
          <span *ngIf="!element.smsEnabled"><mat-icon class="icon-small" style="color: red">cancel</mat-icon> DISABLED</span>
        </td>
        <td >
          <span *ngIf="element.whatsappEnabled"><mat-icon class="icon-small" style="color: green">check_circle</mat-icon> ENABLED</span>
          <span *ngIf="!element.whatsappEnabled"><mat-icon class="icon-small" style="color: red">cancel</mat-icon> DISABLED</span>
        </td>
        <td >
          <span *ngIf="element.accountEnabled"><mat-icon class="icon-small" style="color: green">check_circle</mat-icon> ENABLED</span>
          <span *ngIf="!element.accountEnabled"><mat-icon class="icon-small" style="color: red">cancel</mat-icon> DISABLED</span>
        </td>
        <td >
          <span *ngIf="element.emailVerified"><mat-icon class="icon-small" style="color: green">check_circle</mat-icon> YES</span>
          <span *ngIf="!element.emailVerified"><mat-icon class="icon-small" style="color: red">cancel</mat-icon> NO</span>
        </td>
        <td >
          <span *ngIf="element.mail2SmsServiceAssigned"><mat-icon class="icon-small" style="color: green">check_circle</mat-icon> YES</span>
          <span *ngIf="!element.mail2SmsServiceAssigned"><mat-icon class="icon-small" style="color: red">cancel</mat-icon> NO</span>
        </td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
