import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmsMtFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.uuidChange.next(filter.uuid || undefined);
      this.clientSourceAddressChange.next(filter.clientSourceAddress || undefined);
      this.destinationAddressChange.next(filter.destinationAddress || undefined);
      this.textDataChange.next(filter.textData || undefined);
      this.contentTypeChange.next(filter.contentType || undefined);
      this.stateChange.next(filter.state || undefined);
      this.carrierStatusChange.next(filter.carrierStatus || undefined);
      this.carrierTrackingIdChange.next(filter.carrierTrackingId || undefined);
      this.batchIdChange.next(filter.batchId || undefined);
      this.companyIdChange.next(filter.companyId || undefined);
      this.departmentIdChange.next(filter.departmentId || undefined);
      this.userIdChange.next(filter.userId || undefined);
      this.mnoIdChange.next(filter.mnoId || undefined);
      this.errorChange.next(filter.error || undefined);
      this.systemGeneratedChange.next(filter.systemGenerated || undefined);
      this.anyChange.emit();
    }
  }

  uuidChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get uuid():any {
    return this.uuidChange.value;
  }
  set uuid(value:any) {

    this.uuidChange.next(value);
    this.emitChange("uuid", value);
  }

  clientSourceAddressChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get clientSourceAddress():any {
    return this.clientSourceAddressChange.value;
  }
  set clientSourceAddress(value:any) {
    this.clientSourceAddressChange.next(value);
    this.emitChange("clientSourceAddress", value);
  }

  destinationAddressChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get destinationAddress():any {
    return this.destinationAddressChange.value;
  }
  set destinationAddress(value:any) {

    this.destinationAddressChange.next(value);
    this.emitChange("destinationAddress", value);
  }

  textDataChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get textData():any {
    return this.textDataChange.value;
  }
  set textData(value:any) {

    this.textDataChange.next(value);
    this.emitChange("textData", value);
  }

  contentTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contentType():any {
    return this.contentTypeChange.value;
  }
  set contentType(value:any) {

    this.contentTypeChange.next(value);
    this.emitChange("contentType", value);
  }

  stateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get state():any {
    return this.stateChange.value;
  }
  set state(value:any) {

    this.stateChange.next(value);
    this.emitChange("state", value);
  }

  // TODO: move to messageParts
  carrierStatusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get carrierStatus():any {
    return this.carrierStatusChange.value;
  }
  set carrierStatus(value:any) {

    this.carrierStatusChange.next(value);
    this.emitChange("carrierStatus", value);
  }

  carrierTrackingIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get carrierTrackingId():any {
    return this.carrierTrackingIdChange.value;
  }
  set carrierTrackingId(value:any) {

    this.carrierTrackingIdChange.next(value);
    this.emitChange("carrierTrackingId", value);
  }

  batchIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get batchId():any {
    return this.batchIdChange.value;
  }
  set batchId(value:any) {

    this.batchIdChange.next(value);
    this.emitChange("batchId", value);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  departmentIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get departmentId():any {
    return this.departmentIdChange.value;
  }
  set departmentId(value:any) {
    this.departmentIdChange.next(value);
    this.emitChange("departmentId", value);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(value:any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }

  mnoIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get mnoId():any {
    return this.mnoIdChange.value;
  }
  set mnoId(value:any) {
    this.mnoIdChange.next(value);
    this.emitChange("mnoId", value);
  }

  // TODO: move to messageParts
  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get error():any {
    return this.errorChange.value;
  }
  set error(value:any) {
    this.errorChange.next(value);
    this.emitChange("error", value);
  }

  systemGeneratedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get systemGenerated():any {
    return this.systemGeneratedChange.value;
  }
  set systemGenerated(value:any) {

    this.systemGeneratedChange.next(value);
    this.emitChange("systemGenerated", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.uuid && this.uuid !== '') {
      params = params.set("uuid", this.uuid);
    }
    if (this.clientSourceAddress && this.clientSourceAddress !== '') {
      params = params.set("clientSourceAddress", this.clientSourceAddress);
    }
    if (this.destinationAddress && this.destinationAddress !== '') {
      params = params.set("destinationAddress", this.destinationAddress);
    }
    if (this.textData && this.textData !== '') {
      params = params.set("textData", this.textData);
    }
    if (this.contentType && this.contentType !== '') {
      params = params.set("contentType", this.contentType);
    }
    if (this.state && this.state !== '') {
      params = params.set("state", this.state);
    }
    if (this.carrierStatus && this.carrierStatus !== '') {
      params = params.set("carrierStatus", this.carrierStatus);
    }
    if (this.carrierTrackingId && this.carrierTrackingId !== '') {
      params = params.set("carrierTrackingId", this.carrierTrackingId);
    }
    if (this.batchId && this.batchId !== '') {
      params = params.set("batchId", this.batchId);
    }
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.departmentId && this.departmentId !== '') {
      params = params.set("departmentId", this.departmentId);
    }
    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }
    if (this.mnoId && this.mnoId !== '') {
      params = params.set("mnoId", this.mnoId);
    }
    if (this.systemGenerated && this.systemGenerated !== '') {
      params = params.set("systemGenerated", this.systemGenerated);
    }
    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    return params;
  }

  toObj():any {
    return {
      uuid: this.uuid,
      clientSourceAddress: this.clientSourceAddress,
      destinationAddress: this.destinationAddress,
      textData: this.textData,
      contentType: this.contentType,
      state: this.state,
      carrierStatus: this.carrierStatus,
      carrierTrackingId: this.carrierTrackingId,
      batchId: this.batchId,
      companyId: this.companyId,
      departmentId: this.departmentId,
      userId: this.userId,
      mnoId: this.mnoId,
      error: this.error,
      systemGenerated: this.systemGenerated,
    }
  }

  public clear():void {
    this.uuid = undefined;
    this.clientSourceAddress = undefined;
    this.destinationAddress = undefined;
    this.textData = undefined;
    this.contentType = undefined;
    this.state = undefined;
    this.carrierStatus = undefined;
    this.carrierTrackingId = undefined;
    this.batchId = undefined;
    this.companyId = undefined;
    this.departmentId = undefined;
    this.userId = undefined;
    this.mnoId = undefined;
    this.error = undefined;
    this.systemGenerated = undefined;
  }
}
