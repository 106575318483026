import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-ne-detail',
  templateUrl: './ne-detail.component.html'
})
export class NeDetailComponent implements OnInit {
  @Input()
  neId:any;

  ne:any;

  @Output()
  persisted:EventEmitter<any> = new EventEmitter<any>();

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    if (this.neId) {
      this.load(this.neId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          if (id === '0') {
            this.createNew();
          } else {
            this.load(id);
          }
        });
    }
  }

  load(id:any) {
    this.http.get(`${this.env.e.url}/domain/nes/${id}`).subscribe(
      data => {
        this.ne = data as any;
      }
    );
  }

  createNew() {
    this.ne = {
      negs: []
    };
  }

  save() {
    if (!this.ne.id) {
      this.ne.neName = this.ne.name;
      this.http.post(`${this.env.e.url}/domain/nes`, this.ne)
        .subscribe(
          data => {
            this.ne = data;
            this.alertService.info(`Created ${this.ne.name}`);
            this.persisted.emit({
              data: data,
              action: 'added'
            });
          }
        );
    } else {
      this.ne.neName = this.ne.name;
      this.http.put(`${this.env.e.url}/domain/nes/${this.ne.id}`, this.ne)
        .subscribe(
          data => {
            this.ne = data;
            this.alertService.info(`Updated Network Element Settings`);
            this.persisted.emit({
              data: data,
              action: 'updated'
            });
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/domain/nes/${this.ne.id}`)
      .subscribe(
        complete => {
          this.persisted.emit({
            data: this.ne,
            action: 'deleted'
          });
          this.alertService.info(`Deleted Network Element ${this.ne.name}`);
          if (!this.neId) {
            this.router.navigate(["/nes"]);
          }
        }
      );
  }
}
