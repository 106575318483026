<div *ngIf="outMsgLog">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>help</mat-icon>
      {{'WhatsApp Outgoing Log ' + outMsgLog.id}}
    </mat-toolbar>
  </div>

  <div class="padded vbox-space">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">WhatsApp Outgoing Log</th>
      </tr>
      <tr>
        <th>From</th>
        <th>To</th>
        <th>Created At</th>
        <th>Sent At</th>
        <th>Delivered At</th>
        <th>Type</th>
        <th>Scheduled At</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{outMsgLog.from}}</td>
        <td>{{outMsgLog.to}}</td>
        <td>{{outMsgLog.datetime|dateTimeMs}}</td>
        <td>{{outMsgLog.sentAt|dateTimeMs}}</td>
        <td>{{outMsgLog.doneAt|dateTimeMs}}</td>
        <td>{{contentMsg.type}}</td>
        <td>{{outMsgLog.scheduleAt|dateTimeMs}}</td>
        <td>{{outMsgLog.status}}</td>
      </tr>
      </tbody>
    </table>

    <table class="basicTable " *ngIf="outMsgLog.error">
      <tbody>
      <tr>
        <th colspan="100">
          Error Detail
        </th>
      </tr>
      <tr>
        <th class="CRITICAL">Error</th>
        <td>{{outMsgLog.error}}</td>
      </tr>
      <tr>
        <th>Detail</th>
        <td>{{outMsgLog.errorDetail}}</td>
      </tr>
      </tbody>
    </table>


    <table class="basicTable" *ngIf="contentMsg.type === 'TEXT'">
      <thead>
        <tr>
          <th colspan="100">TEXT Message</th>
        </tr>
        <tr>
          <th>Text</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td [innerHTML]="contentMsg.text | replaceLineBreaks"></td>
        </tr>
      </tbody>
    </table>

    <table class="basicTable vtable"
           *ngIf="contentMsg.type === 'VIDEO' ||
           contentMsg.type === 'AUDIO' ||
           contentMsg.type === 'IMAGE' ||
           contentMsg.type === 'STICKER' ||
           contentMsg.type === 'DOCUMENT'"
           style="max-width: 100%; min-width: 500px;">
      <thead>
      <tr>
        <th colspan="100" class="text-center">{{contentMsg.type}} Message</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Caption:</th>
        <td [innerHTML]="(contentMsg.text | replaceLineBreaks) || 'No Caption'"></td>
      </tr>
      <tr>
        <th>Content Type:</th>
        <td>{{contentMsg.dataContentType || 'Unknown'}}</td>
      </tr>
      <tr>
        <th>File name:</th>
        <td>{{contentMsg.dataFilename || 'No name specified'}}</td>
      </tr>
      <tr *ngIf="contentMsg.data">
        <td colspan="100" *ngIf="contentMsg.type === 'IMAGE' || contentMsg.type === 'STICKER'"
            style="max-width: 100%;">
          <img [src]="'data:;base64,' + contentMsg.data | safe">
        </td>
      </tr>
      </tbody>
    </table>

    <table class="basicTable" *ngIf="contentMsg.type === 'LOCATION'">
      <thead>
      <tr>
        <th colspan="100">LOCATION Message</th>
      </tr>
      <tr>
        <th>Latitute</th>
        <th>Longitude</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{contentMsg.lat}}</td>
        <td>{{contentMsg.lon}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="contentMsg.type === 'CONTACT'">
    <mat-toolbar>Contacts</mat-toolbar>

    <div class="padded vbox vbox-space">
      <div class="boxwrapper" *ngFor="let c of contentMsg.contactsAsObject">
        <mat-toolbar>{{c.name.formattedName}}</mat-toolbar>
        <div class="padded vbox vbox-space">
          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Contact Details</th>
            </tr>
            <tr>
              <th>First.Name</th>
              <th>Last.Name</th>
              <th>Middle.Name</th>
              <th>Formatted.Name</th>
              <th>Name.Suffix</th>
              <th>Name.Prefix</th>
              <th>Birthday</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{c.name.firstName}}</td>
              <td>{{c.name.lastName}}</td>
              <td>{{c.name.middleName}}</td>
              <td>{{c.name.formattedName}}</td>
              <td>{{c.name.nameSuffix}}</td>
              <td>{{c.name.namePrefix}}</td>
              <td>{{c.birthday}}</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Addresses</th>
            </tr>
            <tr>
              <th>Street</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th>Country</th>
              <th>Country.Code</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let address of c.addresses">
              <td>{{address.street}}</td>
              <td>{{address.city}}</td>
              <td>{{address.state}}</td>
              <td>{{address.zip}}</td>
              <td>{{address.country}}</td>
              <td>{{address.countryCode}}</td>
              <td>{{address.type}}</td>
            </tr>
            <tr *ngIf="!c.addresses || c.addresses?.length == 0">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">E-Mails</th>
            </tr>
            <tr>
              <th>E-Mail</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let email of c.emails">
              <td>{{email.email}}</td>
              <td>{{email.type}}</td>
            </tr>
            <tr *ngIf="!c.emails || c.emails?.length == 0">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Phones</th>
            </tr>
            <tr>
              <th>Phone</th>
              <th>WhatsApp.ID</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let phone of c.phones">
              <td>{{phone.phone}}</td>
              <td>{{phone.waId}}</td>
              <td>{{phone.type}}</td>
            </tr>
            <tr *ngIf="!c.phones || c.phones?.length == 0">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">URLs</th>
            </tr>
            <tr>
              <th>URL</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let u of c.urls">
              <td>{{u.url}}</td>
              <td>{{u.type}}</td>
            </tr>
            <tr *ngIf="!c.urls || c.urls?.length == 0">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Organization</th>
            </tr>
            <tr>
              <th>Company</th>
              <th>Department</th>
              <th>Title</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="c.org">
              <td>{{c.org.company}}</td>
              <td>{{c.org.department}}</td>
              <td>{{c.org.title}}</td>
            </tr>
            <tr *ngIf="!c.org">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
