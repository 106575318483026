<div class="stick-top">
  <mat-toolbar>
    <mat-icon>help</mat-icon> SMS Reply Logs
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
    <micro-checkbox [(checked)]="settings.autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing" (click)="saveSettings()"></micro-checkbox>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>
<micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="datetime" entityType="SMS_MO_TO_EMAIL_LOG" filter="" groupBy="anumberId"></micro-do-chart>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>
        <th class="dateTimeHeader">
          <div class="vbox">
            Date
            <input [(ngModel)]="filter.datetime">
          </div>
        </th>
        <th>
          <div class="vbox">
            A-Number
            <micro-anumber-select [(anumberId)]="filter.anumberId"></micro-anumber-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            SMS Reply Config
            <micro-smsMoToEmail-select [(smsMoToEmailId)]="filter.smsMoToEmailId"></micro-smsMoToEmail-select>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td><a routerLink="/sms/smsMoToEmailLogs/{{element.id}}">{{element.datetime | dateTimeMs}}</a></td>
        <td><micro-anumber-lookup [anumberId]="element.anumberId"></micro-anumber-lookup></td>
        <td><micro-smsMoToEmail-lookup [smsMoToEmailId]="element.smsMoToEmailId"></micro-smsMoToEmail-lookup></td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="pagignatorChanged()"
                       [pageSize]="settings.pageSize"
                       [pageSizeOptions]="[25, 50, 100, 250]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
