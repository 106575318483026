import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class NotificationLogFilter {

  anyChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.companyIdChange.next(filter.companyId || '');
      this.alarmIdChange.next(filter.alarmId || '');
      this.subscriptionIdChange.next(filter.subscriptionId || '');
      this.groupIdChange.next(filter.groupId || '');
      this.fromChange.next(filter.from || '');
      this.toChange.next(filter.to || '');
      this.notificationTypeChange.next(filter.notificationType || '');
      this.messageChange.next(filter.message || '');
      this.alarmFilterChange.next(filter.alarmFilter || '');
      this.moiChange.next(filter.moi || '');
      this.specProbChange.next(filter.specProb || '');
      this.errorChange.next(filter.error || '');
      this.anyChange.emit();
    }
  }
  alarmIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get alarmId(): any {
    return this.alarmIdChange.value;
  }

  set alarmId(alarmId: any) {

    this.alarmIdChange.next(alarmId);
    this.emitChange("alarmId", alarmId);
  }

  subscriptionIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get subscriptionId(): any {
    return this.subscriptionIdChange.value;
  }
  set subscriptionId(subscriptionId: any) {
    this.subscriptionIdChange.next(subscriptionId);
    this.emitChange("subscriptionId", subscriptionId);
  }

  groupIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get groupId(): any {
    return this.groupIdChange.value;
  }
  set groupId(groupId: any) {
    this.groupIdChange.next(groupId);
    this.emitChange("groupId", groupId);
  }

  companyIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get companyId(): any {
    return this.companyIdChange.value;
  }

  set companyId(companyId: any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  fromChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get from(): any {
    return this.fromChange.value;
  }

  set from(from: any) {

    this.fromChange.next(from);
    this.emitChange("from", from);
  }

  toChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get to(): any {
    return this.toChange.value;
  }

  set to(to: any) {

    this.toChange.next(to);
    this.emitChange("to", to);
  }

  notificationTypeChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get notificationType(): any {
    return this.notificationTypeChange.value;
  }

  set notificationType(notificationType: any) {

    this.notificationTypeChange.next(notificationType);
    this.emitChange("notificationType", notificationType);
  }

  messageChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get message(): any {
    return this.messageChange.value;
  }

  set message(message: any) {

    this.messageChange.next(message);
    this.emitChange("message", message);
  }

  alarmFilterChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get alarmFilter(): any {
    return this.alarmFilterChange.value;
  }

  set alarmFilter(alarmFilter: any) {

    this.alarmFilterChange.next(alarmFilter);
    this.emitChange("alarmFilter", alarmFilter);
  }

  moiChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get moi(): any {
    return this.moiChange.value;
  }

  set moi(moi: any) {

    this.moiChange.next(moi);
    this.emitChange("moi", moi);
  }

  specProbChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get specProb(): any {
    return this.specProbChange.value;
  }

  set specProb(specProb: any) {

    this.specProbChange.next(specProb);
    this.emitChange("specProb", specProb);
  }

  errorChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get error(): any {
    return this.errorChange.value;
  }

  set error(error: any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  emitChange(prop: string, value: any): void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams(): HttpParams {
    let params: HttpParams = new HttpParams();


    if (this.alarmId && this.alarmId !== '') {
      params = params.set("alarmId", this.alarmId);
    }

    if (this.subscriptionId && this.subscriptionId !== '') {
      params = params.set("subscriptionId", this.subscriptionId);
    }

    if (this.groupId && this.groupId !== '') {
      params = params.set("groupId", this.groupId);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.from && this.from !== '') {
      params = params.set("source", this.from);
    }

    if (this.to && this.to !== '') {
      params = params.set("target", this.to);
    }

    if (this.notificationType && this.notificationType !== '') {
      params = params.set("notificationType", this.notificationType);
    }

    if (this.message && this.message !== '') {
      params = params.set("message", this.message);
    }

    if (this.alarmFilter && this.alarmFilter !== '') {
      params = params.set("alarmFilter", this.alarmFilter);
    }

    if (this.moi && this.moi !== '') {
      params = params.set("moInst", this.moi);
    }

    if (this.specProb && this.specProb !== '') {
      params = params.set("specProb", this.specProb);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    return params;
  }

  toObj():any {
    return {
      alarmId: this.alarmId,
      subscriptionId: this.subscriptionId,
      groupId: this.groupId,
      companyId: this.companyId,
      from: this.from,
      to: this.to,
      notificationType: this.notificationType,
      message: this.message,
      alarmFilter: this.alarmFilter,
      moi: this.moi,
      specProb: this.specProb,
      error: this.error,
    }
  }


  public clear(): void {
    this.alarmId = undefined;
    this.subscriptionId = undefined;
    this.groupId = undefined;
    this.companyId = undefined;
    this.from = undefined;
    this.to = undefined;
    this.notificationType = undefined;
    this.message = undefined;
    this.alarmFilter = undefined;
    this.moi = undefined;
    this.specProb = undefined;
    this.error = undefined;
  }
}
