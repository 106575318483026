<ng-container *ngIf="!embedded">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>reply_all</mat-icon> Email2{{msgChannel}} - Outgoing Email Log
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
      <micro-checkbox [(checked)]="settings.autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing" (click)="saveSettings()"></micro-checkbox>
      <div class="fill-space"></div>
      <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
    </div>
  </div>
  <micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="receivedAt" entityType="MAIL2SMSMO" filter="" groupBy="status"></micro-do-chart>
</ng-container>

<div *ngIf="embedded">
  <div class="stick-top">
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>
    </div>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th class="dateTimeHeader">
          Received At
      </th>
      <th style="width: 114px;">
        <div class="vbox">
          From MSISDN
          <input type="text" [(ngModel)]="filter.msisdn">
        </div>
      </th>
      <th *ngIf="auth.hasRole('COMPANY_ADMIN')">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          User
          <micro-user-select [companyId]="filter.companyId" [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          To Email
          <input type="email" [(ngModel)]="filter.toEmail">
        </div>
      </th>
      <th>
        <div class="vbox">
          From Email
          <input type="email" [(ngModel)]="filter.fromEmail">
        </div>
      </th>
      <th>
        <div class="vbox">
          Status
          <mail2sms-out-status-select [(status)]="filter.status"></mail2sms-out-status-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Out Mailbox
          <micro-smtp-select [(smtpId)]="filter.smtpMailboxId"></micro-smtp-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Text
          <input type="text" [(ngModel)]="filter.text">
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="!embedded" [ngStyle]="{'color': element.displayReceivedAt ? 'inherit' : 'darkgray'}">{{element.receivedAt | dateTimeMs}}</td>
      <td>
        <a *ngIf="msgChannel === 'SMS'" routerLink="/sms/incoming/{{element.msgMoId}}" [matTooltip]="'Open SMS from ' + element.fromMsisdn">{{element.fromMsisdn}}</a>
        <a *ngIf="msgChannel === 'WHATSAPP'" routerLink="/chat/whatsapp/logs/incoming/{{element.msgMoId}}" [matTooltip]="'Open WHATSAPP from ' + element.fromMsisdn">{{element.fromMsisdn}}</a>
      </td>
      <td *ngIf="auth.hasRole('COMPANY_ADMIN')"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
      <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
      <td>
        <ng-container *ngIf="element.mailId"><a routerLink="/mail/log/{{element.mailId}}" [matTooltip]="'Open Email to ' + element.toEmail">{{element.toEmail}}</a></ng-container>
        <ng-container *ngIf="!element.mailId">{{element.toEmail}}</ng-container>
      </td>
      <td>
        <ng-container *ngIf="element.mailId"><a routerLink="/mail/log/{{element.mailId}}" [matTooltip]="'Open Email from ' + element.fromEmail">{{element.fromEmail}}</a></ng-container>
      </td>
      <td>{{element.status}}</td>
      <td>
        <ng-container *ngIf="element.mailId"><micro-smtp-mailbox-lookup [smtpMailboxId]="element.smtpMailboxId"></micro-smtp-mailbox-lookup></ng-container></td>
      <td style="min-width: 50px; max-width: 360px;">
        {{element.msgText?.substring(0, 100)}}
      </td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="pagignatorChanged()"
                       [pageSize]="settings.pageSize"
                       [pageSizeOptions]="[25, 50, 100, 250]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
