import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";
import * as moment from "moment";

export class UsageFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      if (filter?.dateRangeUnit !== 'custom') {
        filter.from = moment().add(-1, filter.dateRangeUnit).startOf(filter.dateRangeUnit).toDate().getTime();
      }

      this.fromChange.next(filter.from || moment().add(-1, 'day').startOf('day').toDate().getTime());
      this.untilChange.next(filter.until || undefined);
      this.fromInfiniteChange.next(filter.fromInfinite || false);
      this.untilInfiniteChange.next(filter.untilInfinite || true);
      this.dateRangeUnitChange.next(filter.dateRangeUnit || 'day');
    }
  }

  fromChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get from():any {
    return this.fromChange.value;
  }
  set from(from:any) {
    this.fromChange.next(from);
    this.emitChange("from", from);
  }

  untilChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get until():any {
    return this.untilChange.value;
  }
  set until(until:any) {
    this.untilChange.next(until);
    this.emitChange("until", until);
  }

  fromInfiniteChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get fromInfinite():any {
    return this.fromInfiniteChange.value;
  }
  set fromInfinite(fromInfinite:any) {
    this.fromInfiniteChange.next(fromInfinite);
    this.emitChange("fromInfinite", fromInfinite);
  }

  untilInfiniteChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get untilInfinite():any {
    return this.untilInfiniteChange.value;
  }
  set untilInfinite(untilInfinite:any) {
    this.untilInfiniteChange.next(untilInfinite);
    this.emitChange("untilInfinite", untilInfinite);
  }

  dateRangeUnitChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get dateRangeUnit():any {
    return this.dateRangeUnitChange.value;
  }
  set dateRangeUnit(dateRangeUnit:any) {
    this.dateRangeUnitChange.next(dateRangeUnit);
    this.emitChange("dateRangeUnit", dateRangeUnit);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.from && this.from !== '') {
      params = params.set("from", this.from);
    }

    if (this.until && this.until !== '') {
      params = params.set("until", this.until);
    }

    if (this.fromInfinite && this.fromInfinite !== '') {
      params = params.set("fromInfinite", this.fromInfinite);
    }

    if (this.untilInfinite && this.untilInfinite !== '') {
      params = params.set("untilInfinite", this.untilInfinite);
    }

    if (this.dateRangeUnit && this.dateRangeUnit !== '') {
      params = params.set("dateRangeUnit", this.dateRangeUnit);
    }

    return params;
  }

  public toObj():any {
    return {
      from: this.from,
      until: this.until,
      fromInfinite: this.fromInfinite,
      untillInfinite: this.untilInfinite,
      dateRangeUnit: this.dateRangeUnit,
    }
  }

  public clear():void {
    this.from = moment().add(-1, 'day').startOf('day').toDate().getTime();
    this.until = undefined;
    this.fromInfinite = false;
    this.untilInfinite = true;
    this.dateRangeUnit = 'day';
  }
}
