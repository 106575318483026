import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmsDrFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.anumberChange.next(filter.anumber || undefined);
      this.toChange.next(filter.to || undefined);
      this.textChange.next(filter.text || undefined);
      this.companyIdChange.next(filter.companyId || undefined);
      this.statusChange.next(filter.status || undefined);
      this.anyChange.emit();
    }
  }

  anumberChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get anumber():any {
    return this.anumberChange.value;
  }
  set anumber(value:any) {
    this.anumberChange.next(value);
    this.emitChange("anumber", value);
  }

  toChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get to():any {
    return this.toChange.value;
  }
  set to(value:any) {

    this.toChange.next(value);
    this.emitChange("to", value);
  }

  textChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get text():any {
    return this.textChange.value;
  }
  set text(value:any) {

    this.textChange.next(value);
    this.emitChange("text", value);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {

    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(value:any) {

    this.statusChange.next(value);
    this.emitChange("status", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.anumber && this.anumber.trim() !== '') {
      params = params.set("anumber", this.anumber);
    }
    if (this.to && this.to.trim() !== '') {
      params = params.set("to", this.to);
    }
    if (this.text && this.text.trim() !== '') {
      params = params.set("text", this.text);
    }
    if (this.companyId && this.companyId.trim() !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.status && this.status.trim() !== '') {
      params = params.set("status", this.status);
    }
    return params;
  }

  toObj():any {
    return {
      anumber: this.anumber,
      to: this.to,
      text: this.text,
      companyId: this.companyId,
      status: this.status,
    }
  }


  public clear():void {
    this.anumber = undefined;
    this.to = undefined;
    this.text = undefined;
    this.status = undefined;
    this.companyId = undefined;
  }
}
