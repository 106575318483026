<div class="stick-top">
  <mat-toolbar><mat-icon>notifications_active</mat-icon> Notification Log</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
    <micro-checkbox [(checked)]="settings.autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing" (click)="saveSettings()"></micro-checkbox>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>
<micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="datetime" entityType="NOTIFICATION_LOG" filter="" groupBy="notificationType"></micro-do-chart>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th style="width: 75px;">
        <div class="vbox">
          Detail
        </div>
      </th>
      <th style="min-width: 100px;">
        <div class="vbox">
          Group
          <micro-objectGroup-select [(objectGroupId)]="filter.groupId" type="SUBSCRIPTION"></micro-objectGroup-select>
        </div>
      </th>
      <th style="width: 120px;">
        <div class="vbox">
          Notification
          <micro-subscription-select [(subscriptionId)]="filter.subscriptionId"  ></micro-subscription-select>
        </div>
      </th>
      <th style="width: 172px;">
        <div class="vbox">
          Date
        </div>
      </th>
      <th style="width: 144px;">
        <div class="vbox">
          From
          <input [(ngModel)]="filter.from">
        </div>
      </th>
      <th style="width: 200px;" matTooltip="Please provide exact match">
        <div class="vbox">
          To
          <input [(ngModel)]="filter.to">
        </div>
      </th>
      <th style="width: 100px;">
        <div class="vbox">
          Type
          <micro-notification-type-select [(notificationType)]="filter.notificationType"></micro-notification-type-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          MOI
          <input [(ngModel)]="filter.moi">
        </div>
      </th>
      <th>
        <div class="vbox">
          Spec.Prob
          <input [(ngModel)]="filter.specProb">
        </div>
      </th>
      <th>
        <div class="vbox">
          Error
          <input [(ngModel)]="filter.specProb">
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td class="text-center"><button (click)="selectRow(element)">Detail</button></td>
      <td>
        <micro-objectGroup-lookup [objectGroupId]="element[2]?.groups"></micro-objectGroup-lookup>
      </td>
      <td [matTooltip]="lookupService.lookup('notif', 'subscriptions', element[0].subscriptionId)">
        <micro-subscription-lookup [subscriptionId]="element[0].subscriptionId"></micro-subscription-lookup>
      </td>
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element[0].datetime">{{element[0].datetime | dateTimeMs}}</td>
      <td [matTooltip]="element[0].from">{{element[0].from}}</td>
      <td [matTooltip]="element[0].targets | json">{{element[0].targets | json}}</td>
      <td [matTooltip]="element[0].notificationType">{{element[0].notificationType}}</td>
      <td [matTooltip]="element[1]?.moInst">{{element[1]?.moInst}}</td>
      <td [matTooltip]="element[1]?.specProb">{{element[1]?.specProb}}</td>
      <td [matTooltip]="element[0].error">{{element[0].error}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="pagignatorChanged()"
                       [pageSize]="settings.pageSize"
                       [pageSizeOptions]="[25, 50, 100, 250]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>

<div *ngIf="selectedRow">
  <micro-alarm-detail *ngIf="alarmEvtTime" [showNav]="false" [id]="selectedRow[0].alarmId" [evtTime]="alarmEvtTime"></micro-alarm-detail>
  <mat-toolbar>Detail</mat-toolbar>
  <div class="padded" style="background: white;">
    <ngx-json-viewer [json]="selectedRow" class="mono"></ngx-json-viewer>
  </div>
</div>
