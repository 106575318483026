import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-smtp-detail',
  templateUrl: './smtp-detail.component.html'
})
export class SmtpDetailComponent extends BaseComponent {
  smtp:any;
  testResult:any;
  changePassword:boolean = false;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private authService:AuthService,
              private router:Router,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/mail/outgoing/mailboxes/${id}`).subscribe(
            data => {
              this.smtp = data as any;
              this.testConnection();
            }
          );
        }
      });
  }

  createNew() {
    this.smtp = {
      port: 587,
      protocol: 'smtp',
      startTlsNotRequired: false,
      debug: false,
      enabled: true,
      authenticationType: 'BASIC'
    };
  }

  save() {
    if (!this.smtp.id) {
      this.http.post(`${this.env.e.url}/mail/outgoing/mailboxes`, this.smtp)
        .subscribe(
          data => {
            this.smtp = data;
            this.alertService.info(`Created ${this.smtp.email}`);
            this.testConnection();
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/mail/outgoing/mailboxes/${this.smtp.id}`, this.smtp)
        .subscribe(
          data => {
            this.smtp = data;
            this.alertService.info(`Updated SMTP settings`);
            this.testConnection();
          }
        );
    }
    this.changePassword = false;
  }

  deleteMailbox() {
    this.http.delete(`${this.env.e.url}/mail/outgoing/mailboxes/${this.smtp.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted Mailbox ${this.smtp.email}`);
          this.router.navigate(["/mail/smtp"]);
        }
      );
  }

  testConnection() {
    this.testResult = null;
    this.http.get(`${this.env.e.url}/mail/outgoing/mailboxes/${this.smtp.id}/test`).subscribe(
      data => {
        this.testResult = data as any;
      }
    );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }
}
