<ng-container *ngIf="!embedded">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>cloud_upload</mat-icon> DB2SMS - Outgoing Log
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
      <micro-checkbox [(checked)]="settings.autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing" (click)="saveSettings()"></micro-checkbox>
      <div class="fill-space"></div>
      <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
    </div>
  </div>
  <micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="receivedAt" entityType="DB2SMS_MO" filter="" groupBy="status"></micro-do-chart>
</ng-container>

<div *ngIf="embedded">
  <div class="stick-top">
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>
    </div>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th class="dateTimeHeader">
          Received At
      </th>
<!--      <th style="width: 114px;">-->
<!--        <div class="vbox">-->
<!--          DB2SMS Account-->
<!--          <micro-db2SmsConfig-select [companyId]="filter.companyId" [(db2SmsConfigId)]="filter.d2sConfigId"></micro-db2SmsConfig-select>-->
<!--        </div>-->
<!--      </th>-->
      <th *ngIf="auth.hasRole('COMPANY_ADMIN')">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          DB2SMS User Account
          <input type="text" [(ngModel)]="filter.accountTag">
        </div>
      </th>
      <th>
        <div class="vbox">
          From MSISDN
          <input type="text" [(ngModel)]="filter.fromMsisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          To MSISDN
          <input type="text" [(ngModel)]="filter.toMsisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          Status
          <db2sms-out-status-select [(status)]="filter.status"></db2sms-out-status-select>
        </div>
      </th>
      <th>
        Uploaded At
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td>{{element.db2SmsReceivedAt | dateTimeMs}}</td>
<!--      <td><micro-db2SmsConfig-lookup [configId]="element.d2sConfigId" [link]="true"></micro-db2SmsConfig-lookup></td>-->
      <td *ngIf="auth.hasRole('COMPANY_ADMIN')"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
      <td><a routerLink="/db2sms/db2SmsConfigs/{{element.d2sConfigId}}" matTooltip="Jump to {{element.accountTag}}">{{element.accountTag}}</a></td>
      <td>
        <a routerLink="/sms/incoming/{{element.smsMoId}}" [matTooltip]="'Open MO SMS from ' + element.fromMsisdn">{{element.fromMsisdn}}</a>
      </td>
      <td>
        <a routerLink="/sms/incoming/{{element.smsMoId}}" [matTooltip]="'Open MO SMS from ' + element.fromMsisdn">{{element.toMsisdn}}</a>
      </td>
      <td>
        {{element.db2SmsMoStatus}}
      </td>
      <td>{{element.db2SmsDbUploadedAt | dateTime}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="pagignatorChanged()"
                       [pageSize]="settings.pageSize"
                       [pageSizeOptions]="[25, 50, 100, 250]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
