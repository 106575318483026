<div class="stick-top">
  <mat-toolbar><mat-icon>blur_on</mat-icon> Reactors</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/reactors/0">
      <mat-icon>add</mat-icon>
      Create a new Reactor
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th style="min-width: 100px;">
        <div *ngIf="hasRole('COMPANY_ADMIN')" class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th style="min-width: 100px;">
        <div class="vbox">
          Group
          <micro-objectGroup-select [(objectGroupId)]="filter.groupId" type="REACTOR"></micro-objectGroup-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Enabled
          <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Event Type
          <micro-event-type-select [(eventType)]="filter.eventType"></micro-event-type-select>
        </div>
      </th>
      <th style="max-width: 200px;">
        <div class="vbox">
          Event Filter
          <input [(ngModel)]="filter.eventFilter">
        </div>
      </th>
      <th style="max-width: 200px;">
        <div class="vbox">
          Event Source
          <input [(ngModel)]="filter.eventSource">
        </div>
      </th>
      <th>
        <div class="vbox">
          Last Activation
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="hasRole('COMPANY_ADMIN')"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
      <td><micro-objectGroup-lookup [objectGroupId]="element.groups"></micro-objectGroup-lookup></td>
      <td><a routerLink="/reactors/{{element.id}}">{{element.name}}</a></td>
      <td>{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
      <td>{{element.eventType}}</td>
      <td [matTooltip]="element.eventFilter" style="max-width: 200px;">{{element.eventFilter}}</td>
      <td [matTooltip]="element.eventSource" style="max-width: 200px;">{{element.eventSource}}</td>
      <td>{{element.lastActivationDate | dateTimeMs}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="pagignatorChanged()"
                       [pageSize]="settings.pageSize"
                       [pageSizeOptions]="[25, 50, 100, 250]"
        >
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
