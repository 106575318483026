<div *ngIf="smtp">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>email</mat-icon> {{!smtp.id ? 'Create new Outgoing Mailbox' : ('Outgoing Mailbox ' + smtp.email)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!smtp.id ? 'add' : 'save'}}</mat-icon>
        {{(smtp.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="smtp.id" (onConfirm)="deleteMailbox()"></micro-delete-confirm>
    </div>
  </div>


  <div *ngIf="smtp.id && !testResult">
    <mat-toolbar>
      Testing SMTP connection...
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && !testResult.success">
    <mat-toolbar class="primary">
      {{testResult.error}}
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && testResult.success">
    <mat-toolbar style="background: green;color: white;">
      Connection was successful
    </mat-toolbar>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
          <tr>
            <th colspan="100">Outgoing Mailbox Configuration</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th><micro-valid-label for="email" [model]="email" label="Email"></micro-valid-label></th>
            <td>
              <input style="width: 300px;" #email="ngModel" [(ngModel)]="smtp.email" required type="email" placeholder="Email" id="email" name="email">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="protocol" [model]="protocol" label="Protocol"></micro-valid-label></th>
            <td>
              <select #protocol="ngModel" [(ngModel)]="smtp.protocol" placeholder="Protocol" name="protocol" id="protocol" >
                <option value="smtp">SMTP</option>
                <option value="smtps">SMTPS</option>
              </select>
            </td>
          </tr>
          <tr>
            <th [ngClass]="{'WARNING': smtp.startTlsNotRequired}">STARTTLS Not Required</th>
            <td>
              <input [(ngModel)]="smtp.startTlsNotRequired" type="checkbox" id="startTlsNotRequired" name="startTlsNotRequired">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="authenticationType" [model]="authenticationType" label="Authentication Type"></micro-valid-label></th>
            <td>
              <micro-smtpAuthType-select #authenticationType="ngModel" [(ngModel)]="smtp.authenticationType" [required]="true" id="authenticationType" name="authenticationType"></micro-smtpAuthType-select>
            </td>
          </tr>
          <tr *ngIf="smtp.authenticationType !== 'NONE'">
            <th><micro-valid-label for="credentialId" [model]="credentialId" label="Credentials"></micro-valid-label></th>
            <td>
              <micro-credential-select #credentialId="ngModel" [(ngModel)]="smtp.credentialId" [companyId]="smtp.companyId" [credentialAuthType]="smtp.authenticationType" [required]="true" id="credentialId" name="credentialId"></micro-credential-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="host" [model]="host" label="Host"></micro-valid-label></th>
            <td>
              <input #host="ngModel" [(ngModel)]="smtp.host" placeholder="Host" id="host" name="host" required>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="port" [model]="port" label="Port"></micro-valid-label></th>
            <td>
              <input #port="ngModel" [(ngModel)]="smtp.port" type="number" placeholder="Port" id="port" name="port" required>
            </td>
          </tr>
          <tr>
            <th [ngClass]="{'WARNING': smtp.debug}">Debug</th>
            <td>
              <input [(ngModel)]="smtp.debug" type="checkbox" id="debug" name="debug">
            </td>
          </tr>
          <tr>
            <th [ngClass]="{'WARNING': !smtp.enabled}">Enabled</th>
            <td>
              <input [(ngModel)]="smtp.enabled" type="checkbox" id="enabled" name="enabled">
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

