import {Component, OnInit, Input, OnDestroy, ViewChild, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {SmsMoFilter} from "./smsmo.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";
import {MailFilter} from "../mail/mail.filter";
import {UsageFilter} from "../usage/usage.filter";
import {DialogService} from "../services/dialog.service";
import {SmsMtFilter} from "./smsMtFilter";

@Component({
  selector: 'micro-smsmos',
  templateUrl: './smsmos.component.html'
})
export class SmsMosComponent implements OnInit, OnDestroy  {

  @Input()
  mtId:string;

  @Input()
  mtBatchId:string;

  @Input()
  to:string;

  @Input()
  text:any;

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:SmsMoFilter = new SmsMoFilter();
  usageFilter:UsageFilter = new UsageFilter();

  @Input()
  selectionMode:string;

  @Input()
  embedded:boolean = false;
  columnDefs:any[];
  colLookup:any = {}

  @Input()
  selectedRow:any;

  @Output()
  selectedRowChange:EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  timer:any;
  reloading:boolean = false;

  settings:any = {
    pageIndex: 0,
    pageSize: 25,
    autoRefresh: false
  }

  constructor(public auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              public dialogService:DialogService) {
  }

  ngOnInit() {
    this.timer = setInterval(()=> {
      if (this.settings.autoRefresh && !this.embedded) {
        this.reload();
      }
    }, 30000);
    this.loadSettings();
    this.reload();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  public reload() {
    if (this.reloading) {
      return;
    }

    this.reloading = true;
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || this.settings.pageSize));
    if (this.mtId && this.mtId !== '') {
      params = params.set("mtId", this.mtId);
    }
    if (this.mtBatchId && this.mtBatchId !== '') {
      params = params.set("mtBatchId", this.mtBatchId);
    }

    if (!this.embedded) {
      if (!this.usageFilter.fromInfinite) {
        params = params.set('from', '' + this.usageFilter.from);
      }

      if (!this.usageFilter.untilInfinite) {
        params = params.set('until', '' + this.usageFilter.until);
      }
    }

    this.http.get(`${this.env.e.url}/sms/mo`, {params:params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }

  onSelectedRowChange() {
    this.selectedRowChange.emit(this.selectedRow);
  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  addDateRangeListner() {
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  loadSettings() {
    let sessionSettingsJson = sessionStorage.getItem("sms_log_mo");

    if (sessionSettingsJson) {
      let sessionSettings = JSON.parse(sessionSettingsJson);
      this.settings = sessionSettings.settings;
      this.paginator.pageIndex = this.settings.pageIndex;
      this.paginator.pageSize = this.settings.pageSize;

      this.filter = new SmsMoFilter(sessionSettings.filter);
      if (this.embedded) {
        this.filter.to = this.to;
        this.filter.text = this.text;
      }
      this.addFilterListener();

      if (!this.embedded) {
        this.usageFilter = new UsageFilter(sessionSettings.dateRange);
        this.addDateRangeListner();
      }

    } else {
      this.usageFilter = new UsageFilter();
      this.addDateRangeListner();
      this.filter = new SmsMoFilter();
      this.filter.to = this.to || undefined;
      this.filter.text = this.text || undefined;
      this.addFilterListener();
    }
  }

  saveSettings() {
    let oldSettings = JSON.parse(sessionStorage.getItem("sms_log_mo"));
    let sessionSettings = {
      filter: this.embedded ? oldSettings.filter : this.filter.toObj(),
      settings: this.settings,
      dateRange: this.embedded ? oldSettings.dateRange : this.usageFilter.toObj(),
    };
    sessionStorage.setItem("sms_log_mo", JSON.stringify(sessionSettings));
  }

  pagignatorChanged() {
    if (this.paginator.pageIndex !== this.settings.pageIndex || this.paginator.pageSize !== this.settings.pageSize) {
      this.settings.pageIndex = this.paginator.pageIndex;
      this.settings.pageSize = this.paginator.pageSize;
      this.saveSettings();
    }
    this.reload();
  }
}
