import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import {NgModule} from "@angular/core";
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform {
  transform(items: any[], field : any, value : any): any[] {
    if (!value || value == ""){
      return items;
    }
    if (!items) return [];
    let filteredItems: any[] = items.filter(it => {
      if (it == -1) {
        return false;
      }

      if (typeof it[field] == 'number') {
        return it[field] === value;
      } else if (typeof value === 'string') {
        if (value === 'null') {
          return it[field] == null;
        } else {
          return it[field].toLowerCase().indexOf(value.toLowerCase()) >= 0;
        }
      } else {
        if (!value){
          return false;
        }

        if (field.indexOf(".") > 0) {
          let parts = field.split(".");

          let child = it[parts[0]];
          if (!child){
            return false;
          }

          for (let p of parts) {
            if (p === parts[0]) continue;
            child = child[p];
            if (!child){
              return false;
            }
          }

          if (!child){
            return false;
          }

          return child.id == value.id;
        } else {
          if (!it[field]){
            return false;
          }

          return it[field].id == value.id;
        }
      }

    });
    if (filteredItems.length > 0){
      return filteredItems;
    } else{
      return [-1];
    }
  }
}

@Pipe({
  name: 'icon'
})
@Injectable()
export class IconPipe implements PipeTransform {
  transform(value : any): string {
    switch (value) {
      case true:
        return 'check_circle';
      case false:
        return 'cancel';
    }
    return '';
  }
}

@Pipe({
  name: 'yesNo'
})
@Injectable()
export class YesNoPipe implements PipeTransform {
  transform(value : any): string {
    return value ? 'YES' : 'NO';
  }
}

@Pipe({
  name: 'dateDay'
})
export class DateDayFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, 'yyyy-MM-dd');
  }
}

@Pipe({
  name: 'dateTime'
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, 'yyyy-MM-dd HH:mm:ss');
  }
}

@Pipe({
  name: 'dateTimeMs'
})
export class DateTimeMsFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }
    return super.transform(value, 'yyyy-MM-dd HH:mm:ss.SSS');
  }
}

@Pipe({
  name: 'eta'
})
export class EtaFormatPipe extends DatePipe implements PipeTransform {
  transform(ms: any, args?: any): any {
    if (ms < 0) {
      return "-";
    }
    var seconds = (new Date().getTime() - ms) / 1000;
    var days = Math.floor(seconds / (3600*24));
    seconds  -= days*3600*24;
    var hrs   = Math.floor(seconds / 3600);
    seconds  -= hrs*3600;
    var mnts = Math.floor(seconds / 60);
    seconds  -= mnts*60;
    seconds = Math.floor(seconds);

    var result = "";
    if (days > 0) {
      result += days + " Day" + (days > 1 ? "s " : " ");
    }
    if (hrs > 0) {
      result += hrs + " Hour" + (hrs > 1 ? "s " : " ");
    }
    if (mnts > 0) {
      result += mnts + " Minute" + (mnts > 1 ? "s " : " ");
    }
    if (seconds >= 0) {
      result += seconds + " Second" + (seconds > 1 || seconds == 0 ? "s " : " ");
    }
    return result;
  }
}

@Pipe({
  name: 'ago'
})
export class AgoFormatPipe extends DatePipe implements PipeTransform {
  transform(ms: any, args?: any): any {
    if (!ms) {
      return '-';
    }

    var time = new Date().getTime() - new Date(ms).getTime();
    var seconds = time / 1000;
    var days = Math.floor(seconds / (3600*24));
    seconds  -= days*3600*24;
    var hrs   = Math.floor(seconds / 3600);
    seconds  -= hrs*3600;
    var mnts = Math.floor(seconds / 60);
    seconds  -= mnts*60;
    seconds = Math.floor(seconds);

    var result = "";
    if (days > 0) {
      result += days + " Day" + (days > 1 ? "s " : " ");
    }
    if (hrs > 0) {
      result += hrs + " Hour" + (hrs > 1 ? "s " : " ");
    }
    if (mnts > 0) {
      result += mnts + " Minute" + (mnts > 1 ? "s " : " ");
    }
    if (seconds >= 0) {
      result += seconds + " Second" + (seconds > 1 || seconds == 0 ? "s " : " ");
    }
    return result;
  }
}

@Pipe({
  name: 'agoFriendly'
})
export class AgoFriendlyFormatPipe extends DatePipe implements PipeTransform {
  transform(ms: any, args?: any): any {
    if (!ms) {
      return '-';
    }
    var time = new Date().getTime() - new Date(ms).getTime();
    var seconds = time / 1000;
    var days = Math.floor(seconds / (3600*24));
    seconds  -= days*3600*24;
    var hrs   = Math.floor(seconds / 3600);
    seconds  -= hrs*3600;
    var mnts = Math.floor(seconds / 60);
    seconds  -= mnts*60;
    seconds = Math.floor(seconds);

    if (days > 0) {
      return days + " Day" + (days > 1 ? "s " : " ") + " Ago";
    }
    if (hrs > 0) {
      return hrs + " Hour" + (hrs > 1 ? "s " : " ") + " Ago";
    }
    if (mnts < 2) {
      return "Just now";
    }
    return mnts + " Minute" + (mnts > 1 ? "s " : " ") + " Ago";
  }
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({
  name: "arraySort"
})
export class ArraySortPipe {
  transform(array: Array<any>, field: string): Array<any> {
    array.sort((a: any, b: any) => {
      if (typeof(a[field]) === 'string') {
        return a[field].localeCompare(b[field]);
      } else {
        return a[field] - b[field];
      }

    });
    return array;
  }
}

@Pipe({
  name: 'month'
})
@Injectable()
export class MonthPipe implements PipeTransform {
  transform(value : number): string {
    switch (value) {
      case 1:
        return 'January';
      case 2:
        return 'February';
      case 3:
        return 'March';
      case 4:
        return 'April';
      case 5:
        return 'May';
      case 6:
        return 'June';
      case 7:
        return 'July';
      case 8:
        return 'August';
      case 9:
        return 'September';
      case 10:
        return 'October';
      case 11:
        return 'November';
      case 12:
        return 'December';
    }
    return '?';
  }
}

@Pipe({name: 'replaceLineBreaks'})
export class ReplaceLineBreaks implements PipeTransform {
  transform(value: string): string {
    return value.replace(/\n/g, '<br/>');
  }
}

const PIPES =
  [
    FilterPipe, IconPipe, DateTimeFormatPipe, DateTimeMsFormatPipe, SafePipe, EtaFormatPipe,
    ArraySortPipe, AgoFormatPipe, AgoFriendlyFormatPipe, YesNoPipe, MonthPipe, DateDayFormatPipe, ReplaceLineBreaks
  ];

@NgModule({
  declarations: PIPES,
  exports: PIPES
})
export class MicroPipesModule {}
