import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {tree} from "d3";

@Component({
  selector: 'micro-mail2SmsPostmaster-detail',
  templateUrl: './mail2SmsPostmaster-detail.component.html'
})
export class Mail2SmsPostmasterDetailComponent implements OnInit {
  mail2SmsPostmaster:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/mail2sms/mail2SmsPostmasters/${id}`).subscribe(
            data => {
              this.setMail2SmsPostmaster(data)
            }
          );
        }
      });
  }

  setMail2SmsPostmaster(mail2SmsPostmaster:any) {
    this.mail2SmsPostmaster = mail2SmsPostmaster;
  }

  createNew() {
    this.mail2SmsPostmaster = {
      enabled : true
    };
  }

  save() {
    if (!this.mail2SmsPostmaster.id) {
      this.http.post(`${this.env.e.url}/mail2sms/mail2SmsPostmasters`, this.mail2SmsPostmaster)
        .subscribe(
          data => {
            this.setMail2SmsPostmaster(data)
            this.alertService.info(`Created ${this.mail2SmsPostmaster.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/mail2sms/mail2SmsPostmasters/${this.mail2SmsPostmaster.id}`, this.mail2SmsPostmaster)
        .subscribe(
          data => {
            this.setMail2SmsPostmaster(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/mail2sms/mail2SmsPostmasters/${this.mail2SmsPostmaster.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.mail2SmsPostmaster.name}`);
          this.router.navigate(["/mail2msg/postmasters"]);
        }
      );
  }
}
