<micro-select-combo #sel
              placeholder="Mail2Msg Messaging Channel"
              [mode]="mode"
              [(id)]="messagingChannel"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              platformService="mail2smsd"
>
</micro-select-combo>
